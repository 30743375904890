import { useState } from 'react';

import { Button, Col, Modal, Row, Select } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { DatePicker, Form } from 'components';
import { articleLength, ORDER_STATUS } from 'utils';

const { Option } = Select;

export const AuthorFilters = ({ filters, setFilters, filtered }) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const onFinish = (values) => {
    const { articleDate, deliveryDate } = values;
    const entries = Object.entries(values).filter(([, v]) => v !== undefined);

    setFilters((prev) => ({
      ...prev,
      ...Object.fromEntries(entries),
      ...(articleDate && { articleDate: dayjs(articleDate).format('YYYY-MM-DD') }),
      ...(deliveryDate && { deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD') }),
    }));

    setVisible(false);
  };

  const clearFilters = () => {
    setFilters({ page: 1, pageSize: 20 });
    form.resetFields();
  };

  const getInitialValues = () => {
    const values = Object.assign({}, filters);
    const { articleDate, deliveryDate } = values;

    values.articleDate = articleDate && dayjs(articleDate);
    values.deliveryDate = deliveryDate && dayjs(deliveryDate);

    return values;
  };

  return (
    <>
      <div>
        <Button
          type="primary"
          onClick={() => setVisible(true)}
          icon={<FilterFilled />}
          className="gx-mr-0"
        >
          <FormattedMessage id="article.filter" />
        </Button>

        {filtered && (
          <Button
            type="link"
            onClick={clearFilters}
          >
            <FormattedMessage id="article.filter.clear" />
          </Button>
        )}
      </div>

      <Modal
        title={<FormattedMessage id="article.filter" />}
        visible={visible}
        footer={null}
        width={800}
        onCancel={() => setVisible(false)}
        centered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={getInitialValues()}
        >
          <Row className="gx-flex-row gx-mb-3">
            <Col span={12}>
              <Form.Item
                name="articleLength"
                label={<FormattedMessage id="article.detail.articleLength" />}
              >
                <Select placeholder={<FormattedMessage id="article.detail.articleLength" />}>
                  {articleLength.map((length, i) => (
                    <Option
                      key={length}
                      value={i.toString()}
                    >
                      {length}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="articleDate"
                label={<FormattedMessage id="article.order.orderDate" />}
              >
                <DatePicker
                  locale={dayjs.locale('tr')}
                  format={['DD/MM/YYYY', 'YYYY-MM-DD']}
                  className="gx-w-100"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="gx-flex-row gx-mb-4">
            <Col span={12}>
              <Form.Item
                name="deliveryDate"
                label={<FormattedMessage id="article.order.deliveryDate" />}
              >
                <DatePicker
                  locale={dayjs.locale('tr')}
                  format="DD/MM/YYYY"
                  className="gx-w-100"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="status"
                label={<FormattedMessage id="article.filter.generalSituation" />}
              >
                <Select placeholder={<FormattedMessage id="article.filter.generalSituation" />}>
                  {Object.entries(ORDER_STATUS).map(([key, value]) => (
                    <Option
                      key={key}
                      value={key}
                    >
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="gx-px-3">
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              <FormattedMessage id="search" />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
