import React, { useState } from 'react';

import { Button, Col, Row, Card } from 'antd';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { userEdit, userRenewPassword } from 'fetcher';
import { isamMail, regexPass, openNotification } from 'utils';
import { CircularProgress, Title, Form, Input } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError, fetchStart, fetchSuccess } from 'slices/commonSlice';

const FormItem = Form.Item;

const UserEdit = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { user, isLoading, mutate } = useUserInfo();
  const [showPassCange, setShowPassCange] = useState(false);
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newAgainPass, setNewAgainPass] = useState('');
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    dispatch(fetchStart());
    setLoading(true);

    const res = await userEdit(user.id, values);
    if (res) {
      setLoading(false);
      mutate();
      dispatch(fetchSuccess());
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    }
  };

  const changePassword = async () => {
    setLoading(true);

    if (newPass === newAgainPass) {
      const pass = {
        oldPassword: oldPass,
        newPassword: newPass,
      };

      const res = await userRenewPassword(user.id, pass);
      if (res) {
        dispatch(fetchSuccess());

        setShowPassCange(!showPassCange);
        openNotification({
          message: formatMessage({ id: 'appModule.actionSuccessfully' }),
        });
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(fetchError('Bilgilerinizi Kontrol Ediniz.'));
      }
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'user.info.editProfile' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="user.info.editProfile" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={12}
        >
          <Card loading={isLoading}>
            <Form
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
              layout={'vertical'}
              initialValues={user}
              form={form}
            >
              <FormItem
                name="firstName"
                label={<FormattedMessage id="appModule.name" />}
                className="gx-mb-2"
              >
                <Input
                  placeholder={formatMessage({
                    id: 'appModule.name',
                  })}
                />
              </FormItem>
              <FormItem
                name="lastName"
                label={<FormattedMessage id="appModule.surname" />}
                className="gx-mb-2"
              >
                <Input
                  placeholder={formatMessage({
                    id: 'appModule.surname',
                  })}
                />
              </FormItem>
              <FormItem
                name="email"
                label={<FormattedMessage id="appModule.email" />}
                className="gx-mb-2"
                rules={[
                  {
                    type: 'email',
                    message: <FormattedMessage id="app.userAuth.EmailErrorCheck" />,
                  },
                  {
                    pattern: isamMail,
                    message: <FormattedMessage id="app.userAuth.EmailISAMCheck" />,
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({
                    id: 'appModule.email',
                  })}
                />
              </FormItem>
              {!showPassCange && (
                <Button
                  className="gx-mt-2"
                  onClick={() => setShowPassCange(!showPassCange)}
                >
                  <FormattedMessage id="userEdit.userPassChange" />
                </Button>
              )}
              {showPassCange && (
                <div>
                  <hr />
                  <h4>
                    <FormattedMessage id="userEdit.userPassActionTitle" />
                  </h4>
                  <FormItem
                    label={<FormattedMessage id="userEdit.userPassOld" />}
                    name="plainPassword"
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                    loading={true}
                  >
                    <Input.Password
                      type="password"
                      onChange={(e) => setOldPass(e.target.value)}
                    />
                  </FormItem>
                  <FormItem
                    label={<FormattedMessage id="userEdit.userPassNew" />}
                    name="newPassword"
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'app.userAuth.PassError',
                        }),
                      },
                      {
                        pattern: regexPass,
                        message: formatMessage({
                          id: 'app.userAuth.PassResetInfoMessageType',
                        }),
                      },
                      {
                        min: 8,
                        message: formatMessage({
                          id: 'app.userAuth.PassResetInfoMessageCharacter',
                        }),
                      },
                    ]}
                  >
                    <Input.Password
                      type="password"
                      onChange={(e) => setNewPass(e.target.value)}
                    />
                  </FormItem>
                  <FormItem
                    label={<FormattedMessage id="userEdit.userPassAgain" />}
                    name="newPasswordAgain"
                    dependencies={['newPassword']}
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'app.userAuth.PassAgainError',
                        }),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              formatMessage({
                                id: 'app.userAuth.PassNotMatch',
                              })
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      onChange={(e) => setNewAgainPass(e.target.value)}
                    />
                  </FormItem>
                  <div className="gx-mt-2">
                    <Button onClick={() => setShowPassCange(!showPassCange)}>
                      <FormattedMessage id="appModule.cancel" />
                    </Button>
                    <Button
                      onClick={() => changePassword()}
                      type={showPassCange ? 'primary' : ''}
                      loading={loading}
                      disabled={newAgainPass !== newPass || newPass.length < 8 ? true : false}
                    >
                      <FormattedMessage id="appModule.change" />
                    </Button>
                  </div>
                  <hr />
                </div>
              )}
              <FormItem shouldUpdate>
                {({ isFieldsTouched }) => (
                  <Button
                    type="primary"
                    className="gx-mb-0 gx-mt-4"
                    htmlType="submit"
                    loading={loading}
                    disabled={!isFieldsTouched() || showPassCange}
                  >
                    <FormattedMessage id="appModule.save" />
                  </Button>
                )}
              </FormItem>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserEdit;
