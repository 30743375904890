import React, { useState } from 'react';

import { Button, Card, Col, Form, Radio, Row, Select, Spin, Checkbox } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { mutate } from 'swr';
import { useCommittees } from 'useSWR';
import { fetchSearchE1, sendOffers } from 'fetcher';
import { openNotification, checkFormValidations } from 'utils';
import { Title, CircularProgress, Input } from 'components';
import { fetchError } from 'slices/commonSlice';
import { useGetArticleGroupsQuery } from 'services';

const { TextArea } = Input;
const { Option } = Select;

const ArticleOfferForm = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  let timeCheck;
  const valuesFromLocalStorage = JSON.parse(sessionStorage.getItem('maddeTeklifFormu'));
  const { committees, isLoading: loadingCommittee } = useCommittees('categoryOne');

  const { data: articleGroups, isLoading: loadingGroups } = useGetArticleGroupsQuery();

  const [searchResult, setSearchResult] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSearch = async (e, type = null) => {
    setFetching(true);
    clearTimeout(timeCheck);
    timeCheck = setTimeout(async () => {
      if (e.length >= 2) {
        try {
          const result = await fetchSearchE1(e, type ? (type === 'Tam' ? true : false) : '');
          setFetching(true);
          setSearchResult(result['hydra:member']);
          setFetching(false);
        } catch (e) {
          console.log(e);
        }
      }
    }, 500);
  };

  const onFinish = (values) => {
    const data = checkFormValidations(values);

    handleSubmit(data);
  };

  const handleSubmit = async (offer) => {
    setButtonLoading(true);
    try {
      await sendOffers(offer);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      sessionStorage.removeItem('maddeTeklifFormu');
      setTimeout(() => {
        mutate(`/article_offers?&ownerCommittee.id=&title=&page=1&itemsPerPage=20&order[]=null`);
        navigate('/madde-teklifleri/tum-maddeler');
        setButtonLoading(false);
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setButtonLoading(false);
    }
  };

  const saveFieldsLocalStorage = (changedFields, allFields) => {
    const touchedAllFields = allFields
      .filter((field) => {
        if (field?.name.includes('committeeContributions') && typeof field.value?.[0] !== 'object') {
          return false;
        }
        return field;
      })
      ?.map((data) => {
        return {
          name: data.name[0],
          value: data.value ?? null,
        };
      })
      ?.reduce(
        (obj, item) => (
          // eslint-disable-next-line
          (obj[item.name] = item.value), obj
        ),
        {}
      );

    sessionStorage.setItem('maddeTeklifFormu', JSON.stringify(touchedAllFields));
  };

  if (loadingCommittee || loadingGroups) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'article.form.OfferFormTitle' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="article.form.OfferFormTitle" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={20}
          xxl={20}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={valuesFromLocalStorage}
            onFieldsChange={saveFieldsLocalStorage}
            scrollToFirstError
          >
            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name={'title'}
                label={<FormattedMessage id="article.form.OfferedArticleTitle" />}
                labelfor={'title'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ textTransform: 'uppercase' }}
                  name="title"
                />
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="articleGroup"
                labelfor={'articleGroup'}
                label={<FormattedMessage id="article.detail.articleGroup" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  {articleGroups?.map((item) => (
                    <Radio.Button
                      key={item.id}
                      value={item['@id']}
                    >
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Card>
            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="birinciEdisyondaYerAldi"
                labelfor={'birinciEdisyondaYerAldi'}
                label={<FormattedMessage id="article.form.articleBeenEdisionOne" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="birinciEdisyondaYerAldi"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value="Evet"
                    data-cy="contain-first-edicion-yes"
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value="Hayır"
                    data-cy="contain-first-edicion-no"
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                dependencies={['birinciEdisyondaYerAldi']}
                style={{
                  cursor: 'default',
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.atifTam" />}
                          labelfor="atifTam"
                          name="atifTam"
                          dependencies={['birinciEdisyondaYerAldi']}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() =>
                              setFieldsValue({
                                oldTitle: null,
                                oldArticleWorkType: null,
                                rewriteandUpdate: null,
                              })
                            }
                            name={'atifTam'}
                            buttonStyle="solid"
                          >
                            <Radio.Button
                              value="Tam"
                              data-cy="tam-article"
                            >
                              <FormattedMessage id="article.form.Tam" />
                            </Radio.Button>
                            <Radio.Button
                              value="Atıf"
                              data-cy="atif-article"
                            >
                              <FormattedMessage id="article.form.Atif" />
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
              <Form.Item dependencies={['atifTam', 'birinciEdisyondaYerAldi']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('atifTam') && getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.SearchEdisionOneLabel" />}
                          labelfor={'oldTitle'}
                          name={'oldTitle'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            labelInValue="hangiMadde"
                            name="hangiMadde"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, getFieldValue('atifTam'))}
                            style={{ display: 'block' }}
                            allowClear
                            showSearch
                            dropdownStyle={{ position: 'relative' }}
                            onClick={() => setSearchResult([])}
                            data-cy="search-e1-oldTitle"
                          >
                            {searchResult.map((d) => (
                              <Option
                                key={d.id}
                                value={d.id}
                              >
                                {d.title} ({d.slug})
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          dependencies={['atifTam', 'birinciEdisyondaYerAldi', 'rewriteandUpdate']}
                          className="gx-mt-2"
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('birinciEdisyondaYerAldi') === 'Evet' &&
                            getFieldValue('atifTam') === 'Tam' && (
                              <div>
                                <Form.Item
                                  label={<FormattedMessage id="article.form.oldArticleWorkType" />}
                                  labelfor="rewriteandUpdate"
                                  name="rewriteandUpdate"
                                  dependencies={['birinciEdisyondaYerAldi']}
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="Yeniden Yazım">
                                      <FormattedMessage id="article.formWorkToBeRewrite" />
                                    </Radio.Button>
                                    <Radio.Button value="Güncelleme">
                                      <FormattedMessage id="article.form.Update" />
                                    </Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )
                          }
                        </Form.Item>
                        <Form.Item dependencies={['rewriteandUpdate', 'oldArticleWorkType']}>
                          {({ getFieldValue, setFieldsValue }) => {
                            const oldArticeWorkType = getFieldValue('oldArticleWorkType') ?? [];
                            const oldArticeWorkTypeLength = oldArticeWorkType.length > 0;
                            const isHaveZero = Array.isArray(oldArticeWorkType) && oldArticeWorkType?.includes(2);

                            if (getFieldValue('rewriteandUpdate') === 'Yeniden Yazım') {
                              setFieldsValue({ oldArticleWorkType: 0 });
                              return null;
                            }
                            if (getFieldValue('rewriteandUpdate') === 'Güncelleme' || getFieldValue('atifTam') === 'Atıf') {
                              return (
                                <Form.Item
                                  name="oldArticleWorkType"
                                  labelfor="oldArticleWorkType"
                                  label={getFieldValue('atifTam') === 'Atıf' && <FormattedMessage id="article.form.oldArticleWorkType" />}
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  {getFieldValue('atifTam') === 'Atıf' ? (
                                    <Radio.Group>
                                      <Radio
                                        className="gx-mt-2"
                                        value={4}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToEncyclopedia" />
                                      </Radio>
                                      <br />
                                      <Radio
                                        className="gx-mt-2"
                                        value={5}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToGlossary" />
                                      </Radio>
                                    </Radio.Group>
                                  ) : (
                                    <Checkbox.Group>
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={2}
                                        data-cy="oldTitle-work-type-3"
                                        disabled={oldArticeWorkTypeLength && !isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeReview" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={1}
                                        data-cy="oldTitle-work-type-1"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeSupply" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={3}
                                        data-cy="oldTitle-work-type-4"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeCorrection" />
                                      </Checkbox>
                                    </Checkbox.Group>
                                  )}
                                </Form.Item>
                              );
                            }
                          }}
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>
            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="yakinIfadeZikrediliyorMu"
                labelfor={'yakinIfadeZikrediliyorMu'}
                label={<FormattedMessage id="article.form.NearstExpressionOldArticle" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="yakinIfadeZikrediliyorMu"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value={'Evet'}
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value={'Hayır'}
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item dependencies={['yakinIfadeZikrediliyorMu']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('yakinIfadeZikrediliyorMu') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                        <Form.Item
                          label={<FormattedMessage id="article.form.WhichOldArticles" />}
                          labelfor={'oldArticles'}
                          name={'oldArticles'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            labelInValue="yakinIfadeMaddeler"
                            name="yakinIfadeMaddeler"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, 'Tam')}
                            allowClear
                            showSearch
                            dropdownStyle={{ position: 'relative' }}
                            mode="multiple"
                            onClick={() => setSearchResult([])}
                          >
                            {searchResult.map((d) => (
                              <Option key={d.id}>
                                {d.title} ({d.slug})
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>
            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <Form.Item
                label={<FormattedMessage id="article.form.otherCommitteesQuestion" />}
                labelfor={'otherCommittees'}
                name={'otherCommittees'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  name={'otherCommittees'}
                  placeholder="Heyetler"
                >
                  <Option value={-99}>
                    <FormattedMessage id="article.form.NoCommitteeOffer" />
                  </Option>
                  {committees?.map((item) => (
                    <Option
                      value={item.id}
                      key={item.id}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <Form.Item
                name="offerReason"
                label={<FormattedMessage id="article.form.offerReason" />}
                labelfor={'offerReason'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  name="offerReason"
                  showCount
                  rows={5}
                  placeholder={formatMessage({
                    id: 'article.status.DecisionReasonPlaceHolder',
                  })}
                />
              </Form.Item>
            </Card>
            <Button
              style={{ width: 100, float: 'right' }}
              type="primary"
              htmlType="submit"
              className="gx-mt-2"
              loading={buttonLoading}
            >
              <FormattedMessage id="form.send" />
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ArticleOfferForm;
