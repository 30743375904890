import { Popover, Menu } from 'antd';
import { SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from 'fetcher';
import { useUserInfo } from 'providers/UserInfoProvider';

const UserInfo = () => {
  const navigate = useNavigate();
  const { user } = useUserInfo();

  const signOut = async () => {
    await logout();
    navigate('/giris');
  };

  const userMenuOptions = (
    <Menu>
      <Menu.Item
        key="kullanici-bilgileri"
        icon={<SettingOutlined />}
      >
        <Link to="/kullanici-bilgileri">Kullanıcı Ayarları</Link>
      </Menu.Item>

      <Menu.Item
        icon={<LogoutOutlined />}
        key="logout"
        onClick={signOut}
      >
        Çıkış Yap
      </Menu.Item>
    </Menu>
  );

  return (
    <Popover
      className="gx-p-0"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <span className="gx-avatar-name gx-text-center">
        <UserOutlined style={{ marginRight: 10 }} />
        {user?.fullName ? user?.fullName : ''}
        <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
      </span>
    </Popover>
  );
};

export default UserInfo;
