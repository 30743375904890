import { useState } from 'react';

import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendCommitteeDecision } from 'fetcher';
import { openNotification, showErrorMessage } from 'utils';

export const StartOrderProcess = ({ offer }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [busy, setBusy] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const startProcess = async () => {
    setBusy(true);

    try {
      await sendCommitteeDecision(offer?.id, 'ou', { status: 2 });

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/madde-teklifleri/tum-maddeler');
    } catch (error) {
      showErrorMessage(error);
    }

    setBusy(false);
  };

  return (
    <>
      <Button
        block
        type="primary"
        className="gx-mt-4 gx-mb-0 gx-text-uppercase"
        onClick={() => setModalVisible(true)}
      >
        <FormattedMessage id="article.order.startProcess" />
      </Button>

      <Modal
        footer={null}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        maskClosable={false}
        centered
      >
        <h1 className="gx-text-center gx-text-uppercase">
          <FormattedMessage id="article.order.startProcess" />
        </h1>

        <p className="gx-text-center gx-my-4">
          <FormattedMessage id="article.order.startProcessDescription" />
        </p>

        <p className="gx-text-center gx-my-4 gx-font-weight-medium">
          <FormattedMessage
            id="article.order.startProcessInfo"
            values={{ title: offer?.title, author: offer?.authors?.map(({ author }) => author?.user?.fullName).join('. ') }}
          />
        </p>

        <div className="gx-d-flex">
          <Button
            className="gx-text-uppercase"
            onClick={() => setModalVisible(false)}
            disabled={busy}
            block
          >
            <FormattedMessage id="appModule.no" />
          </Button>

          <Button
            type="primary"
            className="gx-text-uppercase"
            onClick={startProcess}
            loading={busy}
            block
          >
            <FormattedMessage id="appModule.yes" />
          </Button>
        </div>
      </Modal>
    </>
  );
};
