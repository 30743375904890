import { chunkUploadMedia, uploadMedia } from 'utils';

import { easyPost, easyPostToken, easyGetToken, easyPutToken, easyDeleteToken, fileDownload } from './easyFetch';
import { setCookies, removeCookies } from './tokenHandler';

export const login = async (user) => {
  const { data } = await easyPostToken('/login', user);
  setCookies(data.token, data.refreshToken);
  return data;
};

export const logout = async () => {
  const { data } = await easyPostToken('/logout ');
  removeCookies();
  return data;
};

export const passwordResetRequest = async (email) => {
  const res = await easyPost('/reset_password/request', email);
  const { data } = res;
  return data;
};

export const passwordResetValidateToken = async (token) => {
  const res = await easyPost('/reset_password/validate_token', token);
  const { data } = res;
  return data;
};

export const passwordReset = async (values) => {
  const res = await easyPost('/reset_password', values);
  const { data } = res;
  return data;
};

export const adminUserAdd = async (user) => {
  const res = await easyPost('/register', user);
  const { data } = res;
  return data;
};

export const sendOffers = async (offer, fileList) => {
  let media = [];

  if (fileList.length > 0) {
    const uploaded = await uploadMedia(fileList);
    media = uploaded.map((file) => ({ media: file['@id'] }));
  }

  const res = await easyPostToken('/article_offers', { ...offer, media });
  return res;
};

export const editOffer = async (id, data, fileList = [], step = 1) => {
  let media = [];

  if (fileList.length > 0) {
    // dosyaların `/api/media/{id}` formatında olması bekleniyor
    const alreadyUploaded = fileList.filter((file) => file.status === 'done').map((file) => ({ media: `/api/media/${file.uid}` }));

    const toUpload = fileList.filter((file) => file.status !== 'done');
    const uploaded = await uploadMedia(toUpload);

    media = alreadyUploaded.concat(uploaded.map((file) => ({ media: file['@id'] })));
  }

  const res = await easyPutToken(`/article_offers/${id}`, {
    ...data,
    [step === 2 ? 'contentMedia' : 'media']: media,
  });
  return res;
};

export const sendCommitteeDecision = async (id, committee, decision) => {
  const res = await easyPutToken(`/article_offers/decision/${committee}/${id}`, decision);
  return res;
};

export const editStaticPage = async (content, page) => {
  const res = await easyPutToken(`/static_pages/${page}`, content);
  return res;
};

export const renewPassword = async (password) => {
  const res = await easyPutToken('/renew_password', password);
  return res;
};

export const fetchUserList = async (id = '') => {
  const res = await easyGetToken(`/users/${id}`);
  const { data } = res;
  return data;
};

export const fetchUserListAll = async (page = 1, fullName = '') => {
  const res = await easyGetToken(`/users?page=${page}&itemsPerPage=50&fullName=${fullName}`);
  const { data } = res;
  return data;
};

export const fetchCurrentUser = async () => {
  const res = await easyGetToken(`/current_user`);
  const { data } = res;
  return data;
};

export const fetchUserListComitee = async (page = 1, committee = '', fullName = '') => {
  const res = await easyGetToken(`/users?excludedCommittee=${committee}&page=${page}&itemsPerPage=50&fullName=${fullName}`);
  const { data } = res;
  return data;
};

export const editComitees = async (id, comitee) => {
  const res = await easyPutToken(`/committees/${id}`, comitee);
  const { data } = res;
  return data;
};
export const editCommittees = async (comitee) => {
  const res = await easyPostToken(`/committees`, comitee);
  const { data } = res;
  return data;
};

export const addUserToCommitteeFetch = async (comitee) => {
  const res = await easyPostToken(`/committee_members`, comitee);
  const { data } = res;
  return data;
};

export const removeUserFromCommitteeFetch = async (userCommitteId) => {
  const res = await easyDeleteToken(`/committee_members/${userCommitteId}`);
  const { data } = res;
  return data;
};

export const editComiteesManagment = async (id, maneger) => {
  const res = await easyPutToken(`/committees/${id}`, maneger);
  const { data } = res;
  return data;
};

export const editComiteesUsers = async (id, members) => {
  const res = await easyPutToken(`/committee_members/${id}`, members);
  const { data } = res;
  return data;
};

export const ComiteesUsers = async (id) => {
  const res = await easyGetToken(`/committee_members/${id}`);
  const { data } = res;
  return data;
};

export const fetchSearchE1 = async (query, isTam = '') => {
  const res = await easyGetToken(`/article_title_e1s?title=${query}&isTam=${isTam}`);
  const { data } = res;
  return data;
};

export const fetchE1Article = async (id, isTam = '') => {
  const res = await easyGetToken(`/article_title_e1s/${id}?isTam=${isTam}`);
  const { data } = res;
  return data;
};

export const userEdit = async (id, user) => {
  const res = await easyPutToken('/users/' + id, user);
  const { data } = res;
  return data;
};

export const userRenewPassword = async (id, pass) => {
  const res = await easyPutToken('/renew_password/' + id, pass);
  const { data } = res;
  return data;
};

export const addResource = async (resource) => {
  const res = await easyPostToken(`/resources`, resource);
  const { data } = res;
  return data;
};

export const removeResource = async (id) => {
  const res = await easyDeleteToken(`/resources/${id}`);
  const { data } = res;
  return data;
};

export const editResource = async (id, resource) => {
  const res = await easyPutToken(`/resources/${id}`, resource);
  const { data } = res;
  return data;
};

export const removeOfferContributions = async (id) => {
  const res = await easyDeleteToken(`/article_offer_contributions/${id}`);
  const { data } = res;
  return data;
};

export const exportFilteredTable = async (downloadURL) => {
  const res = await fileDownload(downloadURL);
  const { data } = res;
  return data;
};

export const addAuthor = async (author) => {
  const res = await easyPostToken('/authors', author);
  const { data } = res;
  return data;
};

export const editAuthor = async (id, author) => {
  const res = await easyPutToken(`/authors/${id}`, author);
  const { data } = res;
  return data;
};

export const researchGet = async (searchValue) => {
  const res = await easyGetToken(searchValue ? `/research_subjects?name=${searchValue}` : `/research_subjects`);
  const { data } = res;
  return data;
};

export const researchPost = async (body) => {
  const res = await easyPostToken(`/research_subjects`, body);
  const { data } = res;
  return data;
};

export const sendCommitteeOpinion = async (id, opinion) => {
  const res = await easyPutToken(`/give_opinion/${id}`, opinion);
  const { data } = res;
  return data;
};

export const sendPullbackRequest = async (id, committee, request) => {
  const res = await easyPostToken(`/article_offers/pullback/${id}/${committee}/request`, request);
  const { data } = res;
  return data;
};

export const sendPullbackDecision = async (id, committee, decisionData) => {
  const { decision, description } = decisionData;

  const res = await easyPostToken(`/article_offers/pullback/${id}/${committee}/${decision}`, { description });

  const { data } = res;
  return data;
};

export const sendAuthorOpinion = async (id, opinion) => {
  const res = await easyPutToken(`/give_author_opinion/${id}`, opinion);
  return res;
};

export const sendArticleOrderMeet = async (data) => {
  const res = await easyPostToken('/order_meets', data);
  return res;
};

export const updateAuthorContract = async (id, contract, fileList) => {
  const contractData = { ...contract };

  if (fileList) {
    const [file] = await chunkUploadMedia('contract', fileList);
    Object.assign(contractData, { contract: `/api/media/${file.media.id}` });
  }

  const res = await easyPutToken(`/contracts/${id}`, contractData);
  const { data } = res;
  return data;
};

export const sendGuide = async (guide) => {
  const res = await easyPostToken(`/guides`, guide);
  const { data } = res;
  return data;
};
