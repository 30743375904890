import useSWR from 'swr';

const useGuides = (id) => {
  const url = `/guides/${id ?? ''}`;
  const { data, error, mutate } = useSWR(url, { revalidateOnFocus: false });

  return {
    guides: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useGuides;
