import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useTimeLine } from 'useSWR';
import { useWindowSize } from 'hooks';
import { CircularProgress, CustomScrollbars } from 'components';

import TimeLineComponent from './TimeLineComponent';

const TimeLine = ({ id }) => {
  const { timeScale, isLoading } = useTimeLine(id);
  const { height } = useWindowSize();

  if (isLoading) return <CircularProgress />;

  return (
    <Card
      title={
        <h2>
          <FormattedMessage id="article.detail.timeLine.Title" />
        </h2>
      }
      className="gx-mx-5"
    >
      <CustomScrollbars
        autoHeight
        autoHeightMin={100}
        autoHeightMax={height - 360}
      >
        <TimeLineComponent timeScale={timeScale?.['hydra:member']} />
      </CustomScrollbars>
    </Card>
  );
};

export default TimeLine;
