import { useState } from 'react';

import { Button, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { sendAuthorOpinion } from 'fetcher';
import { openNotification } from 'utils';
import { Form, TextArea } from 'components';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

import { ArticleOrderSummary } from './ArticleOrderSummary';

export const AuthorDecision = ({ offer }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);
  const [modals, setModals] = useState({
    accept: false,
    reject: false,
  });

  const onFinish = async (values) => {
    setBusy(true);

    const { id: trackID } = offer?.ouProcessTracks?.at(-1);

    const opinion = {
      opinion: modals.accept ? 'approved' : 'rejected',
      ...(modals.reject && values),
    };

    try {
      await sendAuthorOpinion(trackID, opinion);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/maddelerim');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }

    setBusy(false);
  };

  const AuthorNoteForm = () => (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="description"
        className="gx-mb-4"
        rules={[{ max: 1000, message: formatMessage({ id: 'article.order.authorNoteLengthError' }) }]}
      >
        <TextArea
          rows={6}
          placeholder={formatMessage({ id: `article.order.author${modals.accept ? 'Note' : 'Reject'}Placeholder` })}
          showCount
        />
      </Form.Item>

      <div className="gx-d-flex gx-pt-2">
        <Button
          className="gx-text-uppercase gx-mb-2"
          onClick={() => setModals({ accept: false, reject: false })}
          disabled={busy}
          block
        >
          <FormattedMessage id="appModule.cancel" />
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          className="gx-text-uppercase gx-mb-2"
          loading={busy}
          block
        >
          <FormattedMessage id={`appModule.${modals.accept ? 'approveOffer' : 'reject'}`} />
        </Button>
      </div>
    </Form>
  );

  return (
    <>
      <div className="gx-d-flex">
        <Button
          className="gx-text-uppercase gx-my-0"
          onClick={() => setModals((prev) => ({ ...prev, reject: true }))}
          disabled={busy}
          block
        >
          <FormattedMessage id="appModule.reject" />
        </Button>

        <Button
          type="primary"
          className="gx-text-uppercase gx-my-0"
          onClick={() => setModals((prev) => ({ ...prev, accept: true }))}
          loading={busy}
          block
        >
          <FormattedMessage id="appModule.approveOffer" />
        </Button>
      </div>

      <Modal
        footer={null}
        width={600}
        visible={modals.reject}
        onCancel={() => setModals((prev) => ({ ...prev, reject: false }))}
        maskClosable={false}
        centered
      >
        <h1 className="gx-text-center gx-text-uppercase">
          <FormattedMessage id="appModule.rejected" />
        </h1>

        <p className="gx-text-center gx-my-4 gx-font-weight-medium">
          <FormattedMessage id="appModule.rejectQuestion" />
        </p>

        <AuthorNoteForm />
      </Modal>

      <Modal
        footer={null}
        width={850}
        visible={modals.accept}
        onCancel={() => setModals((prev) => ({ ...prev, accept: false }))}
        maskClosable={false}
        centered
      >
        <h1 className="gx-text-center gx-text-uppercase">
          <FormattedMessage id="appModule.accept" />
        </h1>

        <p className="gx-text-center gx-my-4">
          <FormattedMessage id="article.order.authorAcceptDescription" />
        </p>

        <ArticleOrderSummary offer={offer} />

        <AuthorNoteForm />
      </Modal>
    </>
  );
};
