import { Button, Descriptions, Card, Dropdown, Menu, List } from 'antd';
import { DownOutlined, EditOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { oldArticleWorkTypes, contributorWorks, getReadableFileSize } from 'utils';
import { useUserInfo } from 'providers/UserInfoProvider';

const OfferInfosSection = ({
  offer,
  situation,
  offerOldArticles,
  offerVersions,
  currentOfferStep,
  setversionUrl,
  isValidating,
  formNumber,
  isDraft,
  isView,
}) => {
  const { formatMessage } = useIntl();
  const { memberships, isLoading, isAdmin, isManager } = useUserInfo();

  const editableOfferStatuses = [0, 1, 9, 19];

  const getVersion = () => {
    if (!offerVersions) {
      return 1;
    }
    const version = offerVersions[0]?.version;
    return version ? version + 1 : 1;
  };
  const menu = (
    <Menu
      selectedKeys={'v' + offer?.version ? offer?.version : 0}
      defaultSelectedKeys={'v' + offer?.version ? offer?.version : 0}
      style={{ height: 'auto', overflow: 'auto', maxHeight: 300 }}
    >
      {currentOfferStep <= 1 && (
        <Menu.Item
          onClick={() => {
            setversionUrl('current');
          }}
          key="newest"
        >
          <FormattedMessage
            id="article.draft"
            values={{ version: getVersion() }}
          />
        </Menu.Item>
      )}

      {offerVersions?.map((version) => (
        <Menu.Item
          onClick={() => {
            setversionUrl(version['@id'].replaceAll('/api', ''));
          }}
          key={'v' + version.version}
        >
          v{version.version}
        </Menu.Item>
      ))}
    </Menu>
  );

  const isEditable =
    situation === 'committee' &&
    offer?.step < 2 &&
    editableOfferStatuses.includes(offer?.status) &&
    isManager &&
    memberships?.some((item) => {
      if (offer?.ownerCommittee) return item?.committee?.id === offer?.ownerCommittee?.id;
      if (offer?.otherCommittees) return item?.committee?.id === offer?.otherCommittees[0]?.id;
      return false;
    });

  return (
    <Card
      loading={isValidating || isLoading}
      title={
        <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
          <h2 className="gx-h2-lg gx-mb-0">
            <FormattedMessage id="article.detail.info.title" />
          </h2>

          {isView && (
            <div>
              {!isDraft ? (
                <>
                  <Dropdown
                    getPopupContainer={(trigger) => trigger.parentElement}
                    trigger={['click']}
                    overlay={menu}
                  >
                    <Button className="gx-mb-0">
                      {offer?.version ? 'v' + offer?.version : formatMessage({ id: 'article.draft' }, { version: getVersion() })}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </>
              ) : (
                <Button
                  danger
                  className="gx-mb-0"
                  style={{ cursor: 'default' }}
                >
                  <FormattedMessage id="draft" />
                </Button>
              )}

              {!offer?.version && (isAdmin || isEditable) && (
                <Link to={`/madde-tespit-formu/duzenle/${offer?.id}`}>
                  <Button
                    type="primary"
                    className="gx-mb-0 "
                    icon={<EditOutlined />}
                    title={formatMessage({
                      id: 'article.detail.EditArticle',
                    })}
                    data-cy="edit-article-button"
                  />
                </Link>
              )}
            </div>
          )}
        </div>
      }
    >
      <Descriptions
        layout="horizontal"
        bordered
        column={24}
        className="offer-detail"
      >
        <Descriptions.Item
          className="offer-form-number"
          label={<FormattedMessage id="article.detail.FormNumber" />}
          span={24}
        >
          {formNumber}
        </Descriptions.Item>

        {offer?.ownerCommittee && !isDraft && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.ownerCommittee" />}
            span={24}
          >
            {offer?.ownerCommittee?.name}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={<FormattedMessage id="article.detail.articleTitle" />}
          span={24}
        >
          {offer?.title}
        </Descriptions.Item>

        {offer?.articleGroup && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.articleGroup" />}
            span={24}
          >
            {offer?.articleGroup?.name}
          </Descriptions.Item>
        )}

        {(offer?.articleType || offer?.articleTypeOther) && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.articleType" />}
            span={24}
          >
            {offer?.articleType ? (
              offer?.articleType?.name
            ) : (
              <span>
                <span className="gx-font-weight-semi-bold">
                  <FormattedMessage id="article.detail.articleTypeOther" />
                </span>
              </span>
            )}
          </Descriptions.Item>
        )}

        {offer?.articleTypeOther && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.articleTypeOtherTitle" />}
            span={24}
          >
            {offer?.articleTypeOther}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={<FormattedMessage id="article.detail.committeeContributions" />}
          span={24}
        >
          {offer?.committeeContributions?.length > 0 ? <FormattedMessage id="appModule.yes" /> : <FormattedMessage id="appModule.no" />}
        </Descriptions.Item>

        {offer?.committeeContributions?.length > 0 && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.committeeContributionsWorkType" />}
            span={24}
          >
            {offer?.committeeContributions?.map((item) => (
              <div
                key={item?.committee?.id}
                className="gx-my-2"
              >
                {item?.committee?.name}
                <ul className="gx-m-0">
                  {item?.type?.sort().map((type) =>
                    type !== 5 ? (
                      <li key={type}>{contributorWorks[type]}</li>
                    ) : (
                      <li key={type}>
                        <span className="gx-font-weight-semi-bold">
                          <FormattedMessage id="article.detail.articleTypeOther" />
                        </span>{' '}
                        ({item?.other})
                      </li>
                    )
                  )}
                </ul>
              </div>
            ))}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={<FormattedMessage id="article.detail.oldTitle" />}
          span={24}
        >
          {offer?.oldTitle ? <FormattedMessage id="appModule.yes" /> : <FormattedMessage id="appModule.no" />}
        </Descriptions.Item>

        {offer?.oldTitle?.type && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.oldTitleType" />}
            span={24}
          >
            {offer?.articleGroup?.name && `${offer?.articleGroup?.name} `}({offer?.oldTitle?.type})
          </Descriptions.Item>
        )}

        {offer?.oldTitle && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.oldTitleTitle" />}
            span={24}
          >
            {offer?.oldTitle?.title + ' '}
            <a
              title={offer?.oldTitle?.title}
              href={offer.oldTitle.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkOutlined />
            </a>
          </Descriptions.Item>
        )}

        {offer?.oldTitle && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.oldArticleWorkType" />}
            span={24}
          >
            {offer?.oldArticleWorkType?.map((type, i) => (
              <div key={i}>
                {oldArticleWorkTypes[type]}
                {offer?.oldArticleWorkType?.length - 1 !== i ? ', ' : ''}
              </div>
            ))}
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={<FormattedMessage id="article.detail.oldArticlesQuestion" />}
          span={24}
        >
          {offerOldArticles?.length ? <FormattedMessage id="appModule.yes" /> : <FormattedMessage id="appModule.no" />}
        </Descriptions.Item>

        {offerOldArticles?.length > 0 && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.oldArticles" />}
            span={24}
          >
            {offerOldArticles &&
              offerOldArticles?.map((offer) => (
                <div key={offer?.title}>
                  {offer?.title + ' '}
                  <a
                    title={offer?.title}
                    href={offer.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LinkOutlined />
                  </a>{' '}
                </div>
              ))}
          </Descriptions.Item>
        )}

        {offer?.otherCommittees?.length > 0 && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.otherCommittees" />}
            span={24}
          >
            {offer?.otherCommittees ? (
              offer?.otherCommittees?.map((item) => <div key={item.name}> {item.name}</div>)
            ) : (
              <FormattedMessage id="article.detail.noCommittee" />
            )}
          </Descriptions.Item>
        )}

        {offer?.offerReason && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.offerReason" />}
            span={24}
          >
            {offer.offerReason}
          </Descriptions.Item>
        )}

        {offer?.detectReason && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.detectReason" />}
            span={24}
          >
            {offer.detectReason}
          </Descriptions.Item>
        )}

        {offer?.media?.length > 0 && (
          <Descriptions.Item
            label={<FormattedMessage id="article.detail.media" />}
            span={24}
          >
            <List
              size="small"
              itemLayout="vertical"
              dataSource={offer.media}
              renderItem={({ media }) => (
                <List.Item className="gx-pl-0 gx-py-1">
                  {media.originalFilename} {`(${getReadableFileSize(media)})`}
                  <a
                    href={`${process.env.REACT_APP_PUBLIC_BASE_URL}${media.relativePath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <DownloadOutlined />
                  </a>
                </List.Item>
              )}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  );
};

export default OfferInfosSection;
