export const checkFormValidations = (values, isOffer = false) => {
  let data = {};
  let singleObject;

  for (const value in values) {
    if (value === 'title') {
      singleObject = values.title;
      data = { ...data, [value]: singleObject };
    }
    if (value === 'committeeContributions') {
      singleObject = values[value] ?? [];
      data = {
        ...data,
        [value]: singleObject,
      };
    }
    if (value === 'otherCommittees') {
      if (values.otherCommittees !== -99) {
        if (values.otherCommittees.toString().search('api') > -1) {
          singleObject = [values.otherCommittees];
        } else {
          singleObject = [`/api/committees/${values.otherCommittees}`];
        }
        data = { ...data, [value]: singleObject };
      }
    }
    if (
      ['oldTitle', 'committeeStatus', 'birinciEdisyondaYerAldi'].includes(value)
    ) {
      if (
        value === 'birinciEdisyondaYerAldi' &&
        values.birinciEdisyondaYerAldi === 'Hayır'
      ) {
        singleObject = null;
        data = { ...data, oldTitle: singleObject };
      } else {
        singleObject = values[value]?.value
          ? +values[value].value
          : +values[value];
        data = { ...data, [value]: singleObject };
      }
    }
    if (['oldArticleWorkType', 'rewriteandUpdate'].includes(value)) {
      if (Array.isArray(values[value])) {
        singleObject = values[value] ?? null;
      } else {
        singleObject = [values[value]] ?? null;
      }
      if (value === 'rewriteandUpdate') {
        if (values[value] === 'Yeniden Yazım') {
          singleObject = [0];
          data = { ...data, oldArticleWorkType: singleObject };
        }
      }

      data = { ...data, [value]: singleObject };
    }
    if (
      [
        'statusDescription',
        'statusChangeCommittee',
        'articleGroup',
        'committeeContributionOther',
        'offerReason',
        'ownerCommittee',
        'detectReason',
      ].includes(value)
    ) {
      singleObject = values[value] ?? null;
      data = { ...data, [value]: singleObject };
    }
    if (value === 'articleType') {
      singleObject =
        values.articleType !== 'Diğer'
          ? values.articleType
          : values.articleTypeOther;
      data = {
        ...data,
        [values.articleType !== 'Diğer' ? value : 'articleTypeOther']:
          singleObject,
      };
    }
    if (value === 'committeeContributionType') {
      singleObject = values.committeeContributionType.map((type) => +type);
      data = { ...data, [value]: singleObject };
    }
    if (value === 'oldArticles' || value === 'yakinIfadeZikrediliyorMu') {
      const rightValue = values.yakinIfadeZikrediliyorMu !== 'Hayır';
      singleObject = rightValue
        ? values.oldArticles.map((item) => (+item.value ? +item.value : +item))
        : [];
      data = { ...data, oldArticles: singleObject };
    }
    data = { ...data, isOffer };
  }

  return data;
};
