import useSWR from 'swr';

const useAllOffersVersions = ({
  committeeId = '',
  subMenus = '',
  pageSize = 20,
  orderType = '',
  filtered = '',
}) => {
  let conditionUrl = '';
  let apiUrl = 'article_offer_versions';
  if (subMenus !== '') {
    conditionUrl = subMenus;
    if (subMenus.includes('&isDraft=true')) {
      apiUrl = 'article_offers';
    }
  } else {
    conditionUrl = `&committee=${committeeId}`;
  }

  let url = `/${apiUrl}?${conditionUrl}&itemsPerPage=${pageSize}&order[${[
    orderType.type,
  ]}]=${orderType.order}${filtered}`;

  const { data, error, isValidating, mutate } = useSWR(url);
  return {
    url,
    mutate,
    offers: data,
    isLoading: !error && !data,
    fethcing: isValidating,
    isError: error,
  };
};

export default useAllOffersVersions;
