import { Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useUserInfo } from 'providers/UserInfoProvider';

export const ArticleOrderRejected = () => {
  const { isAuthor, user } = useUserInfo();

  const mockReasons = [
    {
      id: 42,
      fullName: user?.fullName,
      reason: 'Teklif edilen maddeyi akademik yoğunluğumdan dolayı gündemime alamayacağım.',
    },
  ];

  const AuthorRejectReason = ({ author, reason }) => (
    <Card className="gx-mt-3 gx-mb-2">
      <p className="gx-font-weight-medium">{reason}</p>

      <div className="gx-d-flex">
        <UserOutlined className="gx-mr-2" />
        <span className="gx-fs-sm">
          {author?.fullName} {'//'} {dayjs().format('DD.MM.YYYY - HH:mm')}
        </span>
      </div>
    </Card>
  );

  if (isAuthor) {
    const [{ reason }] = mockReasons;
    return (
      <AuthorRejectReason
        author={user}
        reason={reason}
      />
    );
  }

  return mockReasons?.map(({ id, reason, ...author }) => (
    <AuthorRejectReason
      key={id}
      author={author}
      reason={reason}
    />
  ));
};
