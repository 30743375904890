import { fileUpload } from 'fetcher';
import { v4 } from 'uuid';

/**
 * Dosya yüklenirken dosya sırasının bozulmaması için yükleme
 * işleminin sırayla yapılması gerekiyor
 */
export async function uploadMedia(fileList) {
  const uploadedList = await fileList.reduce(async (acc, file) => {
    const res = await acc;
    const formData = new FormData();
    formData.append('media', file.originFileObj);

    const uploaded = await fileUpload('/form_media/upload', formData);
    return [...res, uploaded.data];
  }, Promise.resolve([]));

  return uploadedList;
}

function chunkFile(file, chunkSize = 1000000) {
  const fileChunks = [];
  let start = 0;
  let end = chunkSize;

  while (start < file.size) {
    fileChunks.push(file.slice(start, end));
    start = end;
    end = start + chunkSize;
  }

  return fileChunks;
}

export async function chunkUploadMedia(type, fileList, chunkSize = 1000000) {
  const uploadedList = await fileList.reduce(async (acc, file) => {
    const res = await acc;

    const fileChunks = chunkFile(file.originFileObj, chunkSize);
    const uuid = v4();

    const promises = fileChunks.slice(0, -1).map((chunk, i) => {
      const formData = new FormData();

      formData.append('file', chunk);
      formData.append('uuid', uuid);
      formData.append('index', i + 1);
      formData.append('total', fileChunks.length);
      formData.append('orig', file.name);

      return fileUpload(`/upload/${type}`, formData);
    });

    await Promise.all(promises);

    const lastChunk = fileChunks.at(-1);
    const lastChunkData = new FormData();
    lastChunkData.append('file', lastChunk);
    lastChunkData.append('uuid', uuid);
    lastChunkData.append('index', fileChunks.length);
    lastChunkData.append('total', fileChunks.length);
    lastChunkData.append('orig', file.name);

    const uploaded = await fileUpload(`/upload/${type}`, lastChunkData);
    return [...res, uploaded.data];
  }, Promise.resolve([]));

  return uploadedList;
}
