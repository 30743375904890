import React, { useState } from 'react';

import { Button, Row, Col, Card, Divider } from 'antd';
import { CloseOutlined, EditFilled, FormOutlined, SaveFilled, CloudDownloadOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { mutate } from 'swr';
import { editComitees, removeUserFromCommitteeFetch, addUserToCommitteeFetch } from 'fetcher';
import { useCommittees, useUserList } from 'useSWR';
import { UserListForCommittee, CommitteeMembers } from 'components/Committees/CommitteeDetail/CommitteeObjects';
import { openNotification, exportTable } from 'utils';
import { CircularProgress, Form, Input, Title } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';

const CommitteeDetail = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [wait, setWait] = useState(false);
  const [edit, setEdit] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [editComiteeUser, setEditComiteeUser] = useState(false);
  const [currentPageOptions, setCurrentPageOptions] = useState({
    page: 1,
    pageSize: 50,
    fullName: '',
    excludedCommittee: id,
  });

  const { isAdmin } = useUserInfo();
  const { committees, isLoading: committeLoading } = useCommittees(id);
  const { users: userList, isLoading, mutate: mutateUserList } = useUserList(currentPageOptions);

  const isUpperCommitte = ['18', '19'].includes(id);
  const pageTitle = isUpperCommitte ? committees?.name : formatMessage({ id: 'committees.detail.Title' }, { committee: committees?.name });

  form.setFieldsValue({
    name: committees?.name,
    email: committees?.email,
    shortName: committees?.shortName,
  });

  const userRemoveFromCommittee = async (userCommitteId) => {
    setTableLoading(true);
    try {
      await removeUserFromCommitteeFetch(userCommitteId);
      mutate('/committees/' + id);
      mutateUserList();
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setTableLoading(false);
    }
  };

  const userAddToCommittee = async (user, type) => {
    setTableLoading(true);

    const data = {
      user,
      type,
      committee: committees['@id'],
    };

    try {
      await addUserToCommitteeFetch(data);
      mutate('/committees/' + id);
      mutateUserList();
      setTableLoading(false);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setTableLoading(false);
    }
  };

  const giveAuthorityToUser = async (userId, user, type) => {
    setTableLoading(true);

    const data = {
      id: userId,
      user,
      type,
      committee: committees['@id'],
    };

    try {
      await addUserToCommitteeFetch(data);
      mutate('/committees/' + id);
      mutateUserList();
      setTableLoading(false);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setTableLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      await editComitees(id, values);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      mutate('/committees/' + id);
      setEdit(true);
      setEditComiteeUser(false);
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }
  };

  const handleExportTable = async () => {
    setWait(true);
    const url = `/committee_members/committee/${id}/export`;
    await exportTable(url, pageTitle);
    setWait(false);
  };

  if (!pageTitle || committeLoading || isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{pageTitle}</Title>

      <div className="offers-table-columns gx-d-flex gx-justify-content-between">
        <h1 className="gx-h1-lg">{pageTitle}</h1>

        <Button
          loading={wait}
          disabled={!(committees?.members?.length > 0)}
          onClick={handleExportTable}
          icon={<CloudDownloadOutlined />}
        >
          <FormattedMessage id="app.export" />
        </Button>
      </div>

      <Row justify="center">
        <Col xxl={18}>
          <Form
            style={{ flexDirection: 'row !important' }}
            layout="vertical"
            onFinish={onFinish}
            form={form}
          >
            <Card
              className="gx-mb-2 ant-card-xs"
              title={
                <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-m-0 gx-p-0">
                  <h2
                    style={{ lineHeight: '36px' }}
                    className="gx-mb-0"
                  >
                    <FormattedMessage id="committees.detail.CardTitle" />
                  </h2>

                  {edit && !editComiteeUser && isAdmin && (
                    <Button
                      onClick={() => setEdit(!edit)}
                      htmlType="submit"
                      icon={<EditFilled />}
                      type="primary"
                      className="gx-mb-0"
                    >
                      <FormattedMessage id="committees.detail.EditCommitteeButton" />
                    </Button>
                  )}

                  {!edit && (
                    <Button
                      htmlType="submit"
                      className={'gx-ml-auto'}
                      icon={<SaveFilled />}
                    >
                      <FormattedMessage id="appModule.save" />
                    </Button>
                  )}
                </div>
              }
            >
              <Row
                style={{ flexDirection: 'row' }}
                justify="space-between"
              >
                <Col
                  sm={12}
                  xl={7}
                  className="gx-mb-4"
                >
                  <Form.Item
                    label={<FormattedMessage id="committees.committeeName" />}
                    initialValue={committees?.name}
                    name="name"
                    required
                  >
                    <Input disabled={edit} />
                  </Form.Item>
                </Col>

                <Col
                  xs={0}
                  lg={0}
                  xl={1}
                >
                  <Divider
                    type="vertical"
                    style={{ color: '#e1e1e1', height: '89px' }}
                  />
                </Col>

                <Col
                  sm={12}
                  xl={7}
                  className="gx-mb-4"
                >
                  <Form.Item
                    label={<FormattedMessage id="appModule.email" />}
                    name="email"
                    required
                    initialValue={committees?.email}
                  >
                    <Input disabled={edit} />
                  </Form.Item>
                </Col>

                <Col
                  xs={0}
                  lg={0}
                  xl={1}
                >
                  <Divider
                    type="vertical"
                    style={{ color: '#e1e1e1', height: '89px' }}
                  />
                </Col>

                <Col
                  sm={12}
                  xl={7}
                >
                  <Form.Item
                    label={<FormattedMessage id="committees.committeeShortName" />}
                    name="shortName"
                    required
                    initialValue={committees?.shortName}
                  >
                    <Input disabled={edit} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>

          <Card
            className="gx-mb-2 ant-card-xs"
            title={
              <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-m-0 gx-p-0">
                <h2
                  style={{ lineHeight: '36px' }}
                  className="gx-mb-0"
                >
                  <FormattedMessage id="committees.detail.CommitteeMembers" />
                </h2>

                {edit && !editComiteeUser && isAdmin && (
                  <Button
                    onClick={() => {
                      document.getElementById('userList').scrollIntoView({ behavior: 'smooth', block: 'end' });
                      setEditComiteeUser(!editComiteeUser);
                    }}
                    htmlType="submit"
                    className={'gx-mb-0'}
                    icon={<FormOutlined />}
                    type="primary"
                  >
                    <FormattedMessage id="committees.detail.CommitteeMembersEdit" />
                  </Button>
                )}

                {edit && editComiteeUser && (
                  <Button
                    onClick={() => setEditComiteeUser(false)}
                    className={'gx-mb-0'}
                    icon={<CloseOutlined />}
                    danger
                  >
                    <FormattedMessage id="appModule.cancel" />
                  </Button>
                )}
              </div>
            }
          >
            {committees?.members?.length === 0 ? (
              <FormattedMessage id="committees.detail.HasNoMembers" />
            ) : (
              !committeLoading && (
                <CommitteeMembers
                  userlist={committees?.members}
                  userRemoveFromCommittee={userRemoveFromCommittee}
                  tableLoading={tableLoading}
                  giveAuthorityToUser={giveAuthorityToUser}
                  edit={editComiteeUser}
                />
              )
            )}
          </Card>

          {editComiteeUser && (
            <Card
              className="gx-mb-2 ant-card-xs"
              title={
                <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-m-0 gx-p-0">
                  <h2
                    style={{ lineHeight: '36px' }}
                    className="gx-mb-0"
                  >
                    <FormattedMessage id="userList.title" />
                  </h2>
                </div>
              }
            >
              <UserListForCommittee
                userAddToCommittee={userAddToCommittee}
                users={userList}
                isLoading={isLoading}
                currentPageOptions={currentPageOptions}
                setCurrentPageOptions={setCurrentPageOptions}
              />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CommitteeDetail;
