import apiInstance from './api';
const orderRevertReasonApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAllOrderRevertReason: build.query({
      query: () => ({
        url: 'ou_revert_reasons',
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['OrderRevertReasons'],
    }),
  }),
  overrideExisting: true,
});

export default orderRevertReasonApi;
