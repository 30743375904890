import useSWR from 'swr';

const useGetUserInfo = () => {
  const { data, error, mutate } = useSWR('/current_user', {
    revalidateOnFocus: true,
  });
  let isManager,
    isAdmin,
    isSuperAdmin,
    isResourceManager,
    canOffer,
    memberships = [],
    canWhichCommitteeOffer,
    isDAH,
    isAYK,
    isEncyclopedia,
    isE2User,
    isOrderUnit,
    isAuthor;

  if (data) {
    isSuperAdmin = data.roles.includes('ROLE_SUPER_ADMIN');
    isAdmin = isSuperAdmin || data.roles.includes('ROLE_ADMIN');
    isResourceManager = isAdmin || data.roles.includes('ROLE_ERESOURCE');

    isDAH = data.roles.includes('ROLE_DAH');
    isAYK = data.roles.includes('ROLE_AYK');
    isEncyclopedia = data.roles.includes('ROLE_ENCYCLOPEDIA');
    isE2User = data.roles.includes('ROLE_USER');
    isOrderUnit = data.roles.includes('ROLE_ORDER_UNIT');
    isAuthor = data.roles.includes('ROLE_AUTHOR');

    isManager = isAdmin || data.memberships.some((item) => item.type === 1 || item.type === 0);
    canOffer = isAdmin || isEncyclopedia || data.memberships.some((item) => ![18, 19].includes(item.committee.id) && (item.type === 1 || item.type === 0));
    canWhichCommitteeOffer = data.memberships.filter((item) => ![18, 19].includes(item.committee.id) && (item.type === 1 || item.type === 0));

    memberships = data.memberships;
  }

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    isManager,
    canOffer,
    isResourceManager,
    isAdmin,
    isSuperAdmin,
    memberships,
    mutate,
    canWhichCommitteeOffer,
    isDAH,
    isAYK,
    isEncyclopedia,
    isE2User,
    isOrderUnit,
    isAuthor,
  };
};

export default useGetUserInfo;
