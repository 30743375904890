import { Table, Radio } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMedias } from 'useSWR';
import { fileDownload, sendGuide } from 'fetcher';
import { downloadBlob, getFileExtensionIcon, getReadableFileSize } from 'utils';
import { useFilters, useWindowSize } from 'hooks';
import { MediaUploader } from 'components';

const mediaTypes = [
  {
    key: 'guide',
    accept: ['application/pdf'],
    handler: sendGuide,
  },
  {
    key: 'docs',
    accept: ['application/pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt'],
  },
  {
    key: 'contract',
    accept: ['application/pdf'],
  },
  {
    key: 'photo',
    accept: ['photo/*'],
  },
  {
    key: 'video',
    accept: ['video/*'],
  },
];

const NextPage = ({ filters }) => {
  const { medias } = useMedias({ ...filters, page: filters.page + 1 });

  return (
    <span
      className="gx-d-none"
      data-users={medias}
    />
  );
};

export default function FileManagment() {
  const { formatMessage } = useIntl();

  const { filters, setFilters } = useFilters({
    page: 1,
    pageSize: 20,
    mediaType: 'guide',
  });

  const { height } = useWindowSize();
  const { medias, isLoading: mediasLoading } = useMedias(filters);

  const mediaType = mediaTypes.find((type) => type.key === filters.mediaType);

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    current: filters.page,
    pageSize: filters.pageSize,
    total: medias?.['hydra:totalItems'] ?? 1,
    position: ['bottomRight'],
    responsive: true,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: formatMessage({ id: 'file' }) })} ${total}`,
  };

  const handleTableChange = (tablePagination) => {
    const { current, pageSize } = tablePagination;

    setFilters({
      ...filters,
      page: pageSize === filters.pageSize ? current : 1,
      pageSize,
    });
  };

  async function download(url, name) {
    const file = await fileDownload(url);
    downloadBlob(file.data, name);
  }

  const columns = [
    {
      title: <FormattedMessage id="file.managment.name" />,
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex) => <span>{dataIndex}</span>,
    },
    {
      title: <FormattedMessage id="file.managment.size" />,
      dataIndex: 'fileSize',
      key: 'fileSize',
      render: (_, file) => <span>{getReadableFileSize(file)}</span>,
    },
    {
      title: <FormattedMessage id="file.managment.types" />,
      dataIndex: 'fileExtension',
      key: 'fileExtension',
      render: (ext, file) => (
        <div className="gx-d-flex gx-align-items-center">
          <span className=" gx-fs-xl">{getFileExtensionIcon(file)}</span>
          <span className="gx-ml-2 gx-mt-1">{ext.toUpperCase()}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="file.managment.action" />,
      dataIndex: 'fileFullUrl',
      render: (dataIndex, data) => (
        <a onClick={() => download(dataIndex, data?.name)}>
          <FormattedMessage id="appModule.download" />
        </a>
      ),
    },
  ];

  return (
    <div>
      <h1>
        <FormattedMessage id="file.managment.title" />
      </h1>

      <Radio.Group
        onChange={(type) => setFilters({ ...filters, mediaType: type?.target.value })}
        defaultValue={filters?.mediaType}>
        {mediaTypes.map((type, i) => (
          <Radio.Button
            key={i}
            value={type.key}
          >
            <FormattedMessage id={`file.type.${type.key}`} />
          </Radio.Button>
        ))}
      </Radio.Group>

      <MediaUploader mediaType={mediaType} />

      <Table
        bordered
        columns={columns}
        dataSource={medias?.['hydra:member']}
        rowKey={(record) => record['@id']}
        loading={mediasLoading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 990, y: height - 550 }}
      />

      <NextPage filters={filters} />
    </div>
  );
}
