import { Card, Col, Row } from 'antd';
import { FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { isamAddress, orderDepartmentInfo } from 'constant';
import { UpsIcon } from 'components/icons';

import { ArticleOrderSummary } from './ArticleOrderSummary';

export const ArticleOrderAccepted = ({ offer }) => (
  <>
    <Card className="gx-my-3">
      <div className="gx-d-flex gx-align-items-center">
        <FilePdfOutlined className="gx-fs-xl gx-mr-2" />
        <p className="gx-m-0">Uploaded_file_name.pdf</p>
      </div>
    </Card>

    <ArticleOrderSummary offer={offer} />

    <Card>
      <Row>
        <Col span={8}>
          <div className="gx-d-flex gx-align-items-center gx-py-2 gx-px-4">
            <MailOutlined className="gx-fs-xxxl gx-mr-3" />

            <div className="gx-d-flex gx-flex-column">
              <span className="gx-font-weight-medium gx-word-break-word">
                <FormattedMessage id="article.order.postalInfo" />
              </span>
              <span>{isamAddress}</span>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="gx-d-flex gx-align-items-center gx-py-2 gx-px-4">
            <MailOutlined className="gx-fs-xxxl gx-mr-3" />

            <div className="gx-d-flex gx-flex-column">
              <span className="gx-font-weight-medium">
                <FormattedMessage id="article.order.departmentSupervisor" />
              </span>
              <span>{orderDepartmentInfo.supervisor}</span>
              <FormattedHTMLMessage
                id="appModule.phoneWithInternal"
                values={{ number: orderDepartmentInfo.phone, internal: orderDepartmentInfo.internal }}
              />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="gx-d-flex gx-align-items-center gx-py-2 gx-px-4">
            <UpsIcon className="gx-fs-xxxl gx-mr-3" />

            <div className="gx-d-flex gx-flex-column">
              <span className="gx-font-weight-medium">
                <FormattedMessage
                  id="appModule.shipmentInfo"
                  values={{ firm: 'UPS' }}
                />
              </span>

              <div>
                <span className="gx-font-weight-medium">
                  <FormattedMessage id="appModule.phone" />
                </span>
                {`: ${orderDepartmentInfo.shippingPhone}`}
              </div>

              <div>
                <span className="gx-font-weight-medium">
                  <FormattedMessage id="appModule.shipmentCode" />
                </span>
                {`: ${orderDepartmentInfo.shippingCode}`}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  </>
);
