import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { getCookies } from 'fetcher';
import { Loading } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

export const AppRedirect = () => {
  const navigate = useNavigate();
  const { isLoading } = useUserInfo();

  useEffect(() => {
    const { token: tokenCookie, refreshToken: rtCookie } = getCookies();
    if (!isLoading) {
      if (tokenCookie && rtCookie) {
        navigate('/anasayfa');
      } else {
        navigate('/giris');
      }
    }
  }, [navigate, isLoading]);

  return <Loading />;
};
