import { DeleteOutlined, InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getReadableFileSize, showErrorMessage } from 'utils';

const defaultAccept = ['image/*', 'video/*', 'application/pdf', '.pdf'];

export const CustomUpload = ({ icon, title, fileList, setFileList, disabled, accept = defaultAccept, maxCount = Infinity, maxFileSize = 25 }) => {
  const { formatMessage } = useIntl();

  function renderItem(origin, file, fileList, actions) {
    const { remove } = actions;
    const size = getReadableFileSize(file);

    return (
      <div className="ant-upload-list-item ant-upload-list-item-list-type-text gx-mt-3 gx-mx-1">
        <div className="ant-upload-list-item-info">
          <span className="ant-upload-span">
            <div className="ant-upload-text-icon">
              <span
                role="img"
                aria-label="paper-clip"
                className="anticon anticon-paper-clip"
              >
                <PaperClipOutlined />
              </span>
            </div>

            <span
              className="ant-upload-list-item-name"
              title={file.name}
            >
              {file.name} {`(${size})`}
            </span>

            {!disabled && (
              <span className="ant-upload-list-item-card-actions">
                <button
                  type="button"
                  className="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                  onClick={remove}
                >
                  <span
                    role="img"
                    aria-label="delete"
                    tabIndex="-1"
                    className="anticon anticon-delete"
                  >
                    <DeleteOutlined />
                  </span>
                </button>
              </span>
            )}
          </span>
        </div>
      </div>
    );
  }

  function beforeUpload(file) {
    // convert maxFileSize from mb to bytes
    const maxSize = maxFileSize * 1000000;

    if (file.size > maxSize) {
      showErrorMessage(formatMessage({ id: 'app.maxFileSize' }, { size: `${maxFileSize} MB` }));
      return false;
    }

    setFileList((state) => [...state, file]);
    return false;
  }

  function onChange(info) {
    setFileList(info.fileList);
  }

  function removeItem(file) {
    setFileList((state) => {
      const index = state.indexOf(file);
      const newFileList = state.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  }

  return (
    <Upload.Dragger
      multiple
      accept={accept.join(',')}
      fileList={fileList}
      maxCount={maxCount}
      itemRender={renderItem}
      onChange={onChange}
      onRemove={removeItem}
      beforeUpload={beforeUpload}
      disabled={disabled}
    >
      <p className="ant-upload-drag-icon">{icon || <InboxOutlined />}</p>

      <div className="ant-upload-text">
        {title && <p className="gx-font-weight-semi-bold gx-mb-2">{title}</p>}

        <span className="gx-px-2">
          <FormattedMessage id="app.selectFile" />
        </span>
      </div>

      <p className="ant-upload-hint gx-mt-3">
        <FormattedMessage
          id="app.maxFileSize"
          values={{ size: `${maxFileSize} MB` }}
        />
      </p>

      {maxCount !== Infinity && (
        <p className="ant-upload-hint gx-mt-1">
          <FormattedMessage
            id="app.maxFileCount"
            values={{ count: maxCount }}
          />
        </p>
      )}
    </Upload.Dragger>
  );
};
