import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Button, Card, Radio, Select, Checkbox, Descriptions, Switch, Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl, FormattedHTMLMessage } from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { useCommittees } from 'useSWR';
import { sendCommitteeDecision, FETCH_RESULTS } from 'fetcher';
import { openNotification, formType, heyetKarariMetin } from 'utils';
import { CircularProgress, Form, Input } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError, setShouldScroll } from 'slices/commonSlice';

import { PullbackStatus } from '../ArticlePullback';

import Refund from './Refund';

const { TextArea } = Input;
const { Option } = Select;

// Madde tekliflerine geri dönmek için navigate'a gönderilen değer
let redirectParams = '';

const ArticleStatus = ({ offer, situation, hasActivePullback }) => {
  const {
    id,
    dahStatus,
    statusChangeCommittee,
    dahDescription,
    committeeStatus,
    committeeContributions,
    statusDescription,
    ownerCommittee,
    aykStatus,
    aykDescription,
    otherCommittees,
    dahStatusChangeCommittee,
    dahStatusIsConditional,
    dahRevertReasonAnswers,
    aykRevertReasonAnswers,
    isOffer,
    hasDecided,
    canDecide,
    isDraft,
    isView,
    step,
  } = offer;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const offerTableURL = useSelector(({ common }) => common.offerTableURL);
  const offerTablePage = useSelector(({ common }) => common.offerTablePage);
  const offerTablePageSize = useSelector(({ common }) => common.offerTablePageSize);
  const filterModalURL = useSelector(({ common }) => common.filterModalURL);

  const { committees } = useCommittees('categoryOne');
  const { memberships, isAdmin, isLoading } = useUserInfo();
  const [alert, setAlert] = useState(false);
  const [commiteeAlert, setCommiteeAlert] = useState(false);
  const [editDecision, setEditDecision] = useState(false);
  const [loading, setLoading] = useState(false);
  // madde tekliflerine geri dönülüp dönülmemesi durumu
  const [shouldRedirect, setRedirect] = useState(false);

  const commiteeId = ownerCommittee !== null ? ownerCommittee.id : otherCommittees.length !== 0 ? otherCommittees[0].id : { id: -100 };

  const formData = {
    ayk: {
      id: 19,
      status: aykStatus,
      description: aykDescription,
      revertAnswers: aykRevertReasonAnswers,
    },
    dah: {
      id: 18,
      status: dahStatus,
      description: dahDescription,
      revertAnswers: dahRevertReasonAnswers,
    },
    committee: {
      id: commiteeId,
      status: committeeStatus,
      description: statusDescription,
      revertAnswers: [],
    },
  };

  const isMember = memberships.some((item) => item.committee.id === formData[situation].id && [0, 1].includes(item.type));

  const canPullback = useMemo(
    () => {
      return (
        (isAdmin || isMember) &&
        ((situation === 'committee' && [0, 8].includes(formData.dah.status)) || (situation === 'dah' && [0, 8].includes(formData.ayk.status)))
      );
    }, // eslint-disable-next-line
    [isAdmin, isMember, situation]
  );

  const editable = useMemo(
    () => {
      if (isAdmin) return true;

      const editableStatuses = [0, 1, 7, 8, situation === 'committee' ? 4 : -1];
      return isMember && editableStatuses.includes(formData[situation].status);
    }, // eslint-disable-next-line
    [isAdmin, isMember, memberships, situation]
  );

  const onCommitteeChange = (value) => {
    const isContributor = committeeContributions.some((c) => c.committee.id === value);
    setCommiteeAlert(isContributor);
  };

  /**
   * async olan onFinish fonksiyonu ile navigate() çağrıldığında oluşabilen memory leak
   * ve sayfa yanıt vermiyor hatasının çözümü için useEffect ve useCallback kullanılarak alternatif
   * bir çözüm yapısı kuruldu.
   */
  const removeRedirect = useCallback(() => {
    setRedirect(false);
  }, [setRedirect]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectParams);
      removeRedirect();
    }
  }, [shouldRedirect, navigate, removeRedirect]);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      let data;
      if (values.status === 3) {
        let answers = { ...values };
        Object.keys(answers).forEach(
          (key) =>
            (answers[key] === undefined || key.includes('checkbox') || ['refundReasons', 'accept', 'other', 'status', 'to'].includes(key)) &&
            delete answers[key]
        );
        data = {
          status: 3,
          description: values.other ?? values.description,
          answers,
          ...(situation === 'ayk' && { to: values.to }),
        };
      } else {
        data = {
          status: values.status,
          description: values.other ?? (values.description || null),

          ...(values.statusChangeCommittee && {
            statusChangeCommittee: values.statusChangeCommittee ?? null,
          }),
        };
      }

      const result = await sendCommitteeDecision(id, situation, data);

      // check if result code is 200
      if (result.status !== FETCH_RESULTS.OK) {
        throw result.status;
      }

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      setLoading(false);
      dispatch(setShouldScroll(true));

      const params = new URLSearchParams({
        page: offerTablePage,
        pageSize: offerTablePageSize,
      });
      redirectParams = `${offerTableURL}?&${params.toString()}${filterModalURL}`;
      setRedirect(true);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Card
        style={{
          marginBottom: 31,
          top: 0,
          minHeight: 'auto',
          overflow: 'hidden',
        }}
        title={
          <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
            <h2 className="gx-mb-0">{formType[situation]}</h2>

            {step < 2 && (
              <div className="gx-d-flex gx-justify-content-end gx-align-items-center">
                {canPullback && (
                  <PullbackStatus
                    id={id}
                    committee={situation}
                  />
                )}

                {editable && (
                  <Tooltip
                    placement="left"
                    title={hasActivePullback && <FormattedMessage id="article.status.pullback.decideTooltip" />}
                  >
                    <span>
                      <Button
                        type="primary"
                        className="gx-mb-0"
                        disabled={hasActivePullback || !isView}
                        onClick={() => {
                          setEditDecision(!editDecision);
                        }}
                      >
                        <FormattedMessage id="article.status.giveDecision" />
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        }
      >
        {editable && editDecision ? (
          <Form
            form={form}
            labelAlign="right"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item shouldUpdate>
              {({ setFieldsValue }) => (
                <Form.Item
                  name="status"
                  labelfor={'status'}
                  label="Heyet Kararı"
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="appModule.requiredMessage" />,
                    },
                  ]}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={() => setFieldsValue({ offerReasonSwitch: false })}
                  >
                    <Radio.Button
                      onClick={() => situation === 'committee' && (isOffer || !canDecide) && setAlert(true)}
                      value={2}
                      data-cy="accept"
                    >
                      <FormattedMessage id="article.committeeDecisionAccept" />
                    </Radio.Button>
                    {situation === 'dah' && (
                      <Radio.Button
                        value={6}
                        data-cy="accept-annotated"
                      >
                        <FormattedMessage id="article.committeeDecisionAcceptAnnotated" />
                      </Radio.Button>
                    )}
                    {situation !== 'committee' && (
                      <Radio.Button
                        value={3}
                        data-cy="revent"
                      >
                        <FormattedMessage id="article.committeeDecisionRevent" />
                      </Radio.Button>
                    )}

                    {['dah'].includes(situation) && (
                      <Radio.Button
                        value={4}
                        data-cy="change-committee"
                      >
                        <FormattedMessage id="article.committeeDecisionChangeCommittee" />
                      </Radio.Button>
                    )}
                    {['ayk'].includes(situation) && (
                      <Radio.Button
                        value={5}
                        data-cy="reject"
                      >
                        <FormattedMessage id="article.committeeDecisionReject" />
                      </Radio.Button>
                    )}
                    <Tooltip title={isDraft && 'Taslak formlar için "Beklet" kararı verilememektedir.'}>
                      <Radio.Button
                        value={1}
                        data-cy="wait"
                        disabled={isDraft}
                      >
                        <FormattedMessage id="article.committeeDecisionWait" />
                      </Radio.Button>
                    </Tooltip>
                  </Radio.Group>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                getFieldValue('status') === 3 &&
                ['dah', 'ayk'].includes(situation) && (
                  <Refund
                    id={formData[situation].id}
                    ownerCommittee={ownerCommittee}
                  />
                )
              }
            </Form.Item>

            {situation === 'committee' && (
              <SweetAlert
                warning
                cancelBtnText={<FormattedMessage id="appModule.cancel" />}
                showCancel
                closeAnim
                confirmBtnText={
                  <Link
                    className="gx-m-0 gx-p-2 gx-text-white"
                    to={`/madde-tespit-formu/duzenle/${id}`}
                  >
                    <FormattedMessage id="article.detail.EditArticle" />
                  </Link>
                }
                title={<FormattedMessage id="appModule.danger" />}
                show={alert}
                onCancel={() => {
                  setAlert(false);
                  form.resetFields();
                }}
                confirmBtnStyle={{ zIndex: -1, padding: 0, border: 'none' }}
              >
                <div
                  style={{ lineHeight: '150%' }}
                  className="gx-mt-2 gx-font-weight-normal"
                >
                  <FormattedMessage id="article.status.CommitteOfferAccepInfoMessage" />
                </div>
              </SweetAlert>
            )}

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                getFieldValue('status') === 4 && (
                  <Form.Item
                    label={<FormattedMessage id="article.committeeDecisionChangeCommittee" />}
                    labelfor={'statusChangeCommittee'}
                    name={'statusChangeCommittee'}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="appModule.requiredMessage" />,
                      },
                    ]}
                    // initialValue={
                    //   situation === 'dah'
                    //     ? dahStatusChangeCommittee?.id
                    //     : statusChangeCommittee?.id
                    // }
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      placeholder="Heyetler"
                      style={{ width: '100%' }}
                      onChange={onCommitteeChange}
                    >
                      {committees?.map((item) => (
                        <Option
                          key={item?.id}
                          value={item?.id}
                          disabled={ownerCommittee?.id === item?.id}
                        >
                          {item.name} Heyeti
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item dependencies={['status']}>
              {({ getFieldValue }) =>
                situation !== 'committee' &&
                getFieldValue('status') === 2 && (
                  <Form.Item
                    className="gx-my-2"
                    label={<FormattedMessage id="article.status.committeReason" />}
                    name="offerReasonSwitch"
                    valuePropName="checked"
                  >
                    <Switch onChange={(e) => setAlert(e)} />
                  </Form.Item>
                )
              }
            </Form.Item>

            {situation === 'dah' && (
              <SweetAlert
                warning
                closeAnim
                confirmBtnText={<FormattedMessage id="appModule.okey" />}
                title={<FormattedMessage id="appModule.danger" />}
                show={alert}
                onConfirm={() => setAlert(false)}
              >
                <div className="gx-mt-2">
                  <FormattedHTMLMessage id="article.status.committeAcceptAlertMessage" />
                </div>
              </SweetAlert>
            )}

            <Form.Item dependencies={['statusChangeCommittee']}>
              {({ getFieldValue }) =>
                situation === 'dah' &&
                getFieldValue('statusChangeCommittee') && (
                  <SweetAlert
                    warning
                    closeAnim
                    confirmBtnText={<FormattedMessage id="appModule.okey" />}
                    title={<FormattedMessage id="appModule.danger" />}
                    show={commiteeAlert}
                    onConfirm={() => setCommiteeAlert(false)}
                  >
                    <div className="gx-mt-2">
                      <FormattedHTMLMessage
                        id="article.status.committeChangeAlertMessage"
                        values={{
                          committee: committees.find((c) => c.id === getFieldValue('statusChangeCommittee')).name,
                        }}
                      />
                    </div>
                  </SweetAlert>
                )
              }
            </Form.Item>

            <Form.Item dependencies={['status', 'offerReasonSwitch']}>
              {({ getFieldValue }) =>
                ((getFieldValue('offerReasonSwitch') === true && ['dah', 'ayk', 'committee'].includes(situation)) ||
                  [1, 6, 5, 4].includes(getFieldValue('status')) ||
                  ((situation === 'committee' && getFieldValue('status')) === 2 && !isOffer)) && (
                  <Form.Item
                    label={<FormattedMessage id="article.status.committeReason" />}
                    colon={false}
                    labelfor="description"
                    name="description"
                    rules={[
                      {
                        required: getFieldValue('status') !== 2 || (situation !== 'dah' && hasDecided),
                        message: <FormattedMessage id={situation === 'dah' ? 'article.status.committeAcceptErrorMessage' : 'appModule.requiredMessage'} />,
                      },
                      {
                        min: 100,
                        message: <FormattedMessage id="article.reasonLengthMessage" />,
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      placeholder={formatMessage({
                        id:
                          situation === 'committee' && getFieldValue('status') === 2
                            ? 'article.status.DecisionReasonPlaceHolderForCommittee'
                            : 'article.status.DecisionReasonPlaceHolder',
                      })}
                      showCount
                      data-cy="committee-reasons"
                    />
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item
              name="accept"
              valuePropName="checked"
            >
              <div className="gx-text-right gx-mt-5">
                <Checkbox data-cy="decision-okey">
                  <FormattedMessage id="article.status.approveDecision" />
                </Checkbox>
                <h6 style={{ color: 'red' }}>
                  <FormattedMessage id="article.status.approveDecisionCantChange" />
                </h6>
              </div>
            </Form.Item>

            <Form.Item dependencies={['accept']}>
              {({ getFieldValue }) => (
                <Form.Item>
                  <Button
                    style={{ width: 100, float: 'right' }}
                    type="primary"
                    disabled={!getFieldValue('accept')}
                    htmlType="submit"
                    loading={loading}
                    data-cy="send"
                  >
                    <FormattedMessage id="appModule.send" />
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          </Form>
        ) : (
          <Descriptions
            layout="horizontal"
            bordered
            column={24}
            className="decision"
          >
            <Descriptions.Item
              span={24}
              label={<FormattedMessage id="article.status.committeeDecision" />}
            >
              <div className="gx-text-uppercase">
                {situation === 'dah' && dahStatusIsConditional ? (
                  <FormattedMessage id="article.committeeDecisionAcceptAnnotated" />
                ) : (
                  heyetKarariMetin[step > 1 ? 2 : formData[situation]?.status]
                )}
              </div>
            </Descriptions.Item>

            {isView && step < 2 && formData[situation].revertAnswers && formData[situation].status === 3 && (
              <Descriptions.Item
                label={<FormattedMessage id="article.status.reventReasons" />}
                span={24}
              >
                <ul className="gx-m-2 gx-p-0">
                  {formData[situation]?.revertAnswers?.map((item) => (
                    <li
                      key={item.id}
                      className="gx-mb-1"
                    >
                      <div className="gx-font-weight-semi-bold gx-mb-1">{item.revertReason.title}</div>
                      <q>{item.answer}</q>
                    </li>
                  ))}
                  {(dahStatus === 3 || aykStatus === 3) && formData[situation]?.description && (
                    <li className="gx-mb-1">
                      <div className="gx-font-weight-semi-bold gx-mb-1">
                        <FormattedMessage id="article.detail.articleTypeOther" />
                      </div>
                      <q>{formData[situation]?.description}</q>
                    </li>
                  )}
                </ul>
              </Descriptions.Item>
            )}

            {step === 1 && (formData[situation] === 'committee' || formData[situation].status !== 3) && formData[situation]?.description && (
              <Descriptions.Item
                label="Heyet Gerekçesi"
                span={24}
              >
                {formData[situation]?.description}
              </Descriptions.Item>
            )}

            {(statusChangeCommittee?.id || dahStatusChangeCommittee?.id) && (
              <Descriptions.Item
                label={<FormattedMessage id="article.status.committeReason" />}
                span={24}
              >
                {statusChangeCommittee?.name || dahStatusChangeCommittee?.name} İlim Heyeti
              </Descriptions.Item>
            )}
          </Descriptions>
        )}
      </Card>
    </>
  );
};

export default ArticleStatus;
