import apiInstance from './api';

const versionApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query({
      query: () => ({
        url: '/fversion',
        method: 'GET',
      }),
      providesTags: ['Version'],
    }),
  }),
  overrideExisting: true,
});

export default versionApi;
