import useSWR from 'swr';

const useCommittee = (id) => {
  const { data, error } = useSWR(`/committees/${id}`);

  return {
    committee: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCommittee;
