import dayjs from 'dayjs';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

export default ({ id, key = 'time', start, end, uppercaseDate }) => {
  const { formatMessage } = useIntl();

  const endDate = dayjs(end);
  const days = endDate.diff(start, 'days');
  const hours = endDate.diff(start, 'hours');
  const minutes = endDate.diff(start, 'minutes');

  let data;

  if (hours >= 24) {
    data = ['appModule.nDays', days];
  } else if (minutes >= 60) {
    data = ['appModule.nHours', hours];
  } else {
    data = ['appModule.nMinutes', minutes];
  }

  const [delim, value] = data;
  let message = formatMessage({ id: delim }, { value });

  if (uppercaseDate) {
    message = message.toUpperCase();
  }

  return <FormattedHTMLMessage id={id} values={{ [key]: message }} />;
};
