import { useState } from 'react';

import { Dropdown, Menu } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, EllipsisOutlined, FilePdfOutlined, PlayCircleOutlined, ReadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { OrderCancelModal } from './CancelModal';
import { OrderMailModal } from './MailModal';
import { OrderAgreementModal } from './AgreementModal';

export const ArticleOrderActions = ({ offer, author, mutateArticles }) => {
  const [modals, setModals] = useState({
    cancel: false,
    mail: false,
    agreement: false,
  });

  if (!author && (offer?.targetOffer?.step < 3 || offer?.step < 3)) return '-';

  const authorItems = [
    {
      key: 'approve',
      menu: (
        <Link to={`/madde-detay/${offer?.targetOffer?.id}`}>
          <Menu.Item
            key="approve"
            disabled={offer?.targetOffer?.status !== 21}
          >
            <PlayCircleOutlined className="gx-mr-2" />
            <FormattedMessage id="appModule.approveOffer" /> / <FormattedMessage id="appModule.reject" />
          </Menu.Item>
        </Link>
      ),
      modal: null,
    },
    {
      key: 'agreement',
      menu: (
        <Menu.Item
          key="agreement"
          disabled={offer?.targetOffer?.status !== 21}
        >
          <FilePdfOutlined className="gx-mr-2" />
          <FormattedMessage id="appModule.agreement" />
        </Menu.Item>
      ),
      modal: null,
    },
    {
      key: 'article',
      menu: (
        <Menu.Item
          key="uploadArticle"
          disabled={offer?.targetOffer?.status !== 22}
        >
          <ReadOutlined className="gx-mr-2" />
          <FormattedMessage id="appModule.uploadArticle" />
        </Menu.Item>
      ),
      modal: null,
    },
  ];

  const offerItems = [
    {
      key: 'start',
      menu: (
        <Link to={`/madde-detay/${offer?.targetOffer?.id}`}>
          <Menu.Item
            key="start"
            disabled={offer?.targetOffer?.status !== 20}
          >
            <PlayCircleOutlined className="gx-mr-2" />
            <FormattedMessage id="appModule.startProcess" />
          </Menu.Item>
        </Link>
      ),
      modal: null,
    },
    {
      key: 'cancel',
      menu: (
        <Menu.Item key="cancel">
          <CloseCircleOutlined className="gx-mr-2" />
          <FormattedMessage id="appModule.cancelAction" />
        </Menu.Item>
      ),
      modal: (
        <OrderCancelModal
          offer={offer}
          open={modals.cancel}
          close={() => mutateArticles() && setModals((prev) => ({ ...prev, cancel: false }))}
        />
      ),
    },
    {
      key: 'mail',
      menu: (
        <Menu.Item key="mail">
          <ClockCircleOutlined className="gx-mr-2" />
          <FormattedMessage id="appModule.sendMail" />
        </Menu.Item>
      ),
      modal: (
        <OrderMailModal
          offer={offer}
          open={modals.mail}
          close={() => setModals((prev) => ({ ...prev, mail: false }))}
        />
      ),
    },
    {
      key: 'agreement',
      menu: (
        <Menu.Item
          key="agreement"
          disabled={offer?.targetOffer?.status !== 22}
        >
          <FilePdfOutlined className="gx-mr-2" />
          <FormattedMessage id="appModule.agreement" />
        </Menu.Item>
      ),
      modal: (
        <OrderAgreementModal
          offer={offer}
          open={modals.agreement}
          close={() => setModals((prev) => ({ ...prev, agreement: false }))}
        />
      ),
    },
  ];

  const actionsMenu = (
    <Menu
      onClick={({ key }) => {
        setModals((prev) => ({ ...prev, [key]: true }));
      }}
    >
      {author ? authorItems.map((c) => c.menu) : offerItems.map((c) => c.menu)}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={actionsMenu}
        placement="bottomRight"
        trigger="click"
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>

      {author ? authorItems.map((c) => modals[c.key] && c.modal) : offerItems.map((c) => modals[c.key] && c.modal)}
    </>
  );
};
