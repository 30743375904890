import {
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';

export function getReadableFileSize(file) {
  const ext = ['byte', 'KB', 'MB', 'GB', 'TB'];
  let size = file.size || file.filesize || file.fileSize;
  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  return Math.round(size * 100) / 100 + ' ' + ext[i];
}

export function getFileExtensionIcon(file) {
  switch (file.extension || file.fileExtension) {
    case 'doc':
    case 'docx':
      return <FileWordOutlined />;
    case 'xls':
    case 'xlsx':
      return <FileExcelOutlined />;
    case 'ppt':
    case 'pptx':
      return <FilePptOutlined />;
    case 'pdf':
      return <FilePdfOutlined />;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'bmp':
      return <FileImageOutlined />;
    case 'gif':
      return <FileGifOutlined />;
    case 'zip':
      return <FileZipOutlined />;
    default:
      return <FileOutlined />;
  }
}
