import useSWR from 'swr';

const useOffers = (id) => {
  const { data, error, isValidating, mutate } = useSWR(
    id ? `/article_offers/${id}` : '',
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  return {
    offer: data,
    isLoading: !error && !data,
    fetching: isValidating,
    isError: error,
    offerMutate: mutate,
  };
};

export default useOffers;
