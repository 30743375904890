import React from 'react';

import { Layout, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from 'constant/themeSettings';
import { useWindowSize } from 'hooks';
import { getCookies, switchUser } from 'fetcher';
import { ISAMDiaLogoWh } from 'containers/Logos';
import UserInfo from 'components/UserInfo';
import { useUserInfo } from 'providers/UserInfoProvider';
import { toggleCollapsedNav } from 'slices/commonSlice';

const { Header } = Layout;

const Topbar = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const { xSwitchUser } = getCookies();
  const { isAdmin, isSuperAdmin } = useUserInfo();

  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const urlCheck = window.location.origin === process?.env.REACT_APP_TEST_URL;

  const adminController = isSuperAdmin ? 'Super Admin' : isAdmin && 'Admin';
  return (
    <Header className="gx-text-white header-fixed">
      {navStyle === NAV_STYLE_MINI_SIDEBAR && (
        <Link
          to="/anasayfa"
          className="gx-d-none gx-d-lg-block gx-pointer"
          style={{ zIndex: 99 }}
        >
          <ISAMDiaLogoWh
            className="logoPosition"
            width={220}
            height={70}
          />
        </Link>
      )}

      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
        <div
          className="gx-linebar gx-mr-0 gx-pt-1"
          style={{ zIndex: 99 }}
        >
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link
        to="/anasayfa"
        className="gx-d-block gx-d-lg-none gx-pointer gx-mr-auto gx-pt-2"
        style={{ zIndex: 99 }}
      >
        <ISAMDiaLogoWh
          width={220}
          height={70}
        />
      </Link>
      <div className={`gx-d-flex gx-layout-sider-header header-content-container ${urlCheck ? 'gx-pt-4' : ''}`}>
        {xSwitchUser && (
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: 5,
              padding: 14,
              textColor: '#000',
              cursor: 'pointer',
            }}
            className="gx-d-flex gx-justify-content-center gx-text-black gx-mx-auto"
            onClick={() => {
              switchUser();
              location.href = '/uye-islemleri/uye-listesi';
            }}
          >
            <span>
              Şu an <span className="gx-font-weight-medium gx-text-underline">{xSwitchUser} </span> kullanıcısının gözünden görüyorsunuz. Profili değiştirmek
              için tıklayın
            </span>
          </div>
        )}

        <ul className="gx-header-notifications gx-ml-auto">
          {false && (
            <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-search-new" />
                </span>
              </Popover>
            </li>
          )}

          {false && (
            <>
              <li className="gx-notify">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  trigger="click"
                >
                  <span className="gx-pointer gx-d-block">
                    <i className="icon icon-notification" />
                  </span>
                </Popover>
              </li>

              <li className="gx-msg">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  // content={<MailNotification />}
                  trigger="click"
                >
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new" />
                    <span className="gx-status gx-status-rtl gx-small gx-orange" />
                  </span>
                </Popover>
              </li>
            </>
          )}

          {isAdmin && (
            <li className="gx-language">
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <span className="gx-pl-2 gx-language-name">{adminController}</span>
              </span>
            </li>
          )}

          <li className="gx-language">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
            </span>
          </li>

          <li className="gx-language gx-d-flex gx-my-auto">
            <UserInfo />
          </li>
        </ul>
      </div>
    </Header>
  );
};

export default Topbar;
