import { useEffect, useMemo } from 'react';

import { Button, Card, Col, Radio, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useForm, useFieldArray, useFormState } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useIntl } from 'react-intl';
import { CustomUpload, Form, Input, RemoveButton } from 'components';

const { Option } = Select;
const { TextArea } = Input;

export default ({ offer, sendAuthors, fileList, setFileList, authors, loading, editable, versionUrl, setActiveTab }) => {
  const { formatMessage } = useIntl();
  const { control, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {
      contentDescription: offer.contentDescription,
      authors: offer.authors?.map((item) => ({
        author: item.author['@id'],
        reason: item.reason,
      })),
    },
  });
  const { fields, append, remove, replace } = useFieldArray({
    name: 'authors',
    control,
  });
  const { errors } = useFormState({ control });

  const authorSelectList = useMemo(() => {
    if (!offer && !authors) return;

    return authors['hydra:member'].reduce(
      (acc, author) => {
        if (author?.user?.memberships.length > 0) {
          acc.committee.push(author);
        } else {
          acc.authors.push(author);
        }
        return acc;
      },
      { committee: [], authors: [] }
    );
  }, [offer, authors]);

  useEffect(() => {
    const authorList = offer?.authors?.map((item) => {
      const { author, reason } = item;
      return {
        id: item['@id'],
        isCommitteeMember: author?.user?.memberships?.length > 0,
        authorSelection: 'list',
        author: author['@id'],
        reason,
      };
    });
    replace(authorList);
  }, [offer, replace]);

  useEffect(() => {
    if (offer.authors.length > 0) {
      offer.authors.map((item, idx) => {
        setValue(`authors.${idx}.author`, item.author.user.fullName);
      });
    }
  }, [setValue, offer]);

  return (
    <Form
      form={{ getValues, setValue }}
      onFinish={handleSubmit(sendAuthors)}
      layout="vertical"
    >
      {fields.map((item, idx) => (
        <Card
          className="gx-mb-3"
          key={item.id}
          loading={loading}
        >
          <Row
            justify="space-between"
            className="gx-flex-row"
          >
            <Col>
              <Form.Item
                label={formatMessage({
                  id: 'article.process.content.isAuthorMember',
                })}
                className="gx-mb-2"
                required
              >
                <Form.Controller
                  name={`authors.${idx}.isCommitteeMember`}
                  control={control}
                  rules={{
                    required: formatMessage(
                      { id: 'appModule.requiredFieldMessage' },
                      {
                        field: formatMessage({
                          id: 'article.process.content.isAuthorMember',
                        }),
                      }
                    ),
                  }}
                  render={({ field }) => (
                    <>
                      <Radio.Group
                        {...field}
                        hasValue={watch(`authors.${idx}.isCommitteeMember`)}
                        buttonStyle="solid"
                        onChange={({ target }) => {
                          const authors = getValues(`authors`);
                          const newValues = {
                            isCommitteeMember: target.value,
                            authorSelection: 'list',
                            author: null,
                          };
                          authors[idx] = { ...authors[idx], ...newValues };
                          replace(authors);
                        }}
                        disabled={!editable}
                      >
                        <Radio.Button value="yes">{formatMessage({ id: 'appModule.yes' })}</Radio.Button>
                        <Radio.Button value="no">{formatMessage({ id: 'appModule.no' })}</Radio.Button>
                      </Radio.Group>

                      <ErrorMessage
                        errors={errors}
                        name={`authors.${idx}.isCommitteeMember`}
                        as="p"
                        className="error-message"
                      />
                    </>
                  )}
                />
              </Form.Item>
            </Col>
            <Col>
              <RemoveButton
                disabled={!editable}
                onClick={() => remove(idx)}
              />
            </Col>
          </Row>

          {getValues(`authors.${idx}.isCommitteeMember`) === 'no' && (
            <Form.Item
              label={formatMessage({ id: 'author' })}
              className="gx-mb-2"
              required
            >
              <Form.Controller
                name={`authors.${idx}.authorSelection`}
                control={control}
                rules={{
                  required: formatMessage({ id: 'appModule.requiredFieldMessage' }, { field: formatMessage({ id: 'author' }) }),
                }}
                render={({ field }) => (
                  <>
                    <Radio.Group
                      {...field}
                      hasValue={watch(`authors.${idx}.authorSelection`)}
                      buttonStyle="solid"
                      disabled={!editable}
                    >
                      <Radio.Button value="list">{formatMessage({ id: 'appModule.pickFromList' })}</Radio.Button>
                      <Radio.Button value="new">{formatMessage({ id: 'appModule.addNew' })}</Radio.Button>
                    </Radio.Group>

                    <ErrorMessage
                      errors={errors}
                      name={`authors.${idx}.authorSelection`}
                      as="p"
                      className="error-message"
                    />
                  </>
                )}
              />
            </Form.Item>
          )}

          {getValues(`authors.${idx}.isCommitteeMember`) !== null &&
            (getValues(`authors.${idx}.authorSelection`) !== 'new' ? (
              <>
                <Form.Item
                  label={formatMessage({
                    id: 'article.process.content.selectAuthor',
                  })}
                  className="gx-mb-2"
                  required
                >
                  <Form.Controller
                    name={`authors.${idx}.author`}
                    control={control}
                    rules={{
                      required: formatMessage(
                        { id: 'appModule.requiredFieldMessage' },
                        {
                          field: formatMessage({
                            id: 'article.process.content.selectAuthor',
                          }),
                        }
                      ),
                    }}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          hasValue={watch(`authors.${idx}.author`)}
                          showSearch
                          filterOption={(input, option) => {
                            return option.children.join('').toLowerCase().includes(input.toLowerCase());
                          }}
                          getPopupContainer={(triggerNode) => triggerNode.parentElement}
                          disabled={!editable}
                        >
                          {authorSelectList?.[getValues(`authors.${idx}.isCommitteeMember`) === 'yes' ? 'committee' : 'authors'].map((author) => (
                            <Option
                              key={author['@id']}
                              value={author['@id']}
                            >
                              {author?.user?.firstName} {author?.user?.lastName} ({author?.user?.email})
                            </Option>
                          ))}
                        </Select>

                        <ErrorMessage
                          errors={errors}
                          name={`authors.${idx}.author`}
                          as="p"
                          className="error-message"
                        />
                      </>
                    )}
                  />
                </Form.Item>

                <Form.Item
                  label={formatMessage({
                    id: 'article.process.content.authorReason',
                  })}
                  className="gx-mb-3"
                  required
                >
                  <Form.Controller
                    name={`authors.${idx}.reason`}
                    control={control}
                    rules={{
                      required: formatMessage(
                        { id: 'appModule.requiredFieldMessage' },
                        {
                          field: formatMessage({
                            id: 'article.process.content.authorReason',
                          }),
                        }
                      ),
                      minLength: {
                        value: 100,
                        message: formatMessage({
                          id: 'article.reasonLengthMessage',
                        }),
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <TextArea
                          {...field}
                          hasValue={watch(`authors.${idx}.reason`)}
                          rows={4}
                          disabled={!editable}
                          showCount
                        />

                        <ErrorMessage
                          errors={errors}
                          name={`authors.${idx}.reason`}
                          as="p"
                          className="error-message"
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </>
            ) : (
              <p className="gx-my-2">
                Eklemek istediğiniz yazar adayı sistemde tanımlı değilse{' '}
                <a
                  href="/yazar-islemleri/yazar-ekle"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  buraya tıklayarak
                </a>{' '}
                sisteme tanımlayabilir ve sonrasında bu sayfaya dönerek seçiminizi yapabilirsiniz.
              </p>
            ))}
        </Card>
      ))}

      <Card className="gx-mb-3">
        <Button
          type="dashed"
          onClick={() => {
            append({
              isCommitteeMember: null,
              authorSelection: 'list',
              author: null,
              reason: null,
            });
          }}
          icon={<PlusOutlined />}
          className="gx-m-0"
          disabled={!editable}
          block
        >
          {' '}
          {formatMessage({ id: 'author.addAuthor' })}
        </Button>
      </Card>

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({
            id: 'article.process.author.contentDescription',
          })}
        >
          <Form.Controller
            name="contentDescription"
            control={control}
            rules={[
              {
                min: 100,
                message: formatMessage({ id: 'article.reasonLengthMessage' }),
              },
            ]}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={5}
                showCount
                disabled={!editable}
              />
            )}
          />
        </Form.Item>

        <p className="gx-mt-3 gx-mb-2">{formatMessage({ id: 'article.detail.media' })}</p>

        <CustomUpload
          fileList={fileList}
          setFileList={setFileList}
          disabled={!editable}
        />
      </Card>

      {editable && (
        <div className="gx-d-flex gx-justify-content-end gx-mt-4 gx-mb-3">
          {versionUrl === 'current' && (
            <Button
              disabled={loading}
              onClick={() => setActiveTab('1')}
            >
              {formatMessage({ id: 'goBack' })}
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={getValues('authors').length === 0}
          >
            {formatMessage({ id: 'appModule.saveAndContinue' })}
          </Button>
        </div>
      )}
    </Form>
  );
};
