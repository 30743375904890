import { createContext, useContext, useEffect } from 'react';

import { useKeyboard } from 'hooks';
import { Controller as RHFController } from 'react-hook-form';

import { useFormContext } from './KeyboardForm';

const FormControllerContext = createContext();

export const FormController = ({ name, hasValue, ...props }) => {
  const form = useFormContext();
  const { inputName, value, setValue } = useKeyboard();

  useEffect(() => {
    if (name !== inputName || value === hasValue) return;

    const inputValue = hasValue || '';
    const keyboardValue = value || '';

    const fromKeyboard = keyboardValue.length > inputValue.length;

    if (fromKeyboard) {
      if (form.setValue) {
        form.setValue(name, value);
      }
    } else {
      setValue(hasValue);
    }
  }, [inputName, value, hasValue, setValue, form, name]);

  return (
    <FormControllerContext.Provider value={name}>
      <RHFController
        name={name}
        hasValue={hasValue}
        {...props}
      />
    </FormControllerContext.Provider>
  );
};

export const useFormControllerContext = () => useContext(FormControllerContext);
