import React from 'react';

import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';

import TimeCardGrid from './TimeCardGrid';

const ignoredFields = ['opinionDate', 'articleOffer', 'requested'];

const TimeCard = ({ metadata, type, user, idx }) => {
  if (metadata.answers) {
    const answers = metadata.answers
      .filter((item) => item.answer[1])
      .map((item) => [item.input, item.answer]);

    metadata = {
      ...metadata,
      ...Object.fromEntries(answers),
    };

    delete metadata.answers;
  }

  ignoredFields.forEach((field) => {
    delete metadata[field];
  });

  // geri çekme hareketinde status görüntülenmemeli
  if (metadata.requester) {
    delete metadata.status;
  }

  if (type === 'entity_remove' && metadata.committee) {
    metadata.committee = [metadata.committee, null];
  }

  const metaDataKeys = Object.keys(metadata);
  const metaDataLength = metaDataKeys.length;

  const shouldBorderTitle = metaDataKeys.includes('committeeStatusCommittee')
    ? metaDataLength - 1 !== 1
    : metaDataLength !== 1;

  return (
    <Card
      bodyStyle={{
        padding: '0',
      }}
      bordered={type !== 'entity_add' || shouldBorderTitle}
      title={
        (type !== 'entity_add' || shouldBorderTitle) && (
          <span className="gx-mx-2 gx-font-weight-semi-bold">
            <FormattedMessage id="article.detail.timeLine.transactionsMade" />
          </span>
        )
      }
    >
      {metaDataKeys
        ?.filter((title) => Array.isArray(metadata[title]))
        ?.map(
          (title, idx) =>
            !['isOffer', 'committeeStatusCommittee'].includes(title) &&
            metadata[title] && (
              <TimeCardGrid
                key={title + idx}
                {...{
                  title,
                  type,
                  idx,
                  metadata,
                }}
              />
            )
        )}

      <TimeCardGrid idx={idx} user={user} />
    </Card>
  );
};
export default TimeCard;
