import academicTitleApi from './academicTitle';
import activityApi from './activities';
import articleGroupsApi from './articleGroupsApi';
import authorArticleApi from './authorArticle';
import authorTaskApi from './authorTask';
import authorWorksApi from './authorWorksApi';
import committeeRevertReasonApi from './commiteeRevertReason';
import committeeOpinionsApi from './committeeOpinionsApi';
import orderRevertReasonApi from './orderRevertReason';
import projectApi from './projectApi';
import roleApi from './roleApi';
import stiplatedTimeApi from './stipulatedTime';
import versionApi from './versionApi';

export { default as apiInstance } from './api';

export const { useGetAuthorArticlesQuery } = authorArticleApi;
export const { useGetActivitiesQuery } = activityApi;
export const { useGetAcademicTitlesQuery } = academicTitleApi;
export const { useGetAuthorTasksQuery } = authorTaskApi;
export const { useGetRolesQuery } = roleApi;
export const { useGetStiplatedTimeQuery } = stiplatedTimeApi;
export const { useGetAllProjectQuery } = projectApi;
export const { useGetAllOrderRevertReasonQuery } = orderRevertReasonApi;
export const { useGetAuthorWorksQuery } = authorWorksApi;
export const { useGetCommitteeOpinionsQuery } = committeeOpinionsApi;
export const { useGetVersionQuery } = versionApi;
export const { useGetCommitteeRevertReasonsQuery } = committeeRevertReasonApi;
export const { useGetArticleGroupsQuery } = articleGroupsApi;
