import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

export const usePushFilteredUrl = (urlFilters, subMenus) => {
  const navigate = useNavigate();
  const [searchURL, setSearchURL] = useState('');
  const [nextPage, setNextPage] = useState('');

  const { page, title, filterModalURL, filterStatusURL, pageSize } = urlFilters;

  const makeURL = useCallback(() => {
    let search = '';
    let next = '';

    search = search + `&page=${page}&pageSize=${pageSize}`;
    next = next + `&page=${page + 1}&pageSize=${pageSize}`;

    if (title !== '') {
      search = search + `&title=${title}`;
      next = next + `&title=${title}`;
    }
    if (filterModalURL.length) {
      search = search + filterModalURL;
      next = next + filterModalURL;
    }
    if (filterStatusURL.length) {
      search = search + filterStatusURL;
      next = next + filterStatusURL;
    }
    setSearchURL(search);
    setNextPage(next);
    navigate({
      pathname: subMenus || 'tum-maddeler',
      search,
    });
  }, [
    page,
    title,
    filterModalURL,
    filterStatusURL,
    subMenus,
    pageSize,
    navigate,
  ]);

  useEffect(() => {
    makeURL();
  }, [makeURL, urlFilters, searchURL, page, nextPage]);

  return { newRoute: searchURL, nextPage };
};
