import { useEffect, useState } from 'react';

import { Button, Col, Divider, Row, Tabs } from 'antd';
import { ArrowLeftOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useOffers } from 'useSWR';
import { CircularProgress, Title } from 'components';
import { ArticleProcess, ArticleOfferDetail, ArticleContentDetail, Timeline } from 'components/ArticleOffer';
import { ArticleOrderDetail } from 'components/ArticleOrder';
import { useUserInfo } from 'providers/UserInfoProvider';

const { TabPane } = Tabs;

const ArticleDetail = () => {
  const { id } = useParams();
  const { offer, isLoading, fetching, offerMutate } = useOffers(id);
  const { isAdmin } = useUserInfo();

  const [timelineVisible, setTimelineVisible] = useState(false);
  const [process, setProcess] = useState('1');

  useEffect(() => {
    const step = Number.parseInt(offer?.step);
    if (Number.isNaN(step) || step <= 0) {
      setProcess('1');
    } else if (step === 4) {
      setProcess('3');
    } else {
      setProcess(`${step}`);
    }
  }, [offer?.step]);

  if (!offer || isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Title>{`${offer.title + ' - Detay'}`}</Title>

      <Row
        justify="space-between"
        gutter={3}
      >
        <Col>
          <h1 className="gx-h1-lg">{offer?.title}</h1>
        </Col>

        <Col>
          <Button
            ghost
            style={{ color: '#BF6800' }}
            disabled={!offer?.isView}
            onClick={() => setTimelineVisible(!timelineVisible)}
          >
            {timelineVisible ? (
              <>
                <FormattedMessage id="goBack" /> <ArrowLeftOutlined />
              </>
            ) : (
              <>
                <FormattedMessage id="article.timeline" /> <FieldTimeOutlined />
              </>
            )}
          </Button>
        </Col>
      </Row>

      <Divider className="gx-mt-0 gx-mb-4" />

      {timelineVisible ? (
        <Timeline id={offer.id} />
      ) : (
        <>
          {isAdmin && (
            <Row
              justify="center"
              gutter={3}
            >
              <Col span={18}>
                <ArticleProcess
                  offer={offer}
                  process={process}
                  setProcess={setProcess}
                />
              </Col>
            </Row>
          )}

          <Tabs
            animated
            centered
            type="card"
            activeKey={process.toString()}
            tabBarStyle={{ display: 'none' }}
            destroyInactiveTabPane
          >
            <TabPane key="1">
              <ArticleOfferDetail
                offer={offer}
                process={process}
              />
            </TabPane>

            <TabPane key="2">
              <ArticleContentDetail
                offer={offer}
                process={process}
                fetching={fetching}
                offerMutate={offerMutate}
              />
            </TabPane>
            <TabPane key="3">
              <ArticleOrderDetail
                offer={offer}
                process={process}
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};

export default ArticleDetail;
