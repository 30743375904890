import apiInstance from './api';

const authorTaskApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorTasks: builder.query({
      query: () => ({
        url: '/tasks',
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AuthorTasks'],
    }),
  }),
  overrideExisting: true,
});

export default authorTaskApi;
