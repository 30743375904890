export const UpsIcon = ({ className }) => {
  return (
    <svg
      width="27"
      height="32"
      viewBox="0 0 27 32"
      fill="none"
      className={`anticon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.10441 19.3367C6.73201 19.5945 4.7697 20.2748 4.7697 17.9759V10.5278H2.42782V17.8757C2.42782 23.2398 8.17151 21.5353 9.43913 20.6687V10.5278H7.10441V19.3367ZM0 2.99508V18.8153C0 26.242 5.36412 28.4979 13.4425 32C21.4923 28.4979 26.8851 26.2563 26.8851 18.8153V2.99508C18.556 -1.41654 6.70336 -0.55713 0 2.99508ZM25.6461 18.8153C25.6461 25.0173 21.8361 26.951 13.4425 30.6536C5.02752 26.9438 1.23181 25.0173 1.23181 18.8153V9.76289C8.55824 3.04521 17.8183 2.60118 25.6461 3.33168V18.8153ZM10.6351 11.1236V26.3637H12.977V21.4436C14.7245 21.9664 18.112 21.2574 18.112 15.8217C18.112 8.84619 12.3325 10.0264 10.6351 11.1236ZM12.977 19.5243V12.3411C13.5786 12.0403 15.7271 11.4316 15.7271 15.8718C15.7271 20.7346 13.1203 19.5816 12.977 19.5243ZM18.6419 13.3366C18.6491 16.7241 22.3374 16.3803 22.3803 18.3784C22.4233 20.0614 20.2032 20.0256 18.7422 18.7294V20.885C21.3347 22.4248 24.6076 21.4651 24.7007 18.4859C24.8225 14.7976 20.8263 15.1485 20.8764 13.2435C20.9194 11.7897 23.0679 11.7753 24.3498 13.0859V11.052C22.3087 9.47642 18.6276 10.3931 18.6419 13.3366Z"
        fill="currentColor"
      />
    </svg>
  );
};
