import { useState, useCallback, useEffect } from 'react';

import useSWR from 'swr';
import { Button, notification } from 'antd';
import { useLocation } from 'react-router-dom';

const refreshPaths = ['/', '/giris', '/anasayfa'];

const useHash = () => {
  const { pathname } = useLocation();

  const hashUrl = process.env.NODE_ENV !== 'development' ? '/hash' : null;

  const { data } = useSWR(hashUrl, {
    revalidateOnFocus: true,
    refreshInterval: 30 * 1000,
  });
  const [hashInitialized, setHashInitialized] = useState(false);

  const openNotification = () => {
    const btn = (
      <Button type="primary" onClick={() => location.reload(true)}>
        Sayfayı Yenile
      </Button>
    );

    notification.warning({
      message: 'Yeni Versiyon Mevcut',
      description:
        'Yeni bir versiyon yayınlanmıştır. Sistemi kullanmak için alttaki button ile sayfayı yenileyin.',
      btn,
      duration: 30 * 1000,
      closeIcon: <span> </span>,
    });
  };

  const checkHash = useCallback(() => {
    if (data) {
      if (!hashInitialized) {
        localStorage.setItem('hashCode', data);
        setHashInitialized(true);
      } else {
        const { hashCode } = localStorage;

        if (hashCode !== data) {
          if (refreshPaths.includes(pathname)) {
            localStorage.setItem('hashCode', data);
            location.reload(true);
          } else {
            openNotification();
          }
        }
      }
    }
  }, [data, hashInitialized, pathname]);

  useEffect(() => {
    checkHash();
  }, [checkHash]);
};

export default useHash;
