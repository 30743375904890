import React, { useEffect, useState } from 'react';

import { Button, Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useArticleTypes, useGuides } from 'useSWR';
import { chunkUploadMedia, openNotification, showErrorMessage } from 'utils';
import { CircularProgress, CustomUpload } from 'components';

export const MediaUploader = ({ mediaType }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { articleTypes, isLoading } = useArticleTypes();
  const { guides, isLoading: guidesLoading } = useGuides();

  useEffect(() => {
    if (mediaType) {
      form.setFieldsValue({
        type: mediaType?.key,
      });
    }
  }, [form, mediaType]);

  const getGuide = (type) => {
    const guide = guides?.find((g) => g.articleType.id === type?.id);
    return `${type.name} ${guide?.media ? `- ${guide?.media?.name}` : ''}`;
  };

  async function upload(values) {
    setUploading(true);

    const { articleType } = values;

    const data = {
      ...(articleType && { articleType }),
    };

    try {
      const fileSizeSum = fileList.reduce((acc, file) => acc + file.size, 0);

      // throw if sum of file sizes are larger than 25 MB
      if (fileSizeSum > 25 * 1024 * 1024) {
        throw formatMessage({
          id: 'appModule.fileSizeExceeded',
        });
      }

      const uploaded = await chunkUploadMedia(mediaType.key, fileList);
      const { handler } = mediaType;

      if (handler) {
        for (const file of uploaded) {
          await Promise.resolve(
            handler({
              media: `/api/media/${file.media.id}`,
              ...data,
            })
          );
        }
      }

      setFileList([]);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    } catch (error) {
      showErrorMessage(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` }));
    }

    setUploading(false);
  }

  if (isLoading || guidesLoading) return <CircularProgress />;

  return (
    <Form
      form={form}
      layout="vertical"
      className="gx-mb-3"
      onFinish={upload}
      initialValues={{
        type: mediaType?.key,
      }}
    >
      <Form.Item
        className="gx-mb-3"
        shouldUpdate
      >
        {({ getFieldValue }) =>
          getFieldValue('type') === 'guide' && (
            <Form.Item
              label={<FormattedMessage id="article.detail.articleType" />}
              name="articleType"
            >
              <Select placeholder={<FormattedMessage id="article.detail.articleType" />}>
                {articleTypes.map((type) => (
                  <Select.Option
                    key={type.id}
                    value={type?.['@id']}
                    disabled={!!guides?.find((g) => g.articleType.id === type?.id)}
                  >
                    {getGuide(type)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )
        }
      </Form.Item>

      <CustomUpload
        fileList={fileList}
        setFileList={setFileList}
        disabled={uploading}
      />

      <div className="gx-d-flex gx-justify-content-end">
        <Button
          className="gx-mt-3"
          onClick={() => setFileList([])}
          disabled={fileList.length === 0 || uploading}
        >
          <FormattedMessage id="article.filter.clear" />
        </Button>

        <Button
          className="gx-mt-3"
          type="primary"
          htmlType="submit"
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          <FormattedMessage id="appModule.upload" />
        </Button>
      </div>
    </Form>
  );
};
