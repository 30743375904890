import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';

import { AdditionalFiles } from './addendum';
import { GuideFiles } from './addendum/GuideFiles';

export const OrderDetailAddendum = ({ offer }) => (
  <Card className="gx-p-3">
    {offer?.contentDescription && (
      <>
        <h2 className="gx-mb-4">
          <FormattedMessage id="article.process.author.contentDescription" />
        </h2>

        <Card className="gx-p-2"> {offer?.contentDescription} </Card>
      </>
    )}

    <AdditionalFiles offer={offer} />
    <GuideFiles offer={offer} />
  </Card>
);
