import { Card } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { useUserInfo } from 'providers/UserInfoProvider';

import { ArticleOrderAccepted, ArticleOrderRejected } from './status';
import { ArticleOrderExpired } from './status/ArticleOrderExpired';

export const ArticleOrderStatus = ({ offer }) => {
  const { ouProcessTracks } = offer;
  const [lastTrack] = ouProcessTracks.slice(0);

  const { isAdmin, isAuthor, isOrderUnit } = useUserInfo();

  const content = {
    pending: {
      icon: <ClockCircleOutlined />,
      key: 'article.order.pendingAuthorApproval',
      component: null,
    },
    approved: {
      icon: <CheckCircleOutlined />,
      key: 'article.order.authorAccepted',
      component: <ArticleOrderAccepted offer={offer} />,
    },
    rejected: {
      icon: <CloseCircleOutlined />,
      key: 'article.order.authorRejected',
      component: <ArticleOrderRejected offer={offer} />,
    },
    notAnswered: {
      icon: <ClockCircleOutlined />,
      key: 'article.order.deadlinePassed',
      component: <ArticleOrderExpired offer={offer} />,
    },
  };

  return (
    <Card className="gx-px-2">
      <div className="gx-d-flex gx-align-items-center gx-mb-3">
        <span className="gx-mr-3 gx-fs-xxl">{content[lastTrack?.opinion]?.icon}</span>

        {(isAdmin || isOrderUnit) && (
          <span className="gx-fs-xl">
            <FormattedMessage id={`${content[lastTrack?.opinion]?.key}.title`} />
          </span>
        )}

        {isAuthor && (
          <span className="gx-fs-xl">
            <FormattedMessage id={`${content[lastTrack?.opinion]?.key}.authorTitle`} />
          </span>
        )}
      </div>

      {lastTrack?.opinion !== 'notAnswered' && (
        <FormattedHTMLMessage
          id={`${content[lastTrack?.opinion]?.key}.${isAuthor ? 'authorDescription' : 'description'}`}
          values={{
            date: dayjs(lastTrack.finishDate).format('DD/MM/YYYY'),
            diff: dayjs(lastTrack.finishDate).diff(dayjs(), 'days'),
          }}
        />
      )}

      {content[lastTrack?.opinion]?.component}
    </Card>
  );
};
