import React, { useState } from 'react';

import { Button, Select, Row, Col, Card } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { adminUserAdd } from 'fetcher';
import { openNotification, regexPass, ROLES_DEF } from 'utils';
import { Title, Form, Input } from 'components';
import { fetchError } from 'slices/commonSlice';

const FormItem = Form.Item;

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await adminUserAdd(values);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        navigate('/uye-islemleri/uye-listesi');
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRoles = (selectedItems) => {
    setSelectedRoles(selectedItems);
  };

  return (
    <>
      <Title>{formatMessage({ id: 'userAdd.Title' })}</Title>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={14}
          xxl={12}
        >
          <h1 className="gx-h1-lg">
            {' '}
            <FormattedMessage id="userAdd.Title" />
          </h1>
          <Link to="/uye-islemleri/uye-listesi">
            <Button
              style={{ float: 'right' }}
              icon={<UserOutlined />}
            >
              <FormattedMessage id="userAdd.backUserList" />
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={14}
          xxl={12}
        >
          <Card>
            <Form
              form={form}
              initialValues={{ remember: false }}
              name="basic"
              onFinish={onFinish}
              layout="vertical"
            >
              <FormItem
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="userAdd.nameError" />,
                  },
                ]}
                name="firstName"
                label={<FormattedMessage id="appModule.name" />}
                className="gx-mb-2"
              >
                <Input />
              </FormItem>
              <FormItem
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="userAdd.surnameError" />,
                  },
                ]}
                name="lastName"
                label={<FormattedMessage id="appModule.surname" />}
                className="gx-mb-2"
              >
                <Input />
              </FormItem>
              <FormItem
                name="email"
                label={<FormattedMessage id="appModule.email" />}
                className="gx-mb-2"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: <FormattedMessage id="app.userAuth.EmailErrorCheck" />,
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                label="Parola"
                name="plainPassword"
                className="gx-mb-2"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="app.userAuth.PassError" />,
                  },
                  {
                    pattern: regexPass,
                    message: <FormattedMessage id="app.userAuth.PassResetInfoMessageType" />,
                  },
                  {
                    min: 8,
                    message: <FormattedMessage id="app.userAuth.PassResetInfoMessageCharacter" />,
                  },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem
                name="roles"
                label={<FormattedMessage id="userAdd.userRole" />}
                className="gx-mb-2"
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  mode="multiple"
                  placeholder={<FormattedMessage id="userAdd.userRole" />}
                  defaultValue={selectedRoles}
                  onChange={handleRoles}
                  style={{ width: '100%' }}
                  showSearch={false}
                  notFoundContent={
                    <div className="gx-text-center gx-h1-lg gx-my-2">
                      <FormattedMessage id="userAdd.userRoleNotFound" />{' '}
                    </div>
                  }
                >
                  {Object.entries(ROLES_DEF).map(([value, text]) => (
                    <Select.Option
                      key={value}
                      value={value}
                    >
                      {text}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  className="gx-mb-0 gx-mt-4"
                  htmlType="submit"
                  loading={loading}
                >
                  <FormattedMessage id="userAdd.userAdd" />
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SignUp;
