import { Input as AntInput } from 'antd';
import { useKeyboard } from 'hooks';

import { useFormContext } from './KeyboardForm';
import { useFormItemContext } from './KeyboardFormItem';
import { useFormListContext } from './KeyboardFormList';
import { useFormControllerContext } from './KeyboardFormController';

export const TextArea = ({ onFocus, ...props }) => {
  const form = useFormContext();
  const itemName = useFormItemContext();
  const listName = useFormListContext();
  const controlName = useFormControllerContext();

  const { setInputName, setValue } = useKeyboard();

  const onFocusChange = (event) => {
    if (form.getFieldValue) {
      const inputName = listName ? [listName, ...itemName].join('.') : itemName;

      setInputName(inputName);
      setValue(form.getFieldValue(inputName));
    } else if (form.getValues) {
      setInputName(controlName);
      setValue(form.getValues(controlName));
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <AntInput.TextArea
      onFocus={onFocusChange}
      {...props}
    />
  );
};
