import { useState } from 'react';

import { Card, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ArticleInterviewNote } from 'components/ArticleOrder/ArticleInterviewNote/ArticleInterviewNote';
import { useUserInfo } from 'providers/UserInfoProvider';

import { OrderDetailGrid } from './OrderDetailGrid';
import { OrderDetailDescriptions } from './OrderDetailDescriptions';
import { OrderDetailAddendum } from './OrderDetailAddendum';
import { OrderAgreement } from './OrderAgreement';
import { ArticleOrderStatus } from './ArticleOrderStatus';
import { StartOrderProcess, AuthorDecision } from './status';
import OrderInterviewHistory from './OrderInterviewHistory';

export const ArticleOrderDetail = ({ offer }) => {
  const { isAdmin, isAuthor, isOrderUnit } = useUserInfo();
  const [noteModalVisible, setNoteModalVisible] = useState(false);

  return (
    <>
      {(isAuthor || isOrderUnit) && [21, 22].includes(offer?.status) && <ArticleOrderStatus offer={offer} />}

      {offer?.status === 22 && (
        <Card className="gx-p-3">
          <OrderInterviewHistory offer={offer} />
        </Card>
      )}

      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.steps.ArticleOffer" />
      </h2>

      <Card className="gx-p-3">
        <OrderDetailGrid offer={offer} />
        <OrderDetailDescriptions offer={offer} />
        <OrderDetailAddendum offer={offer} />
        <OrderAgreement offer={offer} />

        {offer?.status === 22 && !isAuthor && (
          <div className="modalBtnGrp">
            <Button className="modalBtnStyle">
              <FormattedMessage id="additional.time.btn" />
            </Button>
            <Button
              className="modalBtnStyle"
              onClick={() => setNoteModalVisible(true)}
            >
              <FormattedMessage id="interview.note.btn" />
            </Button>
          </div>
        )}

        {offer?.status === 22 && (
          <ArticleInterviewNote
            offer={offer}
            open={noteModalVisible}
            close={() => setNoteModalVisible(false)}
          />
        )}

        {(isAdmin || isOrderUnit) && offer?.status === 20 && <StartOrderProcess offer={offer} />}

        {isAuthor && offer?.status === 21 && <AuthorDecision offer={offer} />}
      </Card>
    </>
  );
};
