import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Table, Row, Col, Button, Modal, Tag } from 'antd';
import { CloudDownloadOutlined, FilterFilled } from '@ant-design/icons';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import scrollIntoView from 'scroll-into-view';
import { useAllOffersVersions, useCommittees } from 'useSWR';
import { useInitialValueMaker, usePushFilteredUrl, useWindowSize } from 'hooks';
import { exportTable, STEP_DEF } from 'utils';
import { CircularProgress, Input, Title } from 'components';
import { TableAlert, Order, FilterModal } from 'components/ArticlesTable';
import { ArticleOrderActions } from 'components/ArticleOrder';
import { useUserInfo } from 'providers/UserInfoProvider';
import { setOfferTable, setShouldScroll, setTableOrder } from 'slices/commonSlice';

const filterNames = {
  otherCommittees: 'Teklif Edilen İlim Heyeti',
  owner: 'Formu Dolduran',
  ownerCommittee: 'Sorumlu İlim Heyeti',
  dateRange: 'Tarih Aralığı',
  committeeContributions: 'Katkıda Bulunacak İlim Heyeti',
  status: 'Genel Durum',
};

const { Search } = Input;

const NextPage = ({ currentPageOptions, committeeId, orderType, nextPage }) => {
  const { pageSize } = currentPageOptions;
  const { offers } = useAllOffersVersions({
    committeeId,
    pageSize,
    orderType,
    filtered: nextPage,
  });

  return (
    <span
      className="gx-d-none"
      data-users={offers}
    />
  );
};

const Articles = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { formatMessage } = useIntl();
  const { width, height } = useWindowSize();
  const { id } = useParams();
  const { isAdmin, isOrderUnit } = useUserInfo();

  const shouldScroll = useSelector(({ common }) => common.offerShouldTableScroll);
  const tableOrder = useSelector(({ common }) => common.tableOrder);
  const scrollID = useSelector(({ common }) => common.offerID);

  const urlFilter = pathname.split('/');
  const isDraft = urlFilter.includes('taslak-maddeler');

  const stringId = id ?? urlFilter[2] !== 'tum-maddeler' ? urlFilter[2] : '';

  let subMenus,
    subTitle = '';

  if (urlFilter.includes('teklif-edilen-maddeler')) {
    subMenus = `&otherCommittees.id=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.offeredArticles' });
  }
  if (urlFilter.includes('sorumlu-olunan-maddeler')) {
    subMenus = `&committee=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.ownerArticles' });
  }
  if (urlFilter.includes('katki-verilecek-maddeler')) {
    subMenus = `&committeeContributions.committee=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.contributionArticles' });
  }
  if (urlFilter.includes('heyetten-cikan-maddeler')) {
    subMenus = `&extracted=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.removedFromCommitteeArticles' });
  }
  if (isDraft) {
    subMenus = `&committee=${stringId}&isDraft=true`;
    subTitle = formatMessage({ id: 'sidebar.draftArticles' });
  }

  const { page, pageSize, title, filterModal: filterModals, filterModalURL, filterStatusObjects, filterStatusURL } = useInitialValueMaker(search);

  const filtersRef = useRef();
  const debounce = useRef();

  const [urlFilters, setUrlFilters] = useState({
    page: page || 1,
    pageSize: pageSize || 20,
    title: title ?? '',
    filterModalURL: filterModalURL ?? '',
    filterModalObjects: filterModals ?? {},
    filterStatusURL: filterStatusURL ?? '',
    filterStatusObjects: filterStatusObjects ?? {},
    filterTags: [],
    filteredURL: search.replaceAll('?', ''),
  });

  const { newRoute, nextPage } = usePushFilteredUrl(urlFilters, pathname);

  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(false);
  const [order, setOrder] = useState({ type: tableOrder.type, order: tableOrder.order });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [ownerOptionsInitialized, setOwnerOptionsInitialized] = useState(false);

  const { committees, isLoading: titleLoading } = useCommittees(stringId, id);
  const {
    offers,
    isLoading,
    url: downloadURL,
    mutate,
  } = useAllOffersVersions({
    subMenus,
    committeeId: stringId,
    pageSize: urlFilters.pageSize,
    orderType: order,
    filtered: newRoute ?? urlFilters.filterModalURL + urlFilters.filterStatusURL,
  });

  const searchName = (text) => {
    setLoading(true);
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setUrlFilters((prevState) => ({ ...prevState, title: text, page: 1 }));
      setLoading(false);
    }, 300);
  };

  const clearFilters = () => {
    if (filtersRef.current) {
      filtersRef.current.clearFilters();
    } else {
      // Clear filter modal values
      localStorage.removeItem('filterModalValues');
      setUrlFilters((prevState) => ({
        ...prevState,
        page: 1,
        filterModalURL: '',
        filterStatusURL: '',
      }));
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="article.detail.FormNumber" />,
      dataIndex: isDraft ? 'formNumber' : 'targetOffer',
      width: 90,
      align: 'center',
      render: (a) => <span>{a?.formNumber ?? a}</span>,
      fixed: width > 575 ? 'left' : null,
    },
    {
      title: (
        <Order
          setOrder={setOrder}
          order={order}
          title={<FormattedMessage id="article.detail.articleTitle" />}
          type="title"
        />
      ),
      className: order.type === 'title' && order.order !== null ? 'active-order' : newRoute.includes('title') && 'active-filter-column',
      dataIndex: 'title',
      width: 240,
      render: (article, record) => <Link to={`/madde-detay/${isDraft ? record.id : record.targetOffer?.id}`}>{article}</Link>,
      fixed: width > 575 ? 'left' : null,
    },
    {
      title: <FormattedMessage id="article.filter.owner" />,
      dataIndex: isDraft ? 'owner' : 'targetOffer',
      width: 110,
      align: 'center',
      className: newRoute.includes('owner=') && 'active-filter-column',
      render: (a) => <span>{a?.owner?.fullName ?? a?.fullName}</span>,
    },
    {
      title: <FormattedMessage id="article.filter.offeredAuthor" />,
      dataIndex: 'author',
      width: 110,
      align: 'center',
      className: newRoute.includes('author=') && 'active-filter-column',
      hidden: !(isAdmin || isOrderUnit),
      render: (author) => (author ? author?.map((a) => <div key={a?.id}>{`${a?.name}`}</div>) : '-'),
    },
    {
      title: (
        <Order
          title={<FormattedMessage id="article.filter.date" />}
          type="createdAt"
          setOrder={setOrder}
          order={order}
        />
      ),
      dataIndex: 'createdAt',
      align: 'center',
      width: 140,
      className: order.type === 'createdAt' && order.order !== null ? 'active-order' : newRoute.includes('createdAt') && 'active-filter-column',
      render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY - HH:mm'),
    },
    // {
    //   title: <FormattedMessage id="article.filter.otherCommittees" />,
    //   dataIndex: 'otherCommittees',
    //   width: 130,
    //   className: newRoute.includes('otherCommittees') && 'active-filter-column',
    //   render: (otherCommittees) => (
    //     <span>
    //       {otherCommittees[0]?.name && otherCommittees[0]?.name + ' Heyeti'}
    //     </span>
    //   ),
    //   hidden: !['test', 'local'].includes(process.env.REACT_APP_SENTRY_ENV),
    // },
    {
      title: <FormattedMessage id="article.detail.ownerCommittee" />,
      dataIndex: 'ownerCommittee',
      align: 'center',
      width: 130,
      className: newRoute.includes('ownerCommittee') && 'active-filter-column',
      render: (ownerCommittee) => <span>{isDraft ? committees.name : ownerCommittee?.name && ownerCommittee?.name + ' Heyeti'}</span>,
    },
    {
      title: <FormattedMessage id="article.filter.committeeContribution" />,
      dataIndex: 'committeeContributions',
      align: 'center',
      width: 130,
      className: newRoute.includes('committeeContributions') && 'active-filter-column',
      render: (contributions) =>
        contributions?.length > 0 ? contributions?.map((committee) => <div key={committee?.committee?.id}>{`${committee?.committee?.name}`}</div>) : '-',
    },
    {
      title: <FormattedMessage id="article.filter.generalProcess" />,
      dataIndex: ['targetOffer', 'step'],
      align: 'center',
      width: 130,
      render: (step) => (
        <p className="gx-py-2 gx-m-0">
          <FormattedMessage id={step ? `article.${STEP_DEF[step]}.title` : `article.${STEP_DEF[1]}.title`} />
        </p>
      ),
    },
    {
      title: <FormattedMessage id="article.filter.generalSituation" />,
      dataIndex: isDraft ? 'status' : 'targetOffer',
      className: `${newRoute.includes('status') && 'active-filter-column'} article-table-general-situation`,
      align: 'center',
      width: 130,
      render: (a) => (
        <TableAlert
          committee={`ih-${isDraft ? a : a.committeeStatus}`}
          karar={isDraft ? a : a.status}
        />
      ),
      // render: (a) => <>{isDraft ? GENEL_DURUM[a] : GENEL_DURUM[a.status]}</>,
    },
    // {
    //   title: <FormattedMessage id="article.filter.IHSituation" />,
    //   dataIndex: isDraft ? 'committeeStatus' : 'targetOffer',
    //   className: newRoute.includes('committeeStatus') && 'active-filter-column',
    //   width: 135,
    //   align: 'center',
    //   render: (a) => (
    //     <TableAlert
    //       committee={`ih-${isDraft ? a : a.committeeStatus}`}
    //       karar={isDraft ? a : a.committeeStatus}
    //     />
    //   ),
    // },
    // {
    //   title: <FormattedMessage id="article.filter.DAHSituation" />,
    //   dataIndex: isDraft ? 'dahStatus' : 'targetOffer',
    //   className: newRoute.includes('dahStatus') && 'active-filter-column',
    //   width: 135,
    //   align: 'center',
    //   render: (a) => (
    //     <TableAlert
    //       committee={`dah-${isDraft ? a : a.dahStatus}`}
    //       karar={isDraft ? a : a.dahStatus}
    //     />
    //   ),
    // },
    // {
    //   title: <FormattedMessage id="article.filter.AYKSituation" />,
    //   dataIndex: isDraft ? 'aykStatus' : 'targetOffer',
    //   className: newRoute.includes('aykStatus') && 'active-filter-column',
    //   width: 135,
    //   align: 'center',
    //   render: (a) => (
    //     <TableAlert
    //       committee={`ayk-${isDraft ? a : a.aykStatus}`}
    //       karar={isDraft ? a : a.aykStatus}
    //     />
    //   ),
    // },
    {
      title: <FormattedMessage id="appModule.action" />,
      key: 'action',
      align: 'center',
      width: 50,
      hidden: !(isAdmin || isOrderUnit),
      render: (_, data) => (
        <ArticleOrderActions
          offer={data}
          mutateArticles={mutate}
        />
      ),
    },
  ].filter((item) => !item.hidden);

  const pagination = {
    defaultCurrent: urlFilters.page,
    current: page,
    defaultPageSize: urlFilters.pageSize || 20,
    total: offers?.['hydra:totalItems'] ?? 1,
    showTotal: (total) =>
      `${formatMessage(
        {
          id: 'table.totalItem',
        },
        { type: 'Madde' }
      )} ${total}`,
    position: ['bottomRight'],
    responsive: true,
    size: 'default',
    onChange: (page, pageSize) => {
      setUrlFilters((prevState) => ({ ...prevState, page, pageSize }));
    },
    pageSizeOptions: [10, 20, 50],
    showSizeChanger: true,
    onShowSizeChange: (current, size) => setUrlFilters((prevState) => ({ ...prevState, page: 1, pageSize: size })),
  };

  /**
   * filtrele kısmında "formu dolduran" input alanında çıkacak isim önerileri, her bir farklı isim
   * dizide sadece bir kere yer alacak şekilde filtreleniyor.
   */
  const prepareOwnerOptions = useCallback(() => {
    const options = [];
    offers?.['hydra:member']?.forEach((member) => {
      const fullName = isDraft ? member.owner?.fullName : member.targetOffer?.owner?.fullName;
      const isOptionsIncludes = options.some((e) => e.value === fullName);
      if (!isOptionsIncludes) {
        options.push({ value: fullName });
      }
    });
    setOwnerOptions(options);
  }, [offers, setOwnerOptions, isDraft]);

  const handleScroll = useCallback(() => {
    scrollIntoView(document.querySelector('.scroll-row'), {
      align: {
        top: 0,
        left: 0,
      },
    });
  }, []);

  const handleExportTable = async () => {
    setWait(true);
    const fileName = `${committees?.name ?? 'Tüm'} ${subTitle ? '- ' + subTitle : 'Teklifler'}`;
    let url = downloadURL;
    if (isDraft) {
      url = url.replaceAll('/article_offers?', '/article_offers/export?');
    } else {
      url = url.replaceAll('/article_offer_versions?', '/article_offer_versions/export?');
    }
    await exportTable(url, fileName);
    setWait(false);
  };

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(handleScroll, 500);
      dispatch(setShouldScroll(false));
    }
  }, [handleScroll, shouldScroll, dispatch]);

  useEffect(() => {
    if (offers && !ownerOptionsInitialized) {
      prepareOwnerOptions();
      setOwnerOptionsInitialized(true);
    }
  }, [offers, prepareOwnerOptions, ownerOptionsInitialized]);

  useEffect(() => {
    dispatch(setTableOrder(order));
  }, [order, dispatch]);

  if (titleLoading) <CircularProgress />;

  return (
    <Row
      align="center"
      justify="center"
    >
      <Title>{`${committees?.name ?? 'Tüm'} ${subTitle ? '- ' + subTitle : 'Teklifler'}`}</Title>

      <Col span={24}>
        <div className="offers-table-columns">
          <div className="gx-d-flex gx-justify-content-between gx-aling-items-center">
            <h1 className="gx-h1-lg">
              <FormattedMessage
                id="article.table.title"
                values={{
                  committee: stringId ? `- ${committees?.name}` : '',
                  // bu alanda subtitle dolu ise ve id 18 ve 19 değilse 62. satırda bulunan subtitle lar + Madde Teklifleri yazması sağlandı. Bu sayede Taslaklar - Arap Dili Edebiyatı gibi başlıklar yazılabiliyor.
                  subtitle: subTitle !== '' ? subTitle : ![18, 19].includes(+stringId) ? 'Madde Teklifleri - Tam Liste' : 'Madde Teklifleri ',
                }}
              />
            </h1>

            <Button
              loading={wait}
              disabled={!(offers?.['hydra:member']?.length > 0 || offers?.length > 0)}
              onClick={handleExportTable}
              icon={<CloudDownloadOutlined />}
            >
              <FormattedMessage id="app.export" />
            </Button>
          </div>

          <Row gutter={6}>
            <Col
              xs={18}
              md={11}
            >
              <Search
                name="offerName"
                onSearch={searchName}
                defaultValue={title}
                placeholder={formatMessage({ id: 'article.table.search' })}
                allowClear
                enterButton
              />
            </Col>

            <Col span={6}>
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                icon={<FilterFilled />}
                className="gx-mr-0"
              >
                <FormattedMessage id="article.filter" />
              </Button>

              {(urlFilters.filterModalURL || urlFilters.filterStatusURL) && (
                <Button
                  type="link"
                  className=""
                  onClick={clearFilters}
                >
                  Temizle
                </Button>
              )}
            </Col>

            <Modal
              title={<FormattedMessage id="article.filter" />}
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
              width={900}
              footer={null}
            >
              <FilterModal
                isLoading={isLoading}
                handleCancel={() => setIsModalVisible(false)}
                tableType={subMenus}
                setUrlFilters={setUrlFilters}
                filterModals={filterModals}
                ownerOptions={ownerOptions}
              />
            </Modal>

            <div
              style={{
                bottom: offers?.['hydra:member']?.length > 0 || offers?.length > 0 ? -25 : -20,
              }}
              className="gx-position-absolute "
            >
              {urlFilters?.filterTags?.map(
                (item) =>
                  item.isTouched && (
                    <Tag
                      onClick={() => setIsModalVisible(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {filterNames[item.value]}{' '}
                    </Tag>
                  )
              )}
            </div>
          </Row>

          <Table
            bordered
            columns={columns}
            pagination={pagination}
            dataSource={offers?.['hydra:member'] || offers}
            rowKey={(record) => (isDraft ? record.id : record.targetOffer?.id)}
            rowClassName={(record) => {
              const recID = isDraft ? record.id : record.targetOffer?.id;
              return recID === scrollID && 'scroll-row';
            }}
            onRow={(record) => ({
              onClick: () =>
                dispatch(
                  setOfferTable({
                    offerID: isDraft ? record.id : record.targetOffer?.id,
                    offerTableURL: pathname,
                    offerTablePage: urlFilters.page,
                    offerTablePageSize: urlFilters.pageSize,
                    filterModalURL: urlFilters.filterModalURL,
                  })
                ),
            })}
            loading={loading || isLoading}
            scroll={{ x: 990, y: height - 400 }}
          />
        </div>
      </Col>

      <NextPage
        currentPageOptions={urlFilters}
        nextPage={nextPage}
        committeeId={stringId}
        orderType={order}
      />
    </Row>
  );
};

export default Articles;
