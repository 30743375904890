import { DownloadOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { fileDownload } from 'fetcher';
import { FormattedMessage } from 'react-intl';
import { useGuides, useMedias } from 'useSWR';
import { downloadBlob } from 'utils';

export const GuideFiles = ({ offer }) => {
  const { guides } = useGuides();
  const { medias } = useMedias({ pagination: false, mediaType: 'guide' });

  const guide = guides?.find((guide) => guide.articleType?.id === offer?.articleType?.id);
  const otherGuides = medias?.['hydra:member']?.map((guide) => ({
    name: guide?.name,
    file: {
      name: guide?.name,
      path: guide?.fileFullUrl,
    },
  }));

  const guideList = [
    {
      name: `${offer?.articleType?.name} Türü Maddeler için Telif Kılavuzu`,
      file: {
        name: guide?.media?.name,
        path: guide?.media.fileFullUrl,
      },
    },
    ...(otherGuides || []),

    // Özel isimlendirme hakkında BE çalışması gerekiyor, şu an için comment olarak bırakıldı
    // {
    //   name: 'Madde Adı Kılavuzu',
    //   path: '',
    // },
    // {
    //   name: 'Madde Hacmi Kılavuzu',
    //   path: '',
    // },
    // {
    //   name: 'Atıf ve Kaynaklar Kılavuzu',
    //   path: '',
    // },
    // {
    //   name: 'Çeviri Yazı Kılavuzu',
    //   path: '',
    // },
    // {
    //   name: 'İmla Kılavuzu',
    //   path: '',
    // },
  ];

  async function download(file) {
    const { name, path } = file;
    const downloaded = await fileDownload(path);
    downloadBlob(downloaded.data, name);
  }

  return (
    <div>
      <h2 className="gx-mb-4">
        <FormattedMessage id="article.detail.guides" />
        <span className="gx-fs-md"> ({guideList?.length}) </span>
      </h2>

      <Row>
        {guideList?.map((guide, i) => (
          <Col
            key={i}
            span={12}
          >
            <Card className="gx-py-1 gx-mb-3">
              <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="gx-d-flex gx-align-items-center">
                  <span className=" gx-fs-xxl">
                    <NodeExpandOutlined />
                  </span>
                  <span className="gx-ml-3">{guide.name}</span>
                </div>

                <a
                  className="gx-ml-2"
                  onClick={() => download(guide.file)}
                >
                  <DownloadOutlined className="gx-fs-xl" />
                </a>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
