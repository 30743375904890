import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ref: {},
  inputName: 'default',
  value: '',
};

const keyboardSlice = createSlice({
  name: 'keyboard',
  initialState,
  reducers: {
    setKeyboardRef: (state, action) => {
      state.ref = action.payload;
    },
    setKeyboardInputName: (state, action) => {
      state.inputName = action.payload;
    },
    setKeyboardInput: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setKeyboardRef, setKeyboardInputName, setKeyboardInput } = keyboardSlice.actions;

export default keyboardSlice.reducer;
