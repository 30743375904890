import { Helmet } from 'react-helmet';

export const Title = ({ children }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title> {children} - İYS - İslam Ansiklopedisi</title>
    </Helmet>
  );
};

export default Title;
