import React, { useEffect, useState } from 'react';

import { Collapse, Select, Row, Col, Radio, Divider, Checkbox, Button, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useArticleTypes } from 'useSWR';
import { OFFER_STATUS } from 'utils';
import { fetchSearchE1, fetchE1Article } from 'fetcher';
import { CircularProgress, Form, Input } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { useGetArticleGroupsQuery } from 'services';

const { Panel } = Collapse;
const { Option } = Select;

const ihStatuses = [
  { id: 7, status: 'Taslak' },
  { id: 0, status: 'Gündemde' },
  { id: 1, status: 'Beklemede' },
  { id: 2, status: 'Kabul' },
];

const dahStatuses = [
  { id: 0, status: 'Gündemde' },
  { id: 1, status: 'Beklemede' },
  { id: 2, status: 'Kabul' },
  { id: 6, status: 'Havale' },
  { id: 3, status: 'İade' },
  { id: 4, status: 'Heyet Değişikliği' },
];

const aykStatuses = [
  { id: 0, status: 'Gündemde' },
  { id: 1, status: 'Beklemede' },
  { id: 2, status: 'Kabul' },
  { id: 3, status: 'İade' },
  { id: 5, status: 'Ret' },
];

export const DetailedFilter = ({ form }) => {
  const { formatMessage } = useIntl();
  const { data: articleGroups, isLoading: loadingGroups } = useGetArticleGroupsQuery();
  const { articleTypes, isLoading: loadingTypes } = useArticleTypes();

  let timeCheck;
  const { isLoading } = useUserInfo();
  const [fetching, setFetching] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [activePanels, setActivePanels] = useState([]);

  const handleSearch = async (e, type = null) => {
    setFetching(true);
    clearTimeout(timeCheck);
    timeCheck = setTimeout(async () => {
      if (e.length >= 2) {
        try {
          const result = await fetchSearchE1(e, type ? !type : '');
          setFetching(true);
          setSearchResult(result['hydra:member']);
          setFetching(false);
        } catch (e) {
          console.log(e);
        }
      }
    }, 500);
  };

  /**
   * Detaylı filtreler açıldıktan sonra gelen panellerin altında seçili değerler varsa
   * panellerin açık konumda görünmesi için panel altındaki filtreler kontrol edilip
   * aktif filtresi olan panellerin `key` değerleri `Collapse` componentine `activeKey` olarak verilir
   *
   * Panel altındaki ilgili field(lar) truthy değere sahipse veya elemanı bulunan
   * bir array ise o panel açılacaklar listesine alınır
   */
  useEffect(() => {
    // Durum paneli altındaki değerler
    const statusItems = form.getFieldsValue(['status', 'ihStatus', 'dahStatus', 'aykStatus', 'articleGroup', 'articleType']);

    // Birinci edisyonda ansiklopedi/atıf maddesi olarak yer alıp almadığı paneli altındaki değerler
    const firstEditionItems = form.getFieldsValue(['birinciEdisyondaYerAldi', 'atifTam', 'oldTitle', 'rewriteandUpdate', 'oldArticleWorkType']);

    const panels = [];

    // Birinci edisyonda ansiklopedi/atıf maddesi paneli altında seçili değer var mı
    const firstEditionCheck = Object.values(firstEditionItems).some((f) => {
      if (Array.isArray(f)) {
        return f.length > 0;
      }
      return !!f;
    });

    // Durum paneli altında truthy değer varsa paneli ekle
    if (Object.values(statusItems).some((f) => !!f)) {
      panels.push('detailed-1');
    }

    // Birinci edisyonda ansiklopedi/atıf maddesi panelinde seçili değer varsa paneli ekle
    if (firstEditionCheck) {
      const oldTitle = form.getFieldValue('oldTitle');

      /**
       * Eğer birinci edisyonda geçtiği madde seçilmişse bu maddenin IDsi ayrılıp madde bilgisi
       * birinci edisyondan aldıktan sonra ilgili fielda seçim anındaki adıyla eklenir
       */
      if (oldTitle) {
        fetchE1Article(oldTitle).then((res) => {
          form.setFieldsValue({
            oldTitle: {
              key: `&oldTitle[]=${oldTitle}`,
              label: [res.title, ' (', res.slug, ')'],
              value: `&oldTitle[]=${oldTitle}`,
            },
          });
        });
      }

      panels.push('detailed-2');
    }

    // Birinci edisyondaki maddelerde bir isim/terim olarak geçiyorsa paneli ekle
    if (form.getFieldValue('yakinIfadeZikrediliyorMu')) {
      const yakinIfade = form.getFieldValue('oldArticles');

      /**
       * Eğer hangi maddelerde geçtiği seçilmişse bu maddelerin IDleri ayrılıp madde bilgileri
       * birinci edisyondan aldıktan sonra ilgili fielda seçim anındaki adlarıyla eklenir
       */
      if (yakinIfade && yakinIfade?.length > 0) {
        form.setFieldsValue({ yakinIfadeZikrediliyorMu: 'Evet' });

        const articles = yakinIfade.map((item) => {
          const id = item.split('=')[1];
          return fetchE1Article(id, true);
        });

        Promise.all(articles).then((res) => {
          form.setFieldsValue({
            oldArticles: res.map((i) => ({
              value: [i.title, ' (', i.slug, ')'],
            })),
          });
        });
      }

      panels.push('detailed-3');
    }

    // İkinci edisyonda geçme sebebi boş değilse paneli ekle
    if (form.getFieldValue('detectReason')) {
      panels.push('detailed-4');
    }

    setActivePanels(panels);
  }, [form]);

  if (loadingGroups || loadingTypes || isLoading) {
    return (
      <div className="gx-d-flex">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Collapse
      expandIconPosition="right"
      style={{ width: '100%' }}
      className="gx-mt-4"
      activeKey={activePanels}
      onChange={(key) => setActivePanels(key)}
      ghost
    >
      <Divider className="gx-mt-2 gx-mb-2" />

      <Panel
        header={formatMessage({ id: 'appModule.situation' })}
        key="detailed-1"
      >
        <Row style={{ flexDirection: 'row' }}>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="gx-p-0 gx-pr-2 gx-mb-2"
          >
            <Form.Item name="status">
              <Select
                defaultValue=""
                style={{ width: '100%' }}
              >
                <Option value="">Son Durum</Option>
                {Object.keys(OFFER_STATUS).map(
                  (item) =>
                    item !== '19' && (
                      <Option
                        key={`latest-${item}`}
                        value={`&targetOffer.status=${item}`}
                      >
                        {OFFER_STATUS[item]}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="gx-p-0 gx-pr-2 gx-mb-2"
          >
            <Form.Item name="ihStatus">
              <Select
                defaultValue=""
                style={{ width: '100%' }}
              >
                <Option value="">İH Durumu</Option>
                {ihStatuses.map((item) => (
                  <Option
                    key={`ih-${item.id}`}
                    value={`&targetOffer.committeeStatus=${item.id}`}
                  >
                    {item.status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="gx-p-0 gx-pr-2 gx-mb-2"
          >
            <Form.Item name="dahStatus">
              <Select
                defaultValue=""
                style={{ width: '100%' }}
              >
                <Option value="">DAH Durumu</Option>
                {dahStatuses.map((item) => (
                  <Option
                    key={`dah-${item.id}`}
                    value={`&targetOffer.dahStatus=${item.id}`}
                  >
                    {item.status}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="dahIncludeHistory"
              valuePropName="checked"
            >
              <Checkbox className="gx-mt-2">
                <FormattedMessage id="article.table.decisionReturned" />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            className="gx-p-0 gx-pr-2 gx-mb-2"
          >
            <Form.Item name="aykStatus">
              <Select
                defaultValue=""
                style={{ width: '100%' }}
              >
                <Option value="">AYK Durumu</Option>
                {aykStatuses.map((item) => (
                  <Option
                    key={`ayk-${item.id}`}
                    value={`&targetOffer.aykStatus=${item.id}`}
                  >
                    {item.status}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="aykIncludeHistory"
              valuePropName="checked"
            >
              <Checkbox className="gx-mt-2">
                <FormattedMessage id="article.table.decisionReturned" />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row' }}>
          <Col
            xs={24}
            md={12}
            className="gx-p-0"
          >
            <div className="gx-mt-4 gx-mb-4">Ansiklopedi Grubu</div>
            <div className="gx-d-flex">
              <Form.Item name="articleGroup">
                <Radio.Group buttonStyle="solid">
                  {articleGroups?.map((item) => (
                    <Radio.Button
                      key={`article-group-${item.id}`}
                      value={`&articleGroup=${item.id}`}
                    >
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('articleGroup')) {
                    return (
                      <Button
                        type="link"
                        onClick={() => {
                          setFieldsValue({
                            articleGroup: null,
                          });
                        }}
                        className="gx-mb-2"
                      >
                        Temizle
                      </Button>
                    );
                  }
                }}
              </Form.Item>
            </div>
          </Col>
          <Col
            xs={24}
            md={12}
            className="gx-p-0 gx-pr-2"
          >
            <div className="gx-mt-4 gx-mb-4">Madde Türü</div>
            <Form.Item name="articleType">
              <Select
                defaultValue=""
                style={{ width: '100%' }}
              >
                <Option value="">Seçiniz</Option>
                {articleTypes?.map((item) => (
                  <Option
                    key={`articleType-${item['@id']}`}
                    value={`&articleType=${item.id}`}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>

      <Divider className="gx-mt-2 gx-mb-2" />

      <Panel
        header={formatMessage({ id: 'article.form.articleBeenEdisionOne' })}
        key="detailed-2"
      >
        <div className="gx-d-flex">
          <Form.Item name="birinciEdisyondaYerAldi">
            <Radio.Group
              name="birinciEdisyondaYerAldi"
              buttonStyle="solid"
            >
              <Radio.Button
                className="gx-mt-2"
                value="Evet"
              >
                <FormattedMessage id="appModule.yes" />
              </Radio.Button>
              <Radio.Button
                className="gx-mt-2"
                value="Hayır"
              >
                <FormattedMessage id="appModule.no" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              if (getFieldValue('birinciEdisyondaYerAldi')) {
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      setFieldsValue({
                        birinciEdisyondaYerAldi: null,
                        atifTam: null,
                        oldTitle: null,
                        rewriteandUpdate: null,
                        oldArticleWorkType: null,
                      });
                    }}
                    className="gx-mt-2"
                  >
                    Temizle
                  </Button>
                );
              }
            }}
          </Form.Item>
        </div>

        <Form.Item
          style={{
            cursor: 'default',
          }}
          dependencies={['birinciEdisyondaYerAldi']}
          shouldUpdate
        >
          {({ getFieldValue, setFieldsValue }) => {
            if (getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
              return (
                <>
                  <div className="gx-mt-4 gx-mb-2 gx-ml-5">
                    <FormattedMessage id="article.form.atifTam" />
                  </div>

                  <div className="gx-d-flex gx-mb-3 gx-ml-5">
                    <Form.Item
                      name="atifTam"
                      dependencies={['birinciEdisyondaYerAldi']}
                      shouldUpdate
                    >
                      <Radio.Group
                        onChange={() =>
                          setFieldsValue({
                            oldTitle: null,
                            oldArticleWorkType: null,
                            rewriteandUpdate: null,
                          })
                        }
                        buttonStyle="solid"
                      >
                        <Radio.Button value="0">
                          <FormattedMessage id="article.form.Tam" />
                        </Radio.Button>
                        <Radio.Button value="1">
                          <FormattedMessage id="article.form.Atif" />
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => {
                        if (getFieldValue('atifTam')) {
                          return (
                            <Button
                              type="link"
                              onClick={() => {
                                setFieldsValue({
                                  atifTam: null,
                                });
                              }}
                              className="gx-mb-2"
                            >
                              Temizle
                            </Button>
                          );
                        }
                      }}
                    </Form.Item>
                  </div>
                </>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          dependencies={['atifTam', 'birinciEdisyondaYerAldi']}
          shouldUpdate
        >
          {({ getFieldValue }) => {
            if (getFieldValue('birinciEdisyondaYerAldi') === 'Evet' && getFieldValue('atifTam')) {
              return (
                <div className="gx-mb-3 gx-ml-5">
                  <Form.Item
                    label={<FormattedMessage id="article.form.SearchEdisionOneLabel" />}
                    labelfor="oldTitle"
                    name="oldTitle"
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      labelInValue="hangiMadde"
                      name="hangiMadde"
                      placeholder={formatMessage({
                        id: 'article.form.SearchEdisionOnePlaceholder',
                      })}
                      loading={fetching}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={(e) => handleSearch(e, getFieldValue('atifTam'))}
                      style={{ display: 'block' }}
                      allowClear
                      showSearch
                      dropdownStyle={{ position: 'relative' }}
                      onClick={() => setSearchResult([])}
                    >
                      {searchResult.map((d) => (
                        <Option
                          key={d.id}
                          value={`&oldTitle[]=${d.id}`}
                        >
                          {d.title} ({d.slug})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    dependencies={['atifTam', 'birinciEdisyondaYerAldi', 'rewriteandUpdate']}
                    className="gx-mt-2"
                  >
                    {({ getFieldValue }) =>
                      getFieldValue('birinciEdisyondaYerAldi') === 'Evet' &&
                      getFieldValue('atifTam') === '0' && (
                        <>
                          <div className="gx-mb-2 gx-mt-4">
                            <FormattedMessage id="article.form.oldArticleWorkType" />
                          </div>
                          <div className="gx-d-flex">
                            <Form.Item
                              name="rewriteandUpdate"
                              dependencies={['birinciEdisyondaYerAldi']}
                            >
                              <Radio.Group buttonStyle="solid">
                                <Radio.Button value="Yeniden Yazım">
                                  <FormattedMessage id="article.formWorkToBeRewrite" />
                                </Radio.Button>
                                <Radio.Button value="Güncelleme">
                                  <FormattedMessage id="article.form.Update" />
                                </Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                              {({ getFieldValue, setFieldsValue }) => {
                                if (getFieldValue('rewriteandUpdate')) {
                                  return (
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        setFieldsValue({
                                          rewriteandUpdate: null,
                                          oldArticleWorkType: null,
                                        });
                                      }}
                                      className="gx-mb-2"
                                    >
                                      Temizle
                                    </Button>
                                  );
                                }
                              }}
                            </Form.Item>
                          </div>
                        </>
                      )
                    }
                  </Form.Item>

                  <Form.Item
                    dependencies={['rewriteandUpdate', 'oldArticleWorkType']}
                    shouldUpdate
                  >
                    {({ getFieldValue }) => {
                      const oldArticleWorkType = getFieldValue('oldArticleWorkType') ?? [];
                      const oldArticleWorkTypeLength = oldArticleWorkType.length > 0;
                      const isHaveZero = Array.isArray(oldArticleWorkType) && oldArticleWorkType?.includes('2');

                      if (getFieldValue('rewriteandUpdate') === 'Güncelleme' || getFieldValue('atifTam') === '1') {
                        return (
                          <Form.Item
                            name="oldArticleWorkType"
                            labelfor="oldArticleWorkType"
                            label={getFieldValue('atifTam') === '1' && <FormattedMessage id="article.form.oldArticleWorkType" />}
                          >
                            {getFieldValue('atifTam') === '1' ? (
                              <Radio.Group>
                                <Radio
                                  className="gx-mt-2"
                                  value={4}
                                >
                                  <FormattedMessage id="article.formWorkToBeChangeToEncyclopedia" />
                                </Radio>
                                <br />
                                <Radio
                                  className="gx-mt-2"
                                  value={5}
                                >
                                  <FormattedMessage id="article.formWorkToBeChangeToGlossary" />
                                </Radio>
                              </Radio.Group>
                            ) : (
                              <Checkbox.Group className="gx-d-flex gx-mt-2">
                                <Checkbox
                                  className="gx-mt-2"
                                  value="2"
                                  disabled={oldArticleWorkTypeLength && !isHaveZero}
                                >
                                  <FormattedMessage id="article.formWorkToBeReview" />
                                </Checkbox>
                                <br />
                                <Checkbox
                                  className="gx-mt-2"
                                  value="1"
                                  disabled={isHaveZero}
                                >
                                  <FormattedMessage id="article.formWorkToBeSupply" />
                                </Checkbox>
                                <br />
                                <Checkbox
                                  className="gx-mt-2"
                                  value="3"
                                  disabled={isHaveZero}
                                >
                                  <FormattedMessage id="article.formWorkToBeCorrection" />
                                </Checkbox>
                              </Checkbox.Group>
                            )}
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
      </Panel>

      <Divider className="gx-mt-2 gx-mb-2" />

      <Panel
        header={formatMessage({ id: 'article.form.NearstExpressionOldArticle' })}
        key="detailed-3"
      >
        <div className="gx-d-flex">
          <Form.Item name="yakinIfadeZikrediliyorMu">
            <Radio.Group
              name="yakinIfadeZikrediliyorMu"
              buttonStyle="solid"
            >
              <Radio.Button
                className="gx-mt-2"
                value="Evet"
              >
                <FormattedMessage id="appModule.yes" />
              </Radio.Button>
              <Radio.Button
                className="gx-mt-2"
                value="Hayır"
              >
                <FormattedMessage id="appModule.no" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              if (getFieldValue('yakinIfadeZikrediliyorMu')) {
                return (
                  <Button
                    type="link"
                    onClick={() => {
                      setFieldsValue({
                        yakinIfadeZikrediliyorMu: null,
                        oldArticles: null,
                      });
                    }}
                    className="gx-mt-2"
                  >
                    Temizle
                  </Button>
                );
              }
            }}
          </Form.Item>
        </div>
        <Form.Item
          dependencies={['yakinIfadeZikrediliyorMu']}
          shouldUpdate
        >
          {({ getFieldValue }) => {
            if (getFieldValue('yakinIfadeZikrediliyorMu') === 'Evet') {
              return (
                <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                  <Form.Item
                    label={<FormattedMessage id="article.form.WhichOldArticles" />}
                    labelfor={'oldArticles'}
                    name={'oldArticles'}
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      labelInValue="yakinIfadeMaddeler"
                      name="yakinIfadeMaddeler"
                      placeholder={formatMessage({
                        id: 'article.form.SearchEdisionOnePlaceholder',
                      })}
                      loading={fetching}
                      notFoundContent={fetching ? <Spin size="small" /> : null}
                      filterOption={false}
                      onSearch={(e) => handleSearch(e, 0)}
                      allowClear
                      showSearch
                      dropdownStyle={{ position: 'relative' }}
                      mode="multiple"
                      onClick={() => setSearchResult([])}
                    >
                      {searchResult.map((d) => (
                        <Option key={d.id}>
                          {d.title} ({d.slug})
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
      </Panel>

      <Divider className="gx-mt-2 gx-mb-2" />

      <Panel
        header={formatMessage({ id: 'article.form.offerReason' })}
        key="detailed-4"
      >
        <Form.Item name="detectReason">
          <Input placeholder="Gerekçeyi yazınız" />
        </Form.Item>
      </Panel>
    </Collapse>
  );
};
