import { Button, Card, Descriptions, Dropdown, List, Menu } from 'antd';
import { DownloadOutlined, DownOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { articleLength, getReadableFileSize } from 'utils';
import { useUserInfo } from 'providers/UserInfoProvider';

export default ({ offer, offerVersions, currentOfferStep, setVersionUrl, status, loading, setEditable, setActiveTab }) => {
  const { formatMessage } = useIntl();
  const { Item } = Descriptions;

  const { memberships, isAdmin, isManager } = useUserInfo();

  const getVersion = (offerVersions) => {
    if (!offerVersions) {
      return 1;
    }
    const version = offerVersions[0]?.version;
    return version ? version + 1 : 1;
  };

  const newVersion = () =>
    status === 'committee' &&
    isManager &&
    memberships?.some((item) => {
      if (offer?.ownerCommittee) return item?.committee?.id === offer?.ownerCommittee?.id;
      if (offer?.otherCommittees) return item?.committee?.id === offer?.otherCommittees[0]?.id;
      return false;
    });

  const menu = (
    <Menu
      selectedKeys={'v' + offer?.version ? offer?.version : 1}
      defaultSelectedKeys={'v' + offer?.version ? offer?.version : 1}
      style={{ height: 'auto', overflow: 'auto', maxHeight: 300 }}
    >
      {currentOfferStep <= 2 && (
        <Menu.Item
          onClick={() => {
            setVersionUrl('current');
          }}
          key="newest"
        >
          {formatMessage({ id: 'article.draft' }, { version: getVersion(offerVersions) })}
        </Menu.Item>
      )}
      {offerVersions?.map((version) => (
        <Menu.Item
          onClick={() => {
            setVersionUrl(version['@id'].replaceAll('/api', ''));
          }}
          key={'v' + version.version}
        >
          v{version.version}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Card
        title={
          <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
            <h2
              style={{ marginTop: 2 }}
              className={'gx-h2-lg'}
            >
              <FormattedMessage id="article.process.content.info" />
            </h2>{' '}
            <div>
              {!offer?.isDraft ? (
                <Dropdown
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  trigger={['click']}
                  overlay={menu}
                >
                  <Button className="gx-mb-0">
                    {offer?.version === 0 || offer?.version
                      ? 'v' + offer?.version
                      : formatMessage({ id: 'article.draft' }, { version: offerVersions?.[0]?.version + 1 })}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  danger
                  className="gx-mb-0"
                  style={{ cursor: 'default' }}
                  onClick={() => setEditable(true)}
                >
                  Taslak
                </Button>
              )}

              {((newVersion && [0, 1, 2, 3, 4, 9].includes(offer?.status)) || isAdmin) && offer?.version !== 0 && !offer?.version && (
                <Button
                  type="primary"
                  className="gx-mb-0 "
                  icon={<EditOutlined />}
                  title={formatMessage({
                    id: 'article.detail.EditArticle',
                  })}
                  data-cy="edit-article-button"
                  onClick={() => {
                    setEditable(true);
                    setActiveTab('1');
                  }}
                />
              )}
            </div>
          </div>
        }
        loading={loading}
      >
        <Descriptions
          layout="horizontal"
          className="offer-detail"
          column={1}
          bordered
        >
          <Item label={<FormattedMessage id="article.detail.articleCode" />}>{offer?.articleCodeFormatted}</Item>

          <Item label={<FormattedMessage id="article.detail.articleTitle" />}>{offer?.title}</Item>

          <Item label={<FormattedMessage id="committee" />}>{offer?.ownerCommittee?.name}</Item>

          <Item label={<FormattedMessage id="article.detail.articleGroup" />}>{offer?.articleGroup?.name}</Item>

          {offer?.committeeContributions?.length > 0 && (
            <Item label={<FormattedMessage id="article.detail.contributionCommittees" />}>
              {offer?.committeeContributions?.map(({ committee }) => (
                <p
                  key={committee.id}
                  className="gx-my-1"
                >
                  {committee.name}
                </p>
              ))}
            </Item>
          )}

          <Item label={<FormattedMessage id="article.detail.articleType" />}>
            {offer?.articleType?.name || <FormattedMessage id="article.detail.articleTypeOther" />}
          </Item>

          {offer?.articleTypeOther && <Item label={<FormattedMessage id="article.detail.articleTypeOtherTitle" />}>{offer?.articleTypeOther}</Item>}

          <Item label={<FormattedMessage id="article.detail.articleSupervisor" />}>
            {offer?.articleSupervisor?.firstName} {offer?.articleSupervisor?.lastName}
          </Item>

          <Item label={<FormattedMessage id="article.detail.articleLength" />}>{articleLength[offer?.articleLength]}</Item>

          {offer?.articleLengthReason && <Item label={<FormattedMessage id="article.detail.articleLengthReason" />}>{offer?.articleLengthReason}</Item>}
        </Descriptions>
      </Card>

      {offer?.authors?.map((author) => (
        <Card
          key={author['@id']}
          title={<FormattedMessage id="article.process.content.author" />}
          loading={loading}
        >
          <Descriptions
            layout="horizontal"
            className="offer-detail"
            column={1}
            bordered
          >
            <Item label={<FormattedMessage id="author.name" />}>
              <a
                href={`/yazar-islemleri/yazar-duzenle/${author?.author.id}`}
                target="_blank"
                rel="noreferrer"
                className="gx-text-underline"
              >
                {author?.author?.user?.firstName} {author?.author?.user?.lastName}
              </a>
              <ExportOutlined
                style={{
                  marginLeft: 5,
                  marginBottom: 5,
                  fontSize: '8px',
                  color: '#3da4e6',
                }}
              />
            </Item>

            <Item label={<FormattedMessage id="article.process.author.reason" />}>{author.reason}</Item>
          </Descriptions>
        </Card>
      ))}

      {(offer?.contentDescription || offer?.contentMedia?.length > 0) && (
        <Card title={<FormattedMessage id="article.additionalInfo" />}>
          <Descriptions
            layout="horizontal"
            className="offer-detail"
            column={1}
            bordered
          >
            {offer?.contentDescription && <Item label={<FormattedMessage id="article.process.author.contentDescription" />}>{offer?.contentDescription}</Item>}

            {offer?.contentMedia?.length && (
              <Item
                label={<FormattedMessage id="article.detail.media" />}
                span={24}
              >
                <List
                  size="small"
                  itemLayout="vertical"
                  dataSource={offer?.contentMedia}
                  renderItem={({ media }) => (
                    <List.Item className="gx-pl-0 gx-py-1">
                      {media.originalFilename} ({getReadableFileSize(media)})
                      <a
                        href={`${process.env.REACT_APP_PUBLIC_BASE_URL}${media.relativePath}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        <DownloadOutlined />
                      </a>
                    </List.Item>
                  )}
                />
              </Item>
            )}
          </Descriptions>
        </Card>
      )}
    </>
  );
};
