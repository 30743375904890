import React from 'react';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const { SubMenu } = Menu;

const SubMenus = React.memo(
  ({
    isAdmin,
    memberships,
    defaultOpenKeys,
    defaultOpenKeysSub,
    committees,
    openKeysSub,
    onOpenChange,
  }) => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        onOpenChange={(e) => onOpenChange(e, 'sub')}
        onSelect={({ keyPath }) => {
          onOpenChange(keyPath, 'subSingle');
        }}
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultOpenKeysSub}
        {...(openKeysSub && {
          openKeys: openKeysSub,
        })}
        {...(openKeysSub && {
          selectedKeys: openKeysSub,
        })}
      >
        {isAdmin
          ? committees?.map((item) =>
              ![18, 19].includes(item.id) ? (
                <SubMenu
                  key={`/madde-teklifleri/${item.id}`}
                  title={
                    <div
                      title={item.name}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      {item.name}
                    </div>
                  }
                  data-cy={'arap-dili-edebiyatı'}
                  className="committeess"
                >
                  {['test', 'local'].includes(
                    process.env.REACT_APP_SENTRY_ENV
                  ) && (
                    <Menu.Item
                      key={`${item.id}/teklif-edilen-maddeler`}
                      className="subtitle"
                      title="Bu alan sadece test ortamında bulunmaktadır."
                    >
                      <Link
                        to={`/madde-teklifleri/${item.id}/teklif-edilen-maddeler`}
                      >
                        <FormattedMessage id="sidebar.offeredArticles" /> (Test)
                      </Link>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key={`${item.id}/taslak-maddeler`}
                    data-cy={`taslak-maddeler-${item.id}`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.id}/taslak-maddeler`}
                      pros={item.name}
                    >
                      <FormattedMessage id="sidebar.draftArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key={`${item.id}/sorumlu-olunan-maddeler`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.id}/sorumlu-olunan-maddeler`}
                    >
                      <FormattedMessage id="sidebar.ownerArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    className="subtitle"
                    key={`${item.id}/katki-verilecek-maddeler`}
                  >
                    <Link
                      to={`/madde-teklifleri/${item.id}/katki-verilecek-maddeler`}
                    >
                      <FormattedMessage id="sidebar.contributionArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    className="subtitle"
                    key={`${item.id}/heyetten-cikan-maddeler`}
                  >
                    <Link
                      to={`/madde-teklifleri/${item.id}/heyetten-cikan-maddeler`}
                    >
                      <FormattedMessage id="sidebar.removedFromCommitteeArticles" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                <Menu.Item key={item.id} data-cy={`maddeler-${item.id}`}>
                  <Link
                    to={`/madde-teklifleri/${item.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <div
                      title={item.name}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      {item.name}
                    </div>
                  </Link>
                </Menu.Item>
              )
            )
          : memberships?.map((item) =>
              ![18, 19].includes(item.committee.id) ? (
                <SubMenu
                  key={item.committee.id}
                  title={
                    <div title={item.committee.name}>{item.committee.name}</div>
                  }
                  className="committeess"
                >
                  <Menu.Item
                    key={`${item.committee.id}/teklif-edilen-maddeler`}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Link
                      to={`/madde-teklifleri/${item.committee.id}/teklif-edilen-maddeler`}
                    >
                      <FormattedMessage id="sidebar.offeredArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key={`${item.committee.id}/taslak-maddeler`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.committee.id}/taslak-maddeler`}
                    >
                      <FormattedMessage id="sidebar.draftArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key={`${item.committee.id}/sorumlu-olunan-maddeler`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.committee.id}/sorumlu-olunan-maddeler`}
                    >
                      <FormattedMessage id="sidebar.ownerArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key={`${item.committee.id}/katki-verilecek-maddeler`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.committee.id}/katki-verilecek-maddeler`}
                    >
                      <FormattedMessage id="sidebar.contributionArticles" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key={`${item.committee.id}/heyetten-cikan-maddeler`}
                    className="subtitle"
                  >
                    <Link
                      to={`/madde-teklifleri/${item.committee.id}/heyetten-cikan-maddeler`}
                    >
                      <FormattedMessage id="sidebar.removedFromCommitteeArticles" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : (
                <Menu.Item key={item.committee.id}>
                  <Link
                    to={`/madde-teklifleri/${item.committee.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <div title={item.committee.name}>{item.committee.name}</div>
                  </Link>
                </Menu.Item>
              )
            )}
      </Menu>
    );
  }
);

export default SubMenus;
