import { useState, useEffect } from 'react';

import { Tabs, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useArticleTypes, useAuthors, useCommittee, usePullbackRequests } from 'useSWR';
import { useAlertLeavingPage } from 'hooks';
import { editOffer } from 'fetcher';
import { CircularProgress } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';
import { useGetArticleGroupsQuery } from 'services';

import { PullbackResponse, PullbackRequests } from './offer/ArticlePullback';
import { ArticleContentInfo, ArticleContentStatus, ArticleContentAuthor, ArticleContentForm } from './content';
import { ArticleSteps } from './offer';

const { TabPane } = Tabs;

const ArticleContentDetail2 = ({ offer, fetching, offerMutate, process }) => {
  // *****************************************  useState

  const [activeTab, setActiveTab] = useState('1');
  const [versionUrl, setVersionUrl] = useState('');
  const [editable, setEditable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [changeValueStep, setChangeValueStep] = useState();

  // *****************************************  Hooks
  const dispatch = useDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  // *****************************************  Dışarıdan Gelen SWR İstekleri

  const { memberships, isAdmin, isDAH, isAYK } = useUserInfo();
  const { committee, isLoading: loadingCommittee } = useCommittee(offer?.ownerCommittee?.id);
  const { articleTypes, isLoading: loadingTypes } = useArticleTypes();
  const { data: articleGroups, isLoading: loadingGroups } = useGetArticleGroupsQuery();
  const { data: authors, isLoading: loadingAuthors } = useAuthors({
    pagination: false,
  });
  const { pullbackRequests } = usePullbackRequests({ id: offer?.id, step: 2 });

  // *****************************************  İçerideki SWR İstekleri

  const { data: offerVersions } = useSWR(offer?.id ? `/article_offers/${offer?.id}/versions?step=2` : '');

  const last = offerVersions?.[0]?.['@id']?.replaceAll('/api', '');
  const { data: offerVersion, mutate } = useSWR(versionUrl && versionUrl !== 'current' && !offer.isDraft ? versionUrl : last);

  // *****************************************   Tab değişince içerideki offer verisi mutate ediliyor

  const onTabChange = () => {
    offerMutate();
    setChangeValueStep();
  };

  // *****************************************  Submit Fonksiyonları
  async function sendAuthors(values) {
    setLoading(true);
    try {
      const fileSizeSum = fileList.reduce((acc, file) => acc + file.size, 0);

      // throw if sum of file sizes are larger than 25 MB
      if (fileSizeSum > 25 * 1024 * 1024) {
        throw formatMessage({
          id: 'appModule.fileSizeExceeded',
        });
      }
      const data = {
        contentDescription: values.contentDescription,
        authors: values.authors.map((item) => ({
          id: item.id || undefined,
          author: item.author,
          reason: item.reason,
        })),
      };

      setEditable(false);
      await editOffer(id, data, fileList, 2);
      mutate();

      offerMutate();
      setActiveTab('3');
      setChangeValueStep();
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }
    setLoading(false);
  }

  async function sendForm(values) {
    setLoading(true);
    try {
      const { title, articleLength, articleLengthReason, articleSupervisor } = values;

      const type = values.articleType === 'other' ? 'articleTypeOther' : 'articleType';

      const data = {
        title,
        articleLength,
        ...(articleLength !== 0 && { articleLengthReason }),
        articleSupervisor,
        [type]: values[type],
      };

      await editOffer(id, data, fileList, 2);
      mutate();
      offerMutate();

      setActiveTab('2');
      setChangeValueStep();
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }
    setLoading(false);
  }

  // *****************************************   Form Fields

  const hasActivePullback = pullbackRequests?.some((req) => req.status === 0);
  const isCommitteeMember = memberships.some((item) => item.committee.id === offer?.ownerCommittee?.id && [0, 1].includes(item.type));

  // Formdan ayılırken alert veren hook
  useAlertLeavingPage();

  useEffect(() => {
    setLoading(true);
    if (!offer) {
      return;
    }

    const currentOffer = versionUrl === 'current' || !offerVersion ? offer : offerVersion;

    if (currentOffer?.contentMedia?.length > 0) {
      const files = offer.contentMedia.map(({ media }) => ({
        uid: media.id,
        name: media.originalFilename,
        size: media.filesize,
        status: 'done',
      }));
      setFileList(files);
    } else {
      setFileList([]);
    }

    if (offer?.step > 2) {
      setEditable(false);
    }

    setLoading(false);
  }, [offer, offerVersion, versionUrl]);

  // *****************************************   Eğer istenilen alanlar doluysa sayfa yenilendiğinde düzenlemeyi kapat

  useEffect(() => {
    if (offer?.articleSupervisor && offer?.authors?.length) {
      setEditable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *****************************************   Hangi ilim kurulu karar aşamasında olduğunu belirleyen fonksiyon

  useEffect(() => {
    if (offer) {
      const { committeeStatus, dahStatus, aykStatus, articleSupervisor, authors: author } = offer;
      if (!articleSupervisor) {
        setActiveTab('1');
      } else if (author.length == 0) {
        setActiveTab('2');
      } else if (committeeStatus === 0) {
        setActiveTab('3');
      } else if (dahStatus === 0) {
        setActiveTab('4');
      } else if (aykStatus === 0) {
        setActiveTab('5');
      }
    }
  }, [offer]);

  // *****************************************   PANE 3 -- PANE 4 -- PANE 5 BURADAN GELİYOR

  const CommitteeTab = ({ status, children }) => (
    <Row gutter={32}>
      <Col span={12}>
        <ArticleContentInfo
          offer={versionUrl === 'current' || !offerVersion ? offer : offerVersion}
          offerVersions={offerVersions}
          currentOfferStep={offer.step}
          setVersionUrl={setVersionUrl}
          status={status}
          loading={loadingCommittee || loadingTypes || loadingGroups || loadingAuthors}
          setEditable={setEditable}
          setActiveTab={setActiveTab}
        />
      </Col>
      <Col span={12}>
        <ArticleContentStatus
          offer={offer}
          status={status}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          hasActivePullback={hasActivePullback}
        />

        {children}
      </Col>
    </Row>
  );

  if (loadingCommittee || loadingTypes || loadingGroups || loadingAuthors) {
    return <CircularProgress />;
  }

  // *********** CONTENT

  return (
    <div>
      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.process.content.title" />
      </h2>
      <Row justify="center">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={18}
        >
          <ArticleSteps
            process={process}
            step={offer?.step}
            status={offer?.status}
            committeeStatus={offer.committeeStatus}
            dahStatus={offer.dahStatus}
            aykStatus={offer.aykStatus}
          />
        </Col>
      </Row>
      <Tabs
        onChange={onTabChange}
        type="card"
        animated
        centered
        tabBarGutter={8}
        tabBarStyle={{ margin: '2rem 0' }}
        activeKey={activeTab}
        onTabClick={(key) => setActiveTab(key)}
      >
        <TabPane
          key="1"
          tab={formatMessage({ id: 'article.process.content.title' })}
          disabled={fetching}
        >
          <ArticleContentForm
            offer={versionUrl === 'current' || !offerVersion ? offer : offerVersion}
            committee={committee}
            articleTypes={articleTypes}
            articleGroups={articleGroups}
            loading={loading || loadingCommittee || loadingTypes || loadingGroups}
            editable={editable}
            setEditable={setEditable}
            versionUrl={versionUrl}
            setVersionUrl={setVersionUrl}
            setChangeValueStep={setChangeValueStep}
            sendForm={sendForm}
          />
        </TabPane>
        <TabPane
          key="2"
          tab={formatMessage({ id: 'article.process.content.author' })}
          disabled={fetching || !offer?.articleSupervisor || changeValueStep === '1'}
        >
          <ArticleContentAuthor
            offer={versionUrl === 'current' || !offerVersion ? offer : offerVersion}
            fileList={fileList}
            setFileList={setFileList}
            authors={authors}
            loading={loading || loadingAuthors}
            editable={editable}
            versionUrl={versionUrl}
            setActiveTab={setActiveTab}
            setChangeValueStep={setChangeValueStep}
            sendAuthors={sendAuthors}
          />
        </TabPane>
        <TabPane
          key="3"
          tab={formatMessage({ id: 'committee' })}
          disabled={fetching || !offer?.authors?.length || changeValueStep}
        >
          <CommitteeTab status="committee">
            {(isCommitteeMember || isAdmin) && (
              <PullbackRequests
                id={offer?.id}
                committee="committee"
                pullbackRequests={pullbackRequests}
              />
            )}
          </CommitteeTab>
        </TabPane>
        <TabPane
          key="4"
          tab={formatMessage({ id: 'dah' })}
          disabled={fetching || !offer?.authors?.length || changeValueStep}
        >
          <CommitteeTab status="dah">
            {(isAdmin || isDAH) && (
              <>
                <PullbackRequests
                  id={offer?.id}
                  committee="dah"
                  pullbackRequests={pullbackRequests}
                />

                <PullbackResponse
                  id={offer?.id}
                  committee="dah"
                  pullbackRequests={pullbackRequests}
                />
              </>
            )}
          </CommitteeTab>
        </TabPane>
        <TabPane
          key="5"
          tab={formatMessage({ id: 'ayk' })}
          disabled={fetching || !offer?.authors?.length || changeValueStep}
        >
          <CommitteeTab status="ayk">
            {(isAdmin || isAYK) && (
              <PullbackResponse
                id={offer?.id}
                committee="ayk"
                pullbackRequests={pullbackRequests}
              />
            )}
          </CommitteeTab>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ArticleContentDetail2;
