import useSWR from 'swr';
import { getFilteredURL } from 'utils';

const useMedias = ({ pagination = true, page = 1, pageSize = 20, ...filters }) => {
  const url = getFilteredURL('/media', {
    pagination,
    page,
    pageSize,
    ...filters,
  });

  const { data, error } = useSWR(url, { revalidateOnFocus: false });

  return {
    medias: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useMedias;
