import { message } from 'antd';

export function showInfoMessage(content) {
  message.info(
    <span
      id="message-id"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

export function showErrorMessage(content) {
  message.error(
    <span
      id="message-id"
      style={{ lineHeight: 1.6 }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
