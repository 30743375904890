import apiInstance from './api';
const stiplatedTimeApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getStiplatedTime: build.query({
      query: () => ({
        url: '/stipulated_time',
        method: 'GET',
      }),
      providesTags: ['StiplatedTimes'],
    }),
  }),
  overrideExisting: true,
});

export default stiplatedTimeApi;
