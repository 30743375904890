import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Title } from 'components';
import { MISSION } from 'utils';

export const UserProfileHeader = ({ firstName, lastName, memberships }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="gx-profile-banner">
      <Title>{formatMessage({ id: 'user.info.about' })}</Title>

      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            {/* <div className="gx-profile-banner-avatar">
              <Avatar
                className="gx-size-90"
                alt="..."
                src={"https://via.placeholder.com/150"}
              />
            </div> */}
            <div className="gx-profile-banner-avatar-info gx-ml-4">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light gx-avatar-name">
                {`${firstName} ${lastName}`}
              </h2>

              {memberships?.map((committee) => (
                <p
                  className="gx-mb-0 gx-fs-lg"
                  key={committee.committee.id}
                >{`${committee.committee.name} ${
                  [18, 19].includes(committee.committee.id)
                    ? MISSION[committee.type].split(' ')[1]
                    : MISSION[committee.type]
                }`}</p>
              ))}
            </div>
          </div>
        </div>

        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">{/* navlist*/}</div>

          <Link className="gx-link gx-profile-setting" to="/profil-duzenle">
            <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />

            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
              <FormattedMessage id="appModule.edit" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
