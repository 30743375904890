import { useState, useRef, useEffect } from 'react';

import { Button } from 'antd';
import { CloseOutlined, DragOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import Keyboard from 'react-simple-keyboard';
import { KeyboardIcon } from 'components/icons';
import { setKeyboardInput, setKeyboardInputName, setKeyboardRef } from 'slices/keyboardSlice';

import { KeyboardShift } from './KeyboardShift';

const display = {
  '{shift}': 'Aa',
  '<i>': '&lt;i&gt;',
  '</i>': '&lt;/i&gt;',
  '<b>': '&lt;b&gt;',
  '</b>': '&lt;/b&gt;',
  '<br>': '&lt;br&gt;',
};

const keyboardLayouts = {
  default: [
    '“ ” ‘ ’ ʿ ʾ ᴗ – {space} ¹ ² ³',
    'â î û ê ŝ {space} ā ī ō ū',
    'ḍ ġ ḥ ḫ ḳ ñ {space} s̱ ṣ ṭ ẕ ż ẓ',
    'ǎ č ř š ž {space} á é ó ś ú ź ǿ {space} à è',
    '{space} k̲h̲ d̲h̲ s̲h̲ t̲h̲ g̲h̲ z̲h̲ d̲j̲ {space}',
  ],
  shift: [
    '“ ” ‘ ’ ʿ ʾ ᴗ – {space} ¹ ² ³',
    'Â Î Û Ê Ŝ {space} Ā Ī Ō Ū',
    'Ḍ Ġ Ḥ Ḫ Ḳ Ñ {space} S̱ Ṣ Ṭ Ẕ Ż Ẓ',
    'Ǎ Č Ř Š Ž {space} Á É Ó Ś Ú Ź Ǿ {space} À È',
    '{space} K̲H̲ D̲H̲ S̲H̲ T̲H̲ G̲H̲ Z̲H̲ D̲J̲ {space}',
  ],
};

const buttonTheme = [
  {
    class: 'kb-font-xlarge',
    buttons: 'ʿ ʾ “ ” ‘ ’',
  },
  {
    class: 'kb-font-large',
    buttons: '¹ ² ³',
  },
  {
    class: 'kb-spacer',
    buttons: '{space}',
  },
];

export const VirtualKeyboard = () => {
  const keyboardRef = useRef();
  const dispatch = useDispatch();

  const inputName = useSelector(({ keyboard }) => keyboard.inputName);
  const value = useSelector(({ keyboard }) => keyboard.value);

  const [layout, setLayout] = useState('default');
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(true);
  const [handleHover, setHandleHover] = useState(false);

  useEffect(() => {
    dispatch(setKeyboardRef(keyboardRef.current));
    keyboardRef?.current?.setInput(value);
  }, [dispatch, keyboardRef, value]);

  const onInputChange = (value) => {
    if (inputName !== 'default') {
      dispatch(setKeyboardInput(value));
    }
  };

  const handleShift = () => {
    setLayout(layout === 'default' ? 'shift' : 'default');
  };

  const closeKeyboard = () => {
    dispatch(setKeyboardInputName('default'));
    setVisible(!visible);

    // klavye containerı tarafından gelen mouseLeave eventi çalıştıktan sonra işlemesi için timeout eklendi
    setTimeout(() => {
      setHover(true);
    }, 0);
  };

  return (
    <div className="backdrop-keyboard">
      <Draggable handle=".handle">
        <div
          className="keyboard-button-wrapper"
          onMouseEnter={() => setHandleHover(true)}
          onMouseLeave={() => setHandleHover(false)}
        >
          <Button
            className="keyboard-button"
            type="primary"
            size="large"
          >
            <span className="handle">{handleHover ? <DragOutlined /> : <KeyboardIcon />}</span>
            <span onClick={() => setVisible(!visible)}>Klavye</span>
          </Button>
        </div>
      </Draggable>

      <Draggable
        bounds="parent"
        defaultPosition={{ x: 865, y: -475 }}
      >
        <div
          className={`keyboard-container ${visible && 'active'} ${hover && 'keyboard-hover'}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Keyboard
            className="keyboard-component"
            mergeDisplay={true}
            layoutName={layout}
            display={display}
            layout={keyboardLayouts}
            buttonTheme={buttonTheme}
            theme="hg-theme-default"
            keyboardRef={(r) => (keyboardRef.current = r)}
            inputName={inputName}
            onChange={onInputChange}
          />

          <div className="caps-keyboard">
            <CloseOutlined
              className="close-icon"
              onClick={closeKeyboard}
            />

            <div
              title="Shift"
              className={`caps-button ${layout !== 'default' && 'active'}`}
              onClick={handleShift}
            >
              <KeyboardShift className="caps-icon" />
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
