import apiInstance from './api';

const articleGroupsApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getArticleGroups: build.query({
      query: () => ({
        url: '/article_groups',
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['ArticleGroups'],
    }),
  }),
  overrideExisting: true,
});
export default articleGroupsApi;
