import React from 'react';

import { Drawer, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from 'constant/themeSettings';
import { useWindowSize } from 'hooks';
import { useCommittees } from 'useSWR';
import { toggleCollapsedNav } from 'slices/commonSlice';

import SidebarContent from './SidebarContent';

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();

  const themeType = useSelector(({ settings }) => settings.themeType);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);

  const { width } = useWindowSize();
  const { committees, isLoading: committeesLoading } = useCommittees('all');

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedNav(!navCollapsed));
  };

  let drawerStyle = 'gx-collapsed-sidebar';

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = '';
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar gx-mini-custom-sidebar';
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = 'gx-custom-sidebar';
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar';
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = 'gx-collapsed-sidebar';
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = 'gx-collapsed-sidebar';
  }
  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR}
      theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
      collapsible
    >
      {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
        <Drawer
          className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={navCollapsed}
        >
          <SidebarContent
            committees={committees}
            committeesLoading={committeesLoading}
          />
        </Drawer>
      ) : (
        <SidebarContent
          committees={committees}
          committeesLoading={committeesLoading}
        />
      )}
    </Sider>
  );
};
export default Sidebar;
