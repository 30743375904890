import { useEffect, useState } from 'react';

import { Col, Row, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useCommitteeOpinions, usePullbackRequests } from 'useSWR';
import { DownButton } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

import { ArticleStatus, ArticleInfos, ArticleSteps } from './offer';
import { ContributionRequest, RequestList } from './offer/ArticleContribution';
import { PullbackResponse, PullbackRequests } from './offer/ArticlePullback';
const { TabPane } = Tabs;

const ArticleOfferDetail = ({ offer, process }) => {
  const [activeStep, setActiveStep] = useState('1');
  const { pullbackRequests } = usePullbackRequests({ id: offer?.id, step: 1 });
  const { opinions } = useCommitteeOpinions(offer?.id);
  const { memberships, isAdmin, isDAH, isAYK } = useUserInfo();

  const hasActivePullback = pullbackRequests?.some((req) => req.status === 0);
  const isCommitteeMember = memberships.some((item) => item.committee.id === offer?.ownerCommittee?.id && [0, 1].includes(item.type));
  const opinionsToDecide = opinions?.request?.filter((opinion) => !opinion.opinionDate);

  useEffect(() => {
    if (offer) {
      const { committeeStatus, dahStatus, aykStatus } = offer;
      if (committeeStatus === 7) {
        return setActiveStep('2');
      }
      if (dahStatus === 0) {
        return setActiveStep('3');
      }
      if (aykStatus === 0) {
        return setActiveStep('4');
      }
    }
  }, [offer]);

  return (
    <>
      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.detection.title" />
      </h2>

      <Row
        justify="center"
        align="middle"
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={18}
        >
          <ArticleSteps
            process={process}
            step={offer?.step}
            status={offer?.status}
            committeeStatus={offer.committeeStatus}
            dahStatus={offer.dahStatus}
            aykStatus={offer.aykStatus}
          />
        </Col>

        <Col xs={24}>
          <Tabs
            type="card"
            activeKey={activeStep}
            onTabClick={(key) => setActiveStep(key)}
            centered
            animated
            destroyInactiveTabPane
          >
            <TabPane
              key="1"
              tab={<FormattedMessage id="article.steps.ArticleOffer" />}
              disabled={!offer?.isOffer}
            >
              <Row
                justify="center"
                align="middle"
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={18}
                >
                  <ArticleInfos
                    offer={offer}
                    offerOldArticles={offer.oldArticles}
                    situation={'MTF'}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane
              key="2"
              tab={<FormattedMessage id="article.steps.ArticleDetection" />}
              data-cy="madde-tespit-section"
            >
              <Row className="gx-position-relative">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                >
                  <DownButton
                    title={
                      <FormattedMessage
                        id="article.detail.downButtonDecision"
                        values={{ to: 'Heyet' }}
                      />
                    }
                    section="heyetKarari"
                  />
                  <ArticleInfos
                    offer={offer}
                    offerOldArticles={offer.oldArticles}
                    situation={'committee'}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                  id="heyetKarari"
                >
                  <ArticleStatus
                    offer={offer}
                    situation={'committee'}
                    hasActivePullback={hasActivePullback}
                  />

                  {(isCommitteeMember || isAdmin) && (
                    <PullbackRequests
                      id={offer?.id}
                      committee="committee"
                      pullbackRequests={pullbackRequests}
                    />
                  )}

                  {offer?.committeeStatus === 2 && opinionsToDecide?.length > 0 && (
                    <ContributionRequest
                      offer={offer}
                      opinions={opinionsToDecide}
                    />
                  )}

                  {opinions?.decision?.length > 0 && <RequestList opinions={opinions?.decision} />}
                </Col>
              </Row>
            </TabPane>

            <TabPane
              key="3"
              tab={<FormattedMessage id="dah" />}
              disabled={offer?.step < 2 && offer?.status === 19}
            >
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                >
                  <DownButton
                    title={
                      <FormattedMessage
                        id="article.detail.downButtonDecision"
                        values={{ to: 'DAH' }}
                      />
                    }
                    section="dahKarari"
                  />
                  <ArticleInfos
                    offer={offer}
                    offerOldArticles={offer.oldArticles}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                  id="dahKarari"
                >
                  <ArticleStatus
                    offer={offer}
                    situation="dah"
                    hasActivePullback={hasActivePullback}
                  />

                  {(isAdmin || isDAH) && (
                    <>
                      <PullbackRequests
                        id={offer?.id}
                        committee="dah"
                        pullbackRequests={pullbackRequests}
                      />

                      <PullbackResponse
                        id={offer?.id}
                        committee="dah"
                        pullbackRequests={pullbackRequests}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </TabPane>

            <TabPane
              key="4"
              tab={<FormattedMessage id="ayk" />}
              disabled={offer?.step < 2 && offer?.status === 19}
            >
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                >
                  <DownButton
                    title={
                      <FormattedMessage
                        id="article.detail.downButtonDecision"
                        values={{ to: 'AYK' }}
                      />
                    }
                    section="aykKarari"
                  />
                  <ArticleInfos
                    offer={offer}
                    offerOldArticles={offer.oldArticles}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                  id="aykKarari"
                >
                  <ArticleStatus
                    offer={offer}
                    situation={'ayk'}
                    hasActivePullback={hasActivePullback}
                  />

                  {(isAdmin || isAYK) && (
                    <PullbackResponse
                      id={offer?.id}
                      committee="ayk"
                      pullbackRequests={pullbackRequests}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default ArticleOfferDetail;
