import { createContext, useContext } from 'react';

import { Form } from 'antd';

const FormListContext = createContext();

export const FormList = ({ children, name, ...props }) => {
  return (
    <FormListContext.Provider value={name}>
      <Form.List
        name={name}
        {...props}
      >
        {children}
      </Form.List>
    </FormListContext.Provider>
  );
};

export const useFormListContext = () => useContext(FormListContext);
