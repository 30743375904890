import { createContext, useContext } from 'react';

import { Form } from 'antd';

const FormItemContext = createContext();

export const FormItem = ({ children, name, ...props }) => {
  return (
    <FormItemContext.Provider value={name}>
      <Form.Item
        name={name}
        {...props}
      >
        {children}
      </Form.Item>
    </FormItemContext.Provider>
  );
};

export const useFormItemContext = () => useContext(FormItemContext);
