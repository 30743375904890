import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Radio, Row, Select, Spin, Checkbox, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAlertLeavingPage } from 'hooks';
import { useArticleTypes, useOffers, useCommittees } from 'useSWR';
import { editOffer, fetchSearchE1 } from 'fetcher';
import { openNotification, checkFormValidations, OFFER_STATUS } from 'utils';
import { CircularProgress, CustomUpload, Form, Input, RemoveButton, Title } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';
import { useGetArticleGroupsQuery } from 'services';

const { TextArea } = Input;
const { Option } = Select;

const EditArticleForm = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isLoading, canWhichCommitteeOffer } = useUserInfo();

  let timeCheck;

  const { committees, isLoading: loadingCommittee } = useCommittees('categoryOne');
  const { articleTypes, isLoading: loadingTypes } = useArticleTypes();
  const { data: articleGroups, isLoading: loadingGroups } = useGetArticleGroupsQuery();

  const { offer, isLoading: offerLoading } = useOffers(id);

  //Formdan ayılırken alert veren hook
  useAlertLeavingPage();
  const [searchResult, setSearchResult] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSearch = async (e, type = null) => {
    setFetching(true);
    clearTimeout(timeCheck);
    timeCheck = setTimeout(async () => {
      if (e.length >= 2) {
        try {
          const result = await fetchSearchE1(e, type ? type === 'Tam' : '');
          const options = await result['hydra:member'].map((item) => ({
            key: item.id,
            value: item.id,
            label: `${item.title} (${item.slug})`,
          }));

          setSearchResult(options);
          setFetching(false);
        } catch (e) {
          console.log(e);
        }
      }
    }, 500);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);

    try {
      const offer = checkFormValidations(values, true);
      const fileSizeSum = fileList.reduce((acc, file) => acc + file.size, 0);

      // throw if sum of file sizes are larger than 25 MB
      if (fileSizeSum > 25 * 1024 * 1024) {
        throw formatMessage({
          id: 'app.fileSizeExceeded',
        });
      }

      await editOffer(id, offer, fileList);

      openNotification({
        message: formatMessage({
          id: 'appModule.actionSuccessfully',
        }),
      });
      setTimeout(() => {
        mutate(`/article_offers/${id}`);
        navigate(`/madde-detay/${id}`);
        setButtonLoading(false);
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (offer?.media?.length > 0) {
      const files = offer.media.map(({ media }) => ({
        uid: media.id,
        name: media.originalFilename,
        size: media.filesize,
        status: 'done',
      }));
      setFileList(files);
    }
  }, [offer]);

  if (loadingCommittee || loadingTypes || loadingGroups || (id && offerLoading) || isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{offer.title + ' - ' + formatMessage({ id: 'article.form.detectionFormTitleEdit' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="article.form.detectionFormTitleEdit" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={20}
          xxl={20}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            scrollToFirstError
            initialValues={{
              maddeDurumu: OFFER_STATUS[offer?.status] ?? null,
              title: offer?.title ?? null,
              articleGroup: offer?.articleGroup?.['@id'] ?? null,
              articleType: offer?.articleTypeOther !== null ? 'Diğer' : offer?.articleType?.['@id'],
              committeeContributions:
                offer?.committeeContributions.map((item) => {
                  return {
                    committee: item.committee['@id'],
                    type: item.type,
                    other: item.other ?? null,
                    id: item['@id'],
                  };
                }) ?? null,
              birinciEdisyondaYerAldi: offer?.oldTitle !== null ? 'Evet' : 'Hayır' ?? null,
              atifTam: offer?.oldTitle?.type ? (offer?.oldTitle?.type === 'TAM' ? 'Tam' : 'Atıf') : null,
              oldArticleWorkType:
                offer.oldArticleWorkType.includes(4) || offer.oldArticleWorkType.includes(5) ? offer.oldArticleWorkType[0] : offer.oldArticleWorkType,
              rewriteandUpdate: offer?.oldArticleWorkType?.some((item) => item !== 0) ? 'Güncelleme' : 'Yeniden Yazım',
              oldTitle: {
                key: offer?.oldTitle?.id,
                value: offer?.oldTitle?.id,
                label: `${offer?.oldTitle?.title} (${offer?.oldTitle?.slug})`,
              },
              oldArticles: offer?.oldArticles?.map((item) => {
                return {
                  key: item.id,
                  value: item.id,
                  label: `${item.title} (${item.slug})`,
                };
              }),
              detectReason: offer?.detectReason,
              articleTypeOther: offer?.articleTypeOther,
            }}
          >
            <Card
              className="gx-mb-3"
              hoverable
            >
              <Form.Item
                name={'title'}
                label={<FormattedMessage id="article.form.OfferedArticleTitle" />}
                labelfor={'title'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Input name="title" />
              </Form.Item>
            </Card>

            <Card
              className="gx-mb-3"
              hoverable
            >
              <Form.Item
                name="articleGroup"
                labelfor={'articleGroup'}
                label={<FormattedMessage id="article.detail.articleGroup" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  {articleGroups?.map((item) => (
                    <Radio.Button
                      key={item.id}
                      value={item['@id']}
                    >
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Card>
            <Card
              className="gx-mb-3"
              hoverable
            >
              <Form.Item
                name="articleType"
                labelfor={'articleType'}
                label={<FormattedMessage id="article.detail.articleType" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group name="maddeTuru">
                  {articleTypes?.map((item) => (
                    <>
                      <Radio
                        key={item['@id']}
                        className="gx-mt-2"
                        value={item['@id']}
                      >
                        {item.name}
                      </Radio>
                      <br />
                    </>
                  ))}
                  <Radio
                    className="gx-mt-2"
                    value="Diğer"
                    data-cy="articleType-other"
                  >
                    <FormattedMessage id="article.detail.articleTypeOther" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item dependencies={['articleType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('articleType') === 'Diğer') {
                    return (
                      <Form.Item
                        name="articleTypeOther"
                        className="gx-mt-2"
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: 'appModule.requiredMessage',
                            }),
                          },
                          {
                            min: 100,
                            message: formatMessage({
                              id: 'article.reasonLengthMessage',
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          name="articleTypeOther"
                          showCount
                          data-cy="articleType-other-text"
                          placeholder={formatMessage({
                            id: 'article.form.OtherArticleTypePlaceholder',
                          })}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Card>

            <Card className="gx-mb-3 gx-p-0">
              <Form.Item
                name={'committeeContributions'}
                dependencies={['ownerCommittee']}
                label={<FormattedMessage id="article.form.contributionCommittee" />}
              >
                <Form.List name="committeeContributions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          className="gx-mx-3"
                          key={key}
                        >
                          <Form.Item
                            name={[name, 'committee']}
                            label={
                              <div className="gx-d-inline-flex gx-align-items-center">
                                <RemoveButton
                                  type="default"
                                  className="gx-mr-2"
                                  onClick={() => remove(name)}
                                  hideButtonText
                                />
                                <FormattedMessage id="committee" />
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: 'appModule.requiredMessage',
                                }),
                              },
                            ]}
                            {...restField}
                          >
                            <Select
                              name={'committee'}
                              data-cy="select-committee"
                              getPopupContainer={(triggerNode) => triggerNode.parentElement}
                              placeholder={formatMessage({
                                id: 'committees.Title',
                              })}
                              disabled={form.getFieldValue('committeeContributions')[name]?.id}
                            >
                              {committees?.map((item) => (
                                <Option
                                  key={item['@id']}
                                  value={item['@id']}
                                  data-cy={`select-committee-${item.id}`}
                                  disabled={
                                    canWhichCommitteeOffer.map((item) => item.committee['@id']).includes(item['@id']) ||
                                    offer.ownerCommittee['@id'] === item['@id'] ||
                                    form
                                      .getFieldValue('committeeContributions')
                                      .map((item) => item?.committee)
                                      .includes(item['@id'])
                                  }
                                >
                                  <FormattedMessage
                                    id="committees.detail.Title"
                                    values={{
                                      committee: item.name,
                                    }}
                                  />
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <div className="gx-mt-2 gx-mb-4 gx-ml-4">
                            <Form.Item
                              name={[name, 'type']}
                              label={<FormattedMessage id="article.form.committeeContributionWorkType" />}
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage({
                                    id: 'appModule.requiredMessage',
                                  }),
                                },
                              ]}
                              {...restField}
                            >
                              <Checkbox.Group>
                                <Space
                                  size={5}
                                  wrap
                                >
                                  <Checkbox value={0}>
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeOne" />
                                  </Checkbox>
                                  <Checkbox value={1}>
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeTwo" />
                                  </Checkbox>
                                  <Checkbox value={2}>
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeThree" />
                                  </Checkbox>
                                </Space>
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                        </div>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          className="gx-mt-2"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          data-cy="add-committee"
                        >
                          <FormattedMessage id="article.form.committeeContributionAddCommittee" />
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Card>

            <Card
              className="gx-mb-3"
              hoverable
            >
              <Form.Item
                name="birinciEdisyondaYerAldi"
                labelfor={'birinciEdisyondaYerAldi'}
                label={<FormattedMessage id="article.form.articleBeenEdisionOne" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="birinciEdisyondaYerAldi"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value="Evet"
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value="Hayır"
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                dependencies={['birinciEdisyondaYerAldi']}
                style={{
                  cursor: 'default',
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.atifTam" />}
                          labelfor="atifTam"
                          name="atifTam"
                          dependencies={['birinciEdisyondaYerAldi']}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() =>
                              setFieldsValue({
                                oldTitle: null,
                                oldArticleWorkType: null,
                                rewriteandUpdate: null,
                              })
                            }
                            name={'atifTam'}
                            buttonStyle="solid"
                          >
                            <Radio.Button
                              value="Tam"
                              data-cy="tam-article"
                            >
                              <FormattedMessage id="article.form.Tam" />
                            </Radio.Button>
                            <Radio.Button
                              value="Atıf"
                              data-cy="atif-article"
                            >
                              <FormattedMessage id="article.form.Atif" />
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
              <Form.Item dependencies={['atifTam', 'birinciEdisyondaYerAldi']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('atifTam') && getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.SearchEdisionOneLabel" />}
                          labelfor={'oldTitle'}
                          name={'oldTitle'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            labelInValue="hangiMadde"
                            name="hangiMadde"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, getFieldValue('atifTam'))}
                            style={{ display: 'block' }}
                            allowClear
                            showSearch
                            dropdownStyle={{ position: 'relative' }}
                            onClick={() => setSearchResult([])}
                            data-cy="search-e1-oldTitle"
                          >
                            {searchResult.map((d) => (
                              <Option
                                key={d.key}
                                value={d.key}
                              >
                                {d.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          dependencies={['atifTam', 'birinciEdisyondaYerAldi', 'rewriteandUpdate']}
                          className="gx-mt-2"
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('birinciEdisyondaYerAldi') === 'Evet' &&
                            getFieldValue('atifTam') === 'Tam' && (
                              <div>
                                <Form.Item
                                  label={<FormattedMessage id="article.form.oldArticleWorkType" />}
                                  labelfor="rewriteandUpdate"
                                  name="rewriteandUpdate"
                                  dependencies={['birinciEdisyondaYerAldi']}
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="Yeniden Yazım">
                                      <FormattedMessage id="article.formWorkToBeRewrite" />
                                    </Radio.Button>
                                    <Radio.Button value="Güncelleme">
                                      <FormattedMessage id="article.form.Update" />
                                    </Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )
                          }
                        </Form.Item>
                        <Form.Item
                          dependencies={['rewriteandUpdate', 'oldArticleWorkType']}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          {({ getFieldValue, setFieldsValue }) => {
                            const oldArticeWorkType = getFieldValue('oldArticleWorkType') ?? [];
                            const oldArticeWorkTypeLength = oldArticeWorkType.length > 0;
                            const isHaveZero = Array.isArray(oldArticeWorkType) && oldArticeWorkType?.includes(2);

                            if (getFieldValue('rewriteandUpdate') === 'Yeniden Yazım') {
                              setFieldsValue({ oldArticleWorkType: 0 });
                              return null;
                            }
                            if (getFieldValue('rewriteandUpdate') === 'Güncelleme' || getFieldValue('atifTam') === 'Atıf') {
                              return (
                                <Form.Item
                                  name="oldArticleWorkType"
                                  labelfor="oldArticleWorkType"
                                  label={getFieldValue('atifTam') === 'Atıf' && <FormattedMessage id="article.form.oldArticleWorkType" />}
                                  required
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  {getFieldValue('atifTam') === 'Atıf' ? (
                                    <Radio.Group>
                                      <Radio
                                        className="gx-mt-2"
                                        value={4}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToEncyclopedia" />
                                      </Radio>
                                      <br />
                                      <Radio
                                        className="gx-mt-2"
                                        value={5}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToGlossary" />
                                      </Radio>
                                    </Radio.Group>
                                  ) : (
                                    <Checkbox.Group>
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={2}
                                        data-cy="oldTitle-work-type-3"
                                        disabled={oldArticeWorkTypeLength && !isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeReview" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={1}
                                        data-cy="oldTitle-work-type-1"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeSupply" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={3}
                                        data-cy="oldTitle-work-type-4"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeCorrection" />
                                      </Checkbox>
                                    </Checkbox.Group>
                                  )}
                                </Form.Item>
                              );
                            }
                          }}
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>
            <Card
              className="gx-mb-3"
              hoverable
            >
              <Form.Item
                name="yakinIfadeZikrediliyorMu"
                labelfor={'yakinIfadeZikrediliyorMu'}
                label={<FormattedMessage id="article.form.NearstExpressionOldArticle" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
                initialValue={offer ? (offer?.oldArticles?.length > 0 ? 'Evet' : 'Hayır') : ''}
              >
                <Radio.Group
                  name="yakinIfadeZikrediliyorMu"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value="Evet"
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value="Hayır"
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item dependencies={['yakinIfadeZikrediliyorMu']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('yakinIfadeZikrediliyorMu') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                        <Form.Item
                          label={<FormattedMessage id="article.form.WhichOldArticles" />}
                          labelfor={'oldArticles'}
                          name={'oldArticles'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            name="yakinIfadeMaddeler"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, 'Tam')}
                            allowClear
                            showSearch
                            mode="multiple"
                            onClick={() => searchResult?.length !== 0 && setSearchResult([])}
                            options={
                              searchResult.length !== 0
                                ? searchResult
                                : offer?.oldArticles?.map((item) => {
                                    return {
                                      key: item?.id,
                                      value: item?.id,
                                      label: `${item?.title} (${item?.slug})`,
                                    };
                                  })
                            }
                            optionLabelProp="label"
                            optionFilterProp="label"
                            labelInValue
                          />
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>
            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <Form.Item
                name="detectReason"
                label={<FormattedMessage id="article.form.detectReason" />}
                labelfor={'detectReason'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  name="offerReason"
                  showCount
                  rows={5}
                  placeholder={formatMessage({
                    id: 'article.status.DecisionReasonPlaceHolder',
                  })}
                />
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <p>
                <FormattedMessage id="article.detail.media" />
              </p>
              <CustomUpload
                fileList={fileList}
                setFileList={setFileList}
              />
            </Card>

            <Button
              style={{ width: 100, float: 'right' }}
              type="primary"
              htmlType="submit"
              className="gx-mt-2"
              loading={buttonLoading}
              data-cy="save-button"
            >
              <FormattedMessage id="appModule.save" />
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EditArticleForm;
