import React, { useState } from 'react';

import { Button, Card, Col, Radio, Row, Select, Spin, Checkbox, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { mutate } from 'swr';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { useArticleTypes, useCommittees } from 'useSWR';
import { fetchSearchE1, sendOffers, FETCH_RESULTS } from 'fetcher';
import { useAlertLeavingPage } from 'hooks';
import { CircularProgress, CustomUpload, Form, Input, RemoveButton, Title } from 'components';
import { openNotification, checkFormValidations } from 'utils';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';
import { useGetArticleGroupsQuery } from 'services';

const { TextArea } = Input;
const { Option } = Select;

const ArticleDetectionForm = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { isAdmin, isLoading, canWhichCommitteeOffer } = useUserInfo();

  let timeCheck;
  const valuesFromLocalStorage = JSON.parse(sessionStorage.getItem('maddeTespitFormu'));
  //Formdan ayılırken alert veren hook
  useAlertLeavingPage();

  const { committees, isLoading: loadingCommittee } = useCommittees('categoryOne');
  const { articleTypes, isLoading: loadingTypes } = useArticleTypes();
  const { data: articleGroups, isLoading: loadingGroups } = useGetArticleGroupsQuery();

  const [searchResult, setSearchResult] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleSearch = async (e, type = null) => {
    setFetching(true);
    clearTimeout(timeCheck);
    timeCheck = setTimeout(async () => {
      if (e.length >= 2) {
        try {
          const result = await fetchSearchE1(e, type ? (type === 'Tam' ? true : false) : '');
          setFetching(true);
          setSearchResult(result['hydra:member']);
          setFetching(false);
        } catch (e) {
          console.log(e);
        }
      }
    }, 500);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const offer = checkFormValidations(values);
      const committeeID = values.ownerCommittee.split('/')[3];
      const fileSizeSum = fileList.reduce((acc, file) => acc + file.size, 0);

      // throw if sum of file sizes are larger than 25 MB
      if (fileSizeSum > 25 * 1024 * 1024) {
        throw formatMessage({
          id: 'appModule.fileSizeExceeded',
        });
      }

      const result = await sendOffers(offer, fileList);

      // Check if article created
      if (result.status !== FETCH_RESULTS.CREATED) {
        throw result.status;
      }

      sessionStorage.removeItem('maddeTespitFormu');
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        mutate(`/article_offers?&committee=${committeeID}&isDraft=true&itemsPerPage=20&order[]=null`);
        navigate(`/madde-teklifleri/${committeeID}/taslak-maddeler?&page=1`);
        setButtonLoading(false);
        setFileList([]);
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
      setButtonLoading(false);
    }
  };

  const saveFieldsLocalStorage = (changedFields, allFields) => {
    const touchedAllFields = allFields
      .filter((field) => {
        if (field?.name.includes('committeeContributions') && typeof field.value?.[0] !== 'object') {
          return false;
        }
        return field;
      })
      ?.map((data) => {
        return {
          name: data.name[0],
          value: data.value ?? null,
        };
      })
      ?.reduce(
        (obj, item) => (
          // eslint-disable-next-line
          (obj[item.name] = item.value), obj
        ),
        {}
      );

    sessionStorage.setItem('maddeTespitFormu', JSON.stringify(touchedAllFields));
  };

  if (loadingCommittee || loadingTypes || loadingGroups || isLoading) return <CircularProgress />;

  return (
    <>
      <Title>{formatMessage({ id: 'article.form.detectionFormTitle' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="article.form.detectionFormTitle" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={20}
          xxl={20}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            onFieldsChange={saveFieldsLocalStorage}
            initialValues={valuesFromLocalStorage}
            scrollToFirstError
          >
            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name={'ownerCommittee'}
                label={<FormattedMessage id="article.detail.ownerCommittee" />}
                required
                initialValue={!isAdmin ? (canWhichCommitteeOffer?.length === 1 ? canWhichCommitteeOffer[0]?.committee?.['@id'] : null) : null}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                {isAdmin ? (
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    placeholder={formatMessage({
                      id: 'committees.Title',
                    })}
                    name={'ownerCommittee'}
                    data-cy="ownerCommittee"
                  >
                    {committees?.map((item) => (
                      <Option
                        key={item['@id']}
                        value={item['@id']}
                        data-cy="arap-dili"
                      >
                        <FormattedMessage
                          id="committees.detail.Title"
                          values={{ committee: item.name }}
                        />
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    placeholder={formatMessage({
                      id: 'committees.Title',
                    })}
                    name={'ownerCommittee'}
                    disabled={canWhichCommitteeOffer?.length === 1}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    data-cy="ownerCommittee"
                  >
                    {canWhichCommitteeOffer?.map((item) => (
                      <Option
                        key={item.committee.id}
                        value={item.committee['@id']}
                        data-cy="arap-dili"
                      >
                        <FormattedMessage
                          id="committees.detail.Title"
                          values={{ committee: item.committee.name }}
                        />
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name={'title'}
                label={<FormattedMessage id="article.form.OfferedArticleTitle" />}
                labelfor={'title'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Input
                  // style={{ textTransform: 'uppercase' }}
                  name="title"
                  data-cy="title"
                />
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="articleGroup"
                labelfor={'articleGroup'}
                label={<FormattedMessage id="article.detail.articleGroup" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  buttonStyle="solid"
                  data-cy="group"
                >
                  {articleGroups?.map((item) => (
                    <Radio.Button
                      key={item.id}
                      value={item['@id']}
                    >
                      {item.name}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="articleType"
                labelfor={'articleType'}
                label={<FormattedMessage id="article.detail.articleType" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="maddeTuru"
                  data-cy="type"
                >
                  {articleTypes?.map((item) => (
                    <>
                      <Radio
                        key={item['@id']}
                        className="gx-mt-2"
                        value={item['@id']}
                        data-cy={`type-${item.id}`}
                      >
                        {item.name}
                      </Radio>
                      <br />
                    </>
                  ))}
                  <Radio
                    className="gx-mt-2"
                    value="Diğer"
                  >
                    <FormattedMessage id="article.detail.articleTypeOther" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item dependencies={['articleType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('articleType') === 'Diğer') {
                    return (
                      <Form.Item
                        name="articleTypeOther"
                        className="gx-mt-2"
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: 'appModule.requiredMessage',
                            }),
                          },
                          {
                            min: 100,
                            message: formatMessage({
                              id: 'article.reasonLengthMessage',
                            }),
                          },
                        ]}
                      >
                        <TextArea
                          name="articleTypeOther"
                          showCount
                          placeholder={formatMessage({
                            id: 'article.form.OtherArticleTypePlaceholder',
                          })}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Card>

            <Card style={{ marginBottom: 12, padding: 0 }}>
              <Form.Item
                label={<FormattedMessage id="article.form.contributionCommittee" />}
                labelfor={'committeeContributions'}
                name={'committeeContributions'}
                dependencies={['ownerCommittee']}
              >
                <Form.List name="committeeContributions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <React.Fragment key={key}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label={
                                  <>
                                    <RemoveButton
                                      type="default"
                                      className="gx-mr-2"
                                      onClick={() => remove(name)}
                                      hideButtonText
                                    />
                                    <FormattedMessage id="committee" />
                                  </>
                                }
                                labelfor={[name, 'committee']}
                                name={[name, 'committee']}
                                fieldKey={[fieldKey, 'committee']}
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: formatMessage({
                                      id: 'appModule.requiredMessage',
                                    }),
                                  },
                                ]}
                                className="gx-w-100"
                                dependencies={['committeeContributions']}
                              >
                                <Select
                                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                  placeholder={formatMessage({
                                    id: 'committees.Title',
                                  })}
                                  name={'committee'}
                                  data-cy={`select-committee`}
                                >
                                  {committees?.map((item) => (
                                    <Option
                                      key={item['@id']}
                                      value={item['@id']}
                                      data-cy={`select-committee-${item.id}`}
                                      disabled={
                                        canWhichCommitteeOffer.map((item) => item.committee['@id']).includes(item['@id']) ||
                                        form.getFieldValue('ownerCommittee') === item['@id'] ||
                                        form
                                          .getFieldValue('committeeContributions')
                                          .map((item) => item?.committee)
                                          .includes(item['@id'])
                                      }
                                    >
                                      <FormattedMessage
                                        id="committees.detail.Title"
                                        values={{
                                          committee: item.name,
                                        }}
                                      />
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>

                          <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                            <Form.Item
                              name={[name, 'type']}
                              labelfor={'type'}
                              label={<FormattedMessage id="article.form.committeeContributionWorkType" />}
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage({
                                    id: 'appModule.requiredMessage',
                                  }),
                                },
                              ]}
                              fieldKey={[fieldKey, 'type']}
                              {...restField}
                            >
                              <Checkbox.Group>
                                <Space
                                  size={[16, 6]}
                                  wrap
                                >
                                  <Checkbox
                                    value={0}
                                    data-cy="commitee-work-type-1"
                                  >
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeOne" />
                                  </Checkbox>
                                  <Checkbox
                                    value={1}
                                    data-cy="commitee-work-type-4"
                                  >
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeTwo" />
                                  </Checkbox>
                                  <Checkbox
                                    value={2}
                                    data-cy="commitee-work-type-4"
                                  >
                                    <FormattedMessage id="article.form.committeeContributionWorkTypeThree" />
                                  </Checkbox>
                                </Space>
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                          {/* <div className="gx-mb-3 gx-ml-5">
                            <Form.Item
                              name={[name, 'other']}
                              fieldKey={[fieldKey, 'other']}
                              {...restField}
                              dependencies={[[name, 'committeeContributions']]}
                              rules={[
                                {
                                  required: form
                                    .getFieldValue(['committeeContributions'])
                                    [fieldKey]?.type?.includes(5),
                                  message: formatMessage({
                                    id: 'appModule.requiredMessage',
                                  }),
                                },
                              ]}
                            >
                              <TextArea
                                showCount
                                maxLength={100}
                                placeholder={formatMessage({
                                  id: 'article.form.committeeContributionWorkTypeOther',
                                })}
                                disabled={
                                  !form
                                    .getFieldValue(['committeeContributions'])
                                    [fieldKey]?.type?.includes(5)
                                }
                                data-cy="commitee-work-type-other-text"
                              />
                            </Form.Item>
                          </div> */}
                        </React.Fragment>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          className="gx-mt-2"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          data-cy="add-committee"
                        >
                          {' '}
                          <FormattedMessage id="article.form.committeeContributionAddCommittee" />
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="birinciEdisyondaYerAldi"
                labelfor={'birinciEdisyondaYerAldi'}
                label={<FormattedMessage id="article.form.articleBeenEdisionOne" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="birinciEdisyondaYerAldi"
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value="Evet"
                    data-cy="contain-first-edicion-yes"
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value="Hayır"
                    data-cy="contain-first-edicion-no"
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                dependencies={['birinciEdisyondaYerAldi']}
                style={{
                  cursor: 'default',
                }}
              >
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.atifTam" />}
                          labelfor="atifTam"
                          name="atifTam"
                          dependencies={['birinciEdisyondaYerAldi']}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={() =>
                              setFieldsValue({
                                oldTitle: null,
                                oldArticleWorkType: null,
                                rewriteandUpdate: null,
                              })
                            }
                            name={'atifTam'}
                            buttonStyle="solid"
                          >
                            <Radio.Button
                              value="Tam"
                              data-cy="tam-article"
                            >
                              <FormattedMessage id="article.form.Tam" />
                            </Radio.Button>
                            <Radio.Button
                              value="Atıf"
                              data-cy="atif-article"
                            >
                              <FormattedMessage id="article.form.Atif" />
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
              <Form.Item dependencies={['atifTam', 'birinciEdisyondaYerAldi']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('atifTam') && getFieldValue('birinciEdisyondaYerAldi') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5">
                        <Form.Item
                          label={<FormattedMessage id="article.form.SearchEdisionOneLabel" />}
                          labelfor={'oldTitle'}
                          name={'oldTitle'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            labelInValue="hangiMadde"
                            name="hangiMadde"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, getFieldValue('atifTam'))}
                            style={{ display: 'block' }}
                            allowClear
                            showSearch
                            dropdownStyle={{ position: 'relative' }}
                            onClick={() => setSearchResult([])}
                            data-cy="search-e1-oldTitle"
                          >
                            {searchResult.map((d) => (
                              <Option
                                key={d.id}
                                value={d.id}
                              >
                                {d.title} ({d.slug})
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          dependencies={['atifTam', 'birinciEdisyondaYerAldi', 'rewriteandUpdate']}
                          className="gx-mt-2"
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('birinciEdisyondaYerAldi') === 'Evet' &&
                            getFieldValue('atifTam') === 'Tam' && (
                              <div>
                                <Form.Item
                                  label={<FormattedMessage id="article.form.oldArticleWorkType" />}
                                  labelfor="rewriteandUpdate"
                                  name="rewriteandUpdate"
                                  dependencies={['birinciEdisyondaYerAldi']}
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="Yeniden Yazım">
                                      <FormattedMessage id="article.formWorkToBeRewrite" />
                                    </Radio.Button>
                                    <Radio.Button value="Güncelleme">
                                      <FormattedMessage id="article.form.Update" />
                                    </Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            )
                          }
                        </Form.Item>
                        <Form.Item dependencies={['rewriteandUpdate', 'oldArticleWorkType']}>
                          {({ getFieldValue, setFieldsValue }) => {
                            const oldArticleWorkType = getFieldValue('oldArticleWorkType') ?? [];
                            const oldArticleWorkTypeLength = oldArticleWorkType.length > 0;
                            const isHaveZero = Array.isArray(oldArticleWorkType) && oldArticleWorkType?.includes(2);

                            if (getFieldValue('rewriteandUpdate') === 'Yeniden Yazım') {
                              setFieldsValue({ oldArticleWorkType: 0 });
                              return null;
                            }

                            if (getFieldValue('rewriteandUpdate') === 'Güncelleme' || getFieldValue('atifTam') === 'Atıf') {
                              return (
                                <Form.Item
                                  name="oldArticleWorkType"
                                  labelfor="oldArticleWorkType"
                                  label={getFieldValue('atifTam') === 'Atıf' && <FormattedMessage id="article.form.oldArticleWorkType" />}
                                  rules={[
                                    {
                                      required: true,
                                      message: formatMessage({
                                        id: 'appModule.requiredMessage',
                                      }),
                                    },
                                  ]}
                                >
                                  {getFieldValue('atifTam') === 'Atıf' ? (
                                    <Radio.Group>
                                      <Radio
                                        className="gx-mt-2"
                                        value={4}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToEncyclopedia" />
                                      </Radio>
                                      <br />
                                      <Radio
                                        className="gx-mt-2"
                                        value={5}
                                      >
                                        <FormattedMessage id="article.formWorkToBeChangeToGlossary" />
                                      </Radio>
                                    </Radio.Group>
                                  ) : (
                                    <Checkbox.Group>
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={2}
                                        data-cy="oldTitle-work-type-3"
                                        disabled={oldArticleWorkTypeLength && !isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeReview" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={1}
                                        data-cy="oldTitle-work-type-1"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeSupply" />
                                      </Checkbox>
                                      <br />
                                      <Checkbox
                                        className="gx-mt-2"
                                        value={3}
                                        data-cy="oldTitle-work-type-4"
                                        disabled={isHaveZero}
                                      >
                                        <FormattedMessage id="article.formWorkToBeCorrection" />
                                      </Checkbox>
                                    </Checkbox.Group>
                                  )}
                                </Form.Item>
                              );
                            }
                          }}
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0 }}
              hoverable
            >
              <Form.Item
                name="yakinIfadeZikrediliyorMu"
                labelfor={'yakinIfadeZikrediliyorMu'}
                label={<FormattedMessage id="article.form.NearstExpressionOldArticle" />}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                ]}
              >
                <Radio.Group
                  name="yakinIfadeZikrediliyorMu"
                  buttonStyle="solid"
                  data-cy="is-have-oldArticle"
                >
                  <Radio.Button
                    className="gx-mt-2"
                    value="Evet"
                    data-cy="is-have-oldArticle-yes"
                  >
                    <FormattedMessage id="appModule.yes" />
                  </Radio.Button>
                  <Radio.Button
                    className="gx-mt-2"
                    value="Hayır"
                    data-cy="is-have-oldArticle-no"
                  >
                    <FormattedMessage id="appModule.no" />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item dependencies={['yakinIfadeZikrediliyorMu']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('yakinIfadeZikrediliyorMu') === 'Evet') {
                    return (
                      <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                        <Form.Item
                          label={<FormattedMessage id="article.form.WhichOldArticles" />}
                          labelfor={'oldArticles'}
                          name={'oldArticles'}
                          rules={[
                            {
                              required: true,
                              message: formatMessage({
                                id: 'appModule.requiredMessage',
                              }),
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            labelInValue="yakinIfadeMaddeler"
                            name="yakinIfadeMaddeler"
                            placeholder={formatMessage({
                              id: 'article.form.SearchEdisionOnePlaceholder',
                            })}
                            loading={fetching}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(e) => handleSearch(e, 'Tam')}
                            allowClear
                            showSearch
                            dropdownStyle={{ position: 'relative' }}
                            mode="multiple"
                            onClick={() => setSearchResult([])}
                            data-cy="oldArticles-e1"
                          >
                            {searchResult.map((d) => (
                              <Option key={d.id}>
                                {d.title} ({d.slug})
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <Form.Item
                name="detectReason"
                label={<FormattedMessage id="article.form.detectReason" />}
                labelfor={'detectReason'}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'appModule.requiredMessage',
                    }),
                  },
                  {
                    min: 100,
                    message: formatMessage({
                      id: 'article.reasonLengthMessage',
                    }),
                  },
                ]}
              >
                <TextArea
                  showCount
                  rows={5}
                  placeholder={formatMessage({
                    id: 'article.status.DecisionReasonPlaceHolder',
                  })}
                  data-cy="detectReason"
                />
              </Form.Item>
            </Card>

            <Card
              style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
              hoverable
            >
              <p>
                <FormattedMessage id="article.detail.media" />
              </p>
              <CustomUpload
                fileList={fileList}
                setFileList={setFileList}
              />
            </Card>

            <Button
              style={{ width: 100, float: 'right' }}
              type="primary"
              htmlType="submit"
              className="gx-mt-2"
              loading={buttonLoading}
              data-cy="save-form"
            >
              <FormattedMessage id="appModule.save" />
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ArticleDetectionForm;
