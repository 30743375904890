import { useEffect } from 'react';

import { Button, Card, Descriptions, Radio, Select, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, useFormState } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { articleLength } from 'utils';
import { Form, Input } from 'components';

const { Option } = Select;
const { TextArea } = Input;

export default ({ offer, committee, articleGroups, articleTypes, loading, editable, setVersionUrl, versionUrl, setEditable, setChangeValueStep, sendForm }) => {
  const { formatMessage } = useIntl();
  const { control, handleSubmit, getValues, setValue, watch } = useForm({
    defaultValues: {
      title: offer.title,
      articleCode: offer.articleCodeFormatted,
      articleType: offer.articleType?.['@id'] || 'other',
      articleTypeOther: offer.articleTypeOther,
      committee: offer.ownerCommittee?.name,
      contributors: offer.committeeContributions?.map((c) => c.committee),
      articleGroup: offer.articleGroup?.['@id'],
      articleSupervisor: offer.articleSupervisor?.['@id'],
      articleLength: offer.articleGroup?.['@id'].includes('2') ? 0 : offer.articleLength,
      articleLengthReason: offer.articleLengthReason,
    },
  });
  const { isDirty, errors } = useFormState({ control });

  useEffect(() => {
    setChangeValueStep(isDirty ? '1' : '');
  }, [setChangeValueStep, isDirty]);

  return (
    <Form
      form={{ getValues, setValue }}
      onFinish={handleSubmit(sendForm)}
      layout="vertical"
    >
      <Card className="gx-mb-3">
        <Form.Item label={formatMessage({ id: 'article.detail.articleCode' })}>
          <Form.Controller
            name="articleCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                disabled
              />
            )}
          />
        </Form.Item>
      </Card>

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({ id: 'article.detail.articleTitle' })}
          required
        >
          <Form.Controller
            name="title"
            hasValue={watch('title')}
            rules={{
              required: formatMessage(
                { id: 'appModule.requiredFieldMessage' },
                {
                  field: formatMessage({
                    id: 'article.detail.articleTitle',
                  }),
                }
              ),
            }}
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  disabled
                />
                <ErrorMessage
                  errors={errors}
                  name="title"
                  as="p"
                  className="error-message"
                />
              </>
            )}
          />
        </Form.Item>
      </Card>

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({ id: 'committee' })}
          required
        >
          <Form.Controller
            name="committee"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasValue={watch('committee')}
                disabled
              />
            )}
          />
        </Form.Item>
      </Card>

      <Card className="gx-mb-3">
        <Form.Item label={formatMessage({ id: 'article.detail.articleGroup' })}>
          <Form.Controller
            name="articleGroup"
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                hasValue={watch('articleGroup')}
                buttonStyle="solid"
                disabled
              >
                {articleGroups.map((group) => (
                  <Radio.Button
                    value={group['@id']}
                    key={group.id}
                  >
                    {group.name}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          />
        </Form.Item>
      </Card>

      {offer.committeeContributions.length > 0 && (
        <Card className="gx-mb-3">
          <Descriptions
            layout="horizontal"
            column={12}
            bordered
          >
            <Descriptions.Item
              labelStyle={{ width: '20%' }}
              label={<FormattedMessage id="article.detail.contributionCommittees" />}
            >
              {offer.committeeContributions.map((c) => c.committee.name).join(' İlim Heyeti, ')}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({ id: 'article.detail.articleType' })}
          required
        >
          <Form.Controller
            name="articleType"
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                hasValue={watch('articleType')}
                disabled
              >
                <Space direction="vertical">
                  {articleTypes?.map((item) => (
                    <Radio
                      key={item.id}
                      value={item['@id']}
                    >
                      {item.name}
                    </Radio>
                  ))}
                  <Radio
                    value="other"
                    className="gx-mb-3"
                  >
                    <FormattedMessage id="article.detail.articleTypeOther" />
                  </Radio>
                </Space>
              </Radio.Group>
            )}
          />
        </Form.Item>

        {getValues('articleType') === 'other' && (
          <Form.Controller
            name="articleTypeOther"
            control={control}
            rules={{
              required: formatMessage(
                { id: 'appModule.requiredFieldMessage' },
                {
                  field: formatMessage({
                    id: 'article.detail.articleTypeOther',
                  }),
                }
              ),
              minLength: {
                value: 100,
                message: formatMessage({ id: 'article.reasonLengthMessage' }),
              },
            }}
            render={({ field }) => (
              <>
                <TextArea
                  {...field}
                  className="gx-m-0"
                  rows={3}
                  disabled
                  showCount
                />

                <ErrorMessage
                  errors={errors}
                  name="articleTypeOther"
                  as="p"
                  className="error-message"
                />
              </>
            )}
          />
        )}
      </Card>

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({ id: 'article.detail.articleSupervisor' })}
          required
        >
          <Form.Controller
            name="articleSupervisor"
            control={control}
            rules={{
              required: formatMessage(
                { id: 'appModule.requiredFieldMessage' },
                {
                  field: formatMessage({
                    id: 'article.detail.articleSupervisor',
                  }),
                }
              ),
            }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    hasValue={watch('articleSupervisor')}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    placeholder="İlim Heyeti Üyesi Seçin"
                    disabled={!editable}
                    loading={loading}
                  >
                    {committee &&
                      committee.members.map(({ user }) => (
                        <Option
                          key={user.id}
                          value={user['@id']}
                        >
                          {user.fullName}
                        </Option>
                      ))}
                  </Select>

                  <ErrorMessage
                    errors={errors}
                    name="articleSupervisor"
                    as="p"
                    className="error-message"
                  />
                </>
              );
            }}
          />
        </Form.Item>
      </Card>

      <Card className="gx-mb-3">
        <Form.Item
          label={formatMessage({ id: 'article.detail.articleLength' })}
          required
        >
          <Form.Controller
            name="articleLength"
            control={control}
            rules={
              !getValues('articleGroup')?.includes('2') && {
                required: formatMessage(
                  { id: 'appModule.requiredFieldMessage' },
                  {
                    field: formatMessage({
                      id: 'article.detail.articleLength',
                    }),
                  }
                ),
              }
            }
            render={({ field }) => (
              <>
                <Radio.Group
                  {...field}
                  hasValue={watch('articleLength')}
                  buttonStyle="solid"
                  disabled={!editable}
                >
                  <Radio.Button
                    value={0}
                    disabled={getValues('articleGroup')?.includes('1')}
                  >
                    {articleLength[0]}
                  </Radio.Button>

                  {articleLength.slice(1).map((item, idx) => (
                    <Radio.Button
                      value={idx + 1}
                      key={idx}
                      disabled={getValues('articleGroup')?.includes('2')}
                    >
                      {item}
                    </Radio.Button>
                  ))}
                </Radio.Group>

                <ErrorMessage
                  errors={errors}
                  name="articleLength"
                  as="p"
                  className="error-message"
                />
              </>
            )}
          />
        </Form.Item>
      </Card>

      {!getValues('articleGroup')?.includes('2') && (
        <Card className="gx-mb-3">
          <Form.Item
            label={formatMessage({ id: 'article.detail.articleLengthReason' })}
            className="gx-mb-2"
            required
          >
            <Form.Controller
              name="articleLengthReason"
              control={control}
              rules={{
                required: formatMessage(
                  { id: 'appModule.requiredFieldMessage' },
                  {
                    field: formatMessage({
                      id: 'article.detail.articleLengthReason',
                    }),
                  }
                ),
                minLength: {
                  value: 100,
                  message: formatMessage({ id: 'article.reasonLengthMessage' }),
                },
              }}
              render={({ field }) => (
                <TextArea
                  {...field}
                  rows={5}
                  showCount
                  disabled={!editable}
                />
              )}
            />
          </Form.Item>

          <ErrorMessage
            errors={errors}
            name="articleLengthReason"
            as="p"
            className="error-message"
          />
        </Card>
      )}

      {editable && (
        <div className="gx-d-flex gx-justify-content-end gx-mt-4 gx-mb-3">
          {versionUrl === 'current' && (
            <Button
              disabled={loading}
              onClick={() => {
                setEditable(false);
                setVersionUrl('');
              }}
            >
              <FormattedMessage id="appModule.cancel" />
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            <FormattedMessage id="appModule.saveAndContinue" />
          </Button>
        </div>
      )}
    </Form>
  );
};
