import { Steps } from 'antd';
import { FileDoneOutlined, FileSyncOutlined, SolutionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export default ({ process, offer, setProcess }) => {
  const { Step } = Steps;

  const steps = ['detection', 'content', 'order', 'publish'];

  function stepStatus(step) {
    if (step === 0 && offer.step === 0) {
      return { name: 'process', icon: <FileSyncOutlined /> };
    }

    if (step + 1 < offer.step) {
      return { name: 'finished', icon: <FileDoneOutlined /> };
    } else if (step + 1 > offer.step) {
      return { name: 'wait', icon: <SolutionOutlined /> };
    }

    return { name: 'process', icon: <FileSyncOutlined /> };
  }

  return (
    <Steps
      className="gx-mt-3 gx-mb-5"
      labelPlacement="vertical"
      responsive
    >
      {steps.map((step, idx) => (
        <Step
          key={step}
          status={stepStatus(idx).name}
          title={
            <span
              className={classNames({
                'gx-text-underline': process - 1 === idx,
              })}
            >
              <FormattedMessage id={`article.process.${step}`} />
            </span>
          }
          icon={stepStatus(idx).icon}
          className={classNames({
            'completed-step': stepStatus(idx).name === 'finished',
            clickable: idx + 1 <= offer.step && ![3, 4].includes(idx),
          })}
          onClick={() => ![3, 4].includes(idx) && idx + 1 <= offer.step && setProcess(idx + 1)}
        />
      ))}
    </Steps>
  );
};
