import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { CircularProgress } from 'components';
import { Title } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

const Home = () => {
  const { isAdmin, isLoading, canOffer } = useUserInfo();

  if (isLoading) return <CircularProgress />;

  /* Müşteri isteğiyle bu şekilde yapıldı. İlerde müşterinin isteğiyle değişiklik yapılabilir. */
  return (
    <Card>
      <Title>Anasayfa</Title>
      <div className="home">
        <h1
          style={{ lineHeight: '150%' }}
          className="gx-h1-lg gx-font-weight-medium"
        >
          TDV İslam Ansiklopedisi İkinci Edisyon <br /> İçerik Yönetim Sistemi'ne Hoş Geldiniz!
        </h1>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">Madde Tespit Formu</h2>
        <p>
          <span>
            İlim heyetleri adına <u>heyet sekreterleri</u> yeni madde girişlerini “Yeni Madde Teklifi /&nbsp;
          </span>
          {canOffer || isAdmin ? (
            <Link to="/yeni-madde-teklifi/madde-tespit-formu">
              <span>Madde Tespit Formu</span>
            </Link>
          ) : (
            <span>Madde Tespit Formu</span>
          )}
          <span>” menüsünü kullanarak yapabilirler.</span>
        </p>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">Madde Teklifleri</h2>
        <p>
          <u>
            <span>Heyet üyeleri</span>
          </u>
          <span>&nbsp;kendi ilim heyetlerini ilgilendiren maddelere ilişkin teklif/tespit süreçlerini&nbsp;</span>
          <Link to="/madde-teklifleri/tum-maddeler">
            <span>Madde Teklifleri</span>
          </Link>
          <span>&nbsp;menüsünden takip edebilirler.</span>
        </p>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">Heyet Listeleri</h2>
        <p>
          <span>Ansiklopedi Yayın Kurulu, Disiplinlerarası Heyet ve tüm ilim heyetlerinin mensuplarına dair bilgilere&nbsp;</span>
          <Link to="/heyetler/ilim-heyetleri">
            <span>Heyet Listeleri</span>
          </Link>
          <span>&nbsp;menüsünden ulaşabilirsiniz.</span>
        </p>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">E-Kaynaklar</h2>
        <p>
          <span>
            TDV İslam Ansiklopedisi İkinci Edisyon ve İngilizce İslam Ansiklopedisi çalışmalarını desteklemek amacıyla oluşturulan E-Kaynaklar sayfasına&nbsp;
            <a
              href="https://ekaynaklar.islamansiklopedisi.org.tr"
              target="_blank"
              rel="noreferrer"
            >
              E-Kaynaklar
            </a>
          </span>

          <span>&nbsp;adresinden erişebilirsiniz.</span>
        </p>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">İYS Kullanım Kılavuzu</h2>
        <p>
          <span>
            {' '}
            İçerik Yönetim Sistemi'nin tanıtımı, formların nasıl doldurulacağı ve menülerin nasıl kullanılacağı konusunda detaylı bilgi alabileceğiniz bu
            kılavuza{' '}
            <a
              href="https://ekaynaklar.islamansiklopedisi.org.tr"
              target="_blank"
              rel="noreferrer"
            >
              E-Kaynaklar
            </a>{' '}
            sayfasından erişebilirsiniz.
          </span>
        </p>
        <br />
        <h2 className="gx-h2-lg gx-font-weight-medium">Diğer Kılavuz ve Yönergeler</h2>
        <p>
          <span>
            TDV İslam Ansiklopedisi İkinci Edisyon İçerik Yönetim Sistemi'nin (İYS) kullanımı aşamasında ve daha sonra maddelerin telif-redaksiyon aşamalarında
            müracaat edilmek üzere Ansiklopedi Koordinatörlüğü tarafından hazırlanan diğer kılavuz ve yönergeler de kullanıma hazır hale geldikçe{' '}
            <a
              href="https://ekaynaklar.islamansiklopedisi.org.tr"
              target="_blank"
              rel="noreferrer"
            >
              E-Kaynaklar
            </a>{' '}
            sayfasından erişime açılacaktır.
          </span>
        </p>
      </div>
    </Card>
  );
};

export default Home;
