import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

import { getCookies, removeCookies, setCookies } from '../fetcher/tokenHandler';

const baseURL = `${process.env.REACT_APP_PUBLIC_BASE_URL}/api`;

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    const { token, xSwitchUser } = getCookies();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    if (xSwitchUser) {
      headers.set('x-switch-user', xSwitchUser);
    }
    return headers;
  },
});

const apiInstance = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extra) => {
    let result = baseQuery(args, api, extra);
    const unauthError = result.error && result.error.status === 401;
    const { token, refreshToken } = getCookies();
    if (unauthError && token === undefined && !refreshToken === undefined) {
      window.location.href = '/giris';
    } else if (unauthError && token && refreshToken === undefined) {
      removeCookies();
      location.href = '/giris';
    } else if (unauthError && refreshToken) {
      const rs = await fetch(`${baseURL}/refresh_token `, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken,
        }),
      });
      if (rs.status === 201) {
        const response = await rs.json();
        const { token, refreshToken: newRs } = response.data;
        setCookies(token, newRs);
        result = baseQuery(args, api, extra);
      } else {
        removeCookies();
        location.href = '/giris';
      }
    }
    return result;
  },
  endpoints: () => ({}),
  tagTypes: [
    'Activities',
    'AcedemicTitles',
    'AuthorArticles',
    'AuthorTasks',
    'OrderRevertReasons',
    'Projects',
    'Roles',
    'StiplatedTimes',
    'AuthorWorks',
    'CommitteeOpinions',
    'Version',
    'CommitteeRevertReasons',
    'ArticleGroups',
  ],
});

export default apiInstance;
