import apiInstance from './api';

const academicTitleApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getAcademicTitles: builder.query({
      query: () => ({
        url: '/author_academic_titles',
        method: 'GET',
      }),
      transformResponse: (response) => response['hydra:member'],

      providesTags: ['AcedemicTitles'],
    }),
  }),
  overrideExisting: true,
});

export default academicTitleApi;
