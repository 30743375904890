import { Card } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import { fileDownload } from 'fetcher';
import { downloadBlob } from 'utils';
import { CircularProgress } from 'components/CircularProgress';

export const OrderAgreement = ({ offer }) => {
  const { data: content, error } = useSWR(`/contracts/contract/${offer?.id}/content`);

  async function download() {
    const file = await fileDownload(`/contracts/contract/${offer?.id}`);
    downloadBlob(file.data, 'Taahhut_Sozlesmesi.pdf');
  }

  if (!error && !content) return <CircularProgress />;

  return (
    <div>
      <h2 className="gx-mb-4">
        <FormattedMessage id="article.order.agreementTitle" />
      </h2>

      <Card className="gx-p-0 gx-mb-3">
        <iframe
          className="gx-w-100 gx-border-0"
          srcDoc={content?.html}
          height={250}
        />
      </Card>

      <div className="gx-d-flex gx-justify-content-between gx-mb-4">
        <div className="gx-d-flex gx-align-items-center">
          <FilePdfOutlined className="gx-fs-xxxl" />

          <div className="gx-d-flex gx-flex-column gx-ml-3">
            <p className="gx-m-0">
              <FormattedMessage id="article.order.downloadAgreement" />
            </p>
            <p
              className="gx-m-0 gx-font-weight-medium gx-text-primary clickable"
              onClick={download}
            >
              Taahhut_Sozlesmesi.pdf
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
