import React, { useEffect, useState } from 'react';

import { Menu, Tooltip } from 'antd';
import {
  TableOutlined,
  UserAddOutlined,
  FormOutlined,
  UserOutlined,
  UserSwitchOutlined,
  GroupOutlined,
  MenuOutlined,
  CopyOutlined,
  PlusCircleOutlined,
  FileTextOutlined,
  FolderAddOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, CustomScrollbars } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

import SidebarLogo from './SidebarLogo';
import SubMenus from './SubMenus';

const { SubMenu } = Menu;
const rootSubmenuKeys = [
  'yeni-madde-teklifi',
  'madde-teklifleri',
  'maddelerim',
  'uye-islemleri',
  'yazar-islemleri',
  'heyetler',
  'e-kaynaklar',
  'loglar',
  'logout',
];

const subKeys = ['teklif-edilen-maddeler', 'taslak-maddeler', 'sorumlu-olunan-maddeler', 'katki-verilecek-maddeler', 'heyetten-cikan-maddeler', 'heyet-detay'];

const SidebarContent = ({ committees, committeesLoading }) => {
  let location = useLocation();

  const { pathname } = location;

  const { isAdmin, isSuperAdmin, canOffer, memberships, isResourceManager, isLoading, isEncyclopedia, isManager, isAuthor } = useUserInfo();

  const selectedKeys = pathname.substr(1);
  let defaultOpenKeys = selectedKeys.split('/');
  const defaultOpenKeysSub = defaultOpenKeys.slice(1).join('/').split();

  const [openKeys, setOpenKeys] = useState();
  const [openKeysSub, setOpenKeysSub] = useState();

  useEffect(() => {
    const selectedKeys = location.pathname.substr(1);
    const openKeys = selectedKeys.split('/');
    setOpenKeys(openKeys);
  }, [location]);

  if (isLoading || committeesLoading) return <CircularProgress />;

  let subRootSubMenuKeys = [];

  const onOpenChange = (keys, type) => {
    const keysObj = {
      openedKeys: type === 'root' ? openKeys : openKeysSub,
      menuArray: type === 'root' ? rootSubmenuKeys : subRootSubMenuKeys,
    };
    const handleKeys = (keys) => {
      if (type === 'rootSingle') {
        setOpenKeys(keys);
        setOpenKeysSub([]);
      }
      if (type === 'subSingle') {
        setOpenKeysSub(keys);
        setOpenKeys(['madde-teklifleri']);
      }
      if (type === 'root') {
        setOpenKeys(keys);
      }
      if (type === 'sub') {
        setOpenKeysSub(keys);
      }
    };

    if (['root', 'sub'].includes(type)) {
      const latestOpenKey = keys.find((key) => !keysObj.openedKeys?.includes(key));

      if (!keysObj.menuArray.includes(latestOpenKey)) {
        handleKeys(keys);
      } else {
        handleKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    } else {
      handleKeys(keys);
    }
  };

  const setDefaultOpenKeys = () => {
    let isSub = false;
    subKeys.forEach((key) => {
      if (defaultOpenKeys.includes(key)) {
        isSub = true;
      }
    });

    if (isSub) {
      defaultOpenKeys = [defaultOpenKeys[0], `/${defaultOpenKeys[0]}/${defaultOpenKeys[1]}`, `${defaultOpenKeys[1]}/${defaultOpenKeys[2]}`];
    }
  };

  if ((isAdmin || isEncyclopedia) && committees) {
    subRootSubMenuKeys = committees.map((item) => '' + item.id);
    subRootSubMenuKeys = [...subRootSubMenuKeys, 'tum-teklifler'];
  }
  if (!isAdmin && memberships) {
    subRootSubMenuKeys = memberships?.map((item) => item.committee.id);
  }

  setDefaultOpenKeys();

  return (
    <>
      <div className="gx-d-flex gx-flex-column h-100">
        <SidebarLogo />
        <div className="gx-sidebar-content">
          {/* <div
            className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
          >
            <UserProfile navStyle={navStyle} />
          </div> */}
          <div className="gx-d-flex gx-flex-column gx-flex-1">
            <CustomScrollbars className="gx-layout-sider-scrollbar-sidebar">
              <Menu
                className="gx-d-flex gx-flex-column gx-h-100 sidebarItemPositions"
                theme="dark"
                mode="inline"
                onOpenChange={(e) => onOpenChange(e, 'root')}
                onSelect={({ keyPath }) => {
                  onOpenChange(keyPath, 'rootSingle');
                }}
                defaultOpenKeys={defaultOpenKeys}
                defaultSelectedKeys={defaultOpenKeys}
                {...(openKeys && { openKeys })}
                {...(openKeys && { selectedKeys: openKeys })}
              >
                {(isAdmin || isResourceManager || isEncyclopedia) && (
                  <Menu.Item
                    icon={<CopyOutlined className="iconPositions" />}
                    key="e-kaynaklar"
                  >
                    <Link to="/e-kaynaklar">
                      <FormattedMessage id="resources.Title" />
                    </Link>
                  </Menu.Item>
                )}
                {(isAdmin || isResourceManager || isEncyclopedia) && (
                  <Menu.Item
                    icon={<CopyOutlined className="iconPositions" />}
                    key="sabit-sayfalar"
                  >
                    <Link to="/sabit-sayfalar">Sabit Sayfalar</Link>
                  </Menu.Item>
                )}

                {
                  /*antd menu componentinde, children olarak başka bir component eklendiğinde active classı bu componentte hep duruyor dinamik olmuyor bu sebeple if else bloğunda tooltip ile böyle bir geliştirme yapıldı */
                  !canOffer ? (
                    <Tooltip
                      title={
                        'Bu alan şu anda sadece ilim heyeti başkan ve sekreterleri tarafından "Madde Tespit Formu" ile madde teklifi yapmak için kullanılmaktadır.'
                      }
                      placement="rightBottom"
                      key="yeni-madde-teklifi"
                    >
                      <SubMenu
                        key="yeni-madde-teklifi"
                        title={<FormattedMessage id="sidebar.newOffer" />}
                        icon={<PlusCircleOutlined className="gx-d-inline-block iconPositions" />}
                        data-cy="yeni-madde-teklifi"
                        disabled={true}
                      />
                    </Tooltip>
                  ) : (
                    <SubMenu
                      key="yeni-madde-teklifi"
                      title={<FormattedMessage id="sidebar.newOffer" />}
                      icon={<PlusCircleOutlined className="gx-d-inline-block iconPositions" />}
                      data-cy="yeni-madde-teklifi"
                    >
                      <Menu.Item
                        key="madde-tespit-formu"
                        icon={<FormOutlined className="iconPositions" />}
                      >
                        <Link
                          to="/yeni-madde-teklifi/madde-tespit-formu"
                          data-cy="madde-tespit-formu"
                        >
                          <FormattedMessage id="article.form.detectionFormTitle" />
                        </Link>
                      </Menu.Item>

                      {['test', 'local'].includes(process.env.REACT_APP_SENTRY_ENV) && (
                        <Menu.Item
                          key="madde-teklif-formu"
                          icon={<FormOutlined className="iconPositions" />}
                          title="Bu alan şu anda sadece Test ortamında gösterilmektedir."
                        >
                          <Link to="/yeni-madde-teklifi/madde-teklif-formu">
                            <FormattedMessage id="article.form.OfferFormTitle" />
                          </Link>
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )
                }

                <SubMenu
                  title={<FormattedMessage id="sidebar.articlerOffers" />}
                  icon={<TableOutlined className="gx-d-inline-block iconPositions" />}
                  key="madde-teklifleri"
                  data-cy="madde-teklifleri"
                >
                  {/* <Menu.Item key="uye-ekle">
                  <Link to="/uye-ekle">
                    <UserAddOutlined />
                    <span>Heyet Ekle</span>
                  </Link>
                </Menu.Item> */}
                  {!(memberships?.length === 1 && memberships[0].committee.id === 18) && (
                    <Menu.Item
                      key="tum-maddeler"
                      icon={<MenuOutlined className="iconPositions" />}
                    >
                      <Link to="/madde-teklifleri/tum-maddeler">
                        <FormattedMessage id="sidebar.allOffers" />
                      </Link>
                    </Menu.Item>
                  )}

                  <SubMenus
                    isAdmin={isAdmin || isEncyclopedia}
                    memberships={memberships}
                    committees={committees}
                    defaultOpenKeys={defaultOpenKeys}
                    defaultOpenKeysSub={defaultOpenKeysSub}
                    openKeysSub={openKeysSub}
                    onOpenChange={onOpenChange}
                  />
                </SubMenu>

                {isAuthor && (
                  <Menu.Item
                    key="maddelerim"
                    icon={<ReadOutlined className="iconPositions" />}
                  >
                    <Link to="/maddelerim">
                      <FormattedMessage id="sidebar.authorArticleOffers" />
                    </Link>
                  </Menu.Item>
                )}

                {(isAdmin || isEncyclopedia) && (
                  <SubMenu
                    title={<span style={{ display: 'inline-block' }}>Üye İşlemleri</span>}
                    icon={<UserOutlined className="gx-d-inline-block iconPositions" />}
                    key="uye-islemleri"
                  >
                    <Menu.Item
                      key="uye-ekle"
                      icon={<UserAddOutlined className="iconPositions" />}
                    >
                      <Link to="/uye-islemleri/uye-ekle">
                        <FormattedMessage id="userAdd.userAdd" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item
                      key="uye-listesi"
                      icon={<UserSwitchOutlined className="iconPositions" />}
                    >
                      <Link to="/uye-islemleri/uye-listesi">
                        <FormattedMessage id="userList.title" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}

                <SubMenu
                  title={
                    <span style={{ display: 'inline-block' }}>
                      <FormattedMessage id="author.actions" />
                    </span>
                  }
                  icon={<UserOutlined className="gx-d-inline-block iconPositions" />}
                  key="yazar-islemleri"
                >
                  {(isAdmin || isManager) && (
                    <Menu.Item
                      key="yazar-ekle"
                      icon={<UserAddOutlined className="iconPositions" />}
                    >
                      <Link to="/yazar-islemleri/yazar-ekle">
                        <FormattedMessage id="author.addAuthor" />
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item
                    key="yazar-listesi"
                    icon={<UserSwitchOutlined className="iconPositions" />}
                  >
                    <Link to="/yazar-islemleri/yazar-listesi">
                      <FormattedMessage id="author.allAuthors" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  title={
                    <span style={{ display: 'inline-block' }}>
                      <FormattedMessage id="sidebar.committeeList" />
                    </span>
                  }
                  icon={<GroupOutlined className="gx-d-inline-block iconPositions" />}
                  key="heyetler"
                >
                  {/* <Menu.Item key="uye-ekle">
                  <Link to="/uye-ekle">
                    <UserAddOutlined />
                    <span>Heyet Ekle</span>
                  </Link>
                </Menu.Item> */}
                  <Menu.Item key="heyet-detay/19">
                    <Link to="/heyetler/heyet-detay/19">
                      <FormattedMessage id="ayk" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="heyet-detay/18">
                    <Link to="/heyetler/heyet-detay/18">
                      <FormattedMessage id="dah" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="ilim-heyetleri"
                    icon={<MenuOutlined className="iconPositions" />}
                  >
                    <Link to="/heyetler/ilim-heyetleri">
                      <FormattedMessage id="committees.Title" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {isSuperAdmin && (
                  <SubMenu
                    title={<span style={{ display: 'inline-block' }}>Loglar</span>}
                    icon={<FileTextOutlined className="gx-d-inline-block iconPositions" />}
                    key="loglar"
                  >
                    <Menu.Item key="tum-loglar">
                      <Link to="/loglar/tum-loglar">
                        <FormattedMessage id="logs.allLogs" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}
                <Menu.Item icon={<FolderAddOutlined className="gx-d-inline-block iconPositions" />}>
                  <Link to="/dosya-yonetimi">
                    <FormattedMessage id="file.managment" />
                  </Link>
                </Menu.Item>
              </Menu>
            </CustomScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarContent;
