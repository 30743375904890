import React from 'react';

import { Alert } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, ClockCircleTwoTone, LoginOutlined } from '@ant-design/icons';
import { OFFER_STATUS } from 'utils';

const results = {
  success: {
    type: 'success',
    icon: <CheckCircleFilled />,
  },
  error: {
    type: 'error',
    icon: <CloseCircleFilled />,
  },
  info: {
    type: 'info',
    icon: <LoginOutlined />,
  },
  infoTwoTone: {
    type: 'info',
    icon: <ClockCircleTwoTone />,
  },
};

const kararType = {
  0: results.info,
  1: results.infoTwoTone,
  5: results.info,
  6: results.infoTwoTone,
  11: results.info,
  12: results.infoTwoTone,
  17: results.success,
  18: results.error,
  19: results.info,
  20: results.info,
  21: results.info,
  22: results.info,
  23: results.info,
};

export const TableAlert = ({ karar, committee }) => (
  <Alert
    className="table-alert"
    message={OFFER_STATUS[karar] ?? '-'}
    type={kararType[karar]?.type}
    icon={kararType[karar]?.icon}
    showIcon={!!OFFER_STATUS[karar]}
    banner
    data-cy={committee}
  />
);
