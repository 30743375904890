import React from 'react';

import { Checkbox, Tooltip, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CircularProgress, Form, Input } from 'components';
import { useGetCommitteeRevertReasonsQuery } from 'services';

const { TextArea } = Input;
const { Option } = Select;

const Refund = ({ id, ownerCommittee }) => {
  const intl = useIntl();
  const { data, error } = useGetCommitteeRevertReasonsQuery({ step: 1, status: 3, committee: id });

  if (!error && !data) return <CircularProgress />;

  return (
    <>
      {id === 19 && (
        <Form.Item
          label={<FormattedMessage id="article.status.reventCommittee" />}
          labelfor={'to'}
          name={'to'}
          rules={[
            {
              required: true,
              message: <FormattedMessage id="appModule.requiredMessage" />,
            },
          ]}
          className="gx-mb-3"
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            placeholder="Heyetler"
            style={{ width: '100%' }}
          >
            <Option
              key={'dah'}
              value={'dah'}
            >
              <FormattedMessage id="dah" />
            </Option>
            <Option
              key={'committee'}
              value={'committee'}
            >
              <FormattedMessage id="article.detail.ownerCommittee" /> {ownerCommittee ? `(${ownerCommittee.name})` : ''}
            </Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="refundReasons"
        tooltip={<FormattedMessage id="article.status.reventTooltipMessage" />}
        label={<FormattedMessage id="article.status.reventReasons" />}
        rules={[
          {
            required: true,
            message: <FormattedMessage id="article.status.reventReasonsErrorMessage" />,
          },
        ]}
      >
        <Checkbox.Group
          style={{ width: '600px' }}
          className="gx-px-2"
        >
          {data?.map((item) => (
            <Form.Item
              key={item.id}
              className="gx-mb-1"
            >
              <Form.Item
                name={item.input + 'checkbox'}
                valuePropName="checked"
                className="gx-mb-2"
              >
                <Checkbox value={item.input}>
                  {item.title}{' '}
                  {item?.info?.length ? (
                    <Tooltip title={item?.info}>
                      <QuestionCircleOutlined />{' '}
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </Checkbox>
              </Form.Item>
              <Form.Item dependencies={[item.input + 'checkbox']}>
                {({ getFieldValue }) =>
                  getFieldValue(item.input + 'checkbox') && (
                    <Form.Item
                      name={item.input}
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="article.status.reventReasonsErrorMessage" />,
                        },
                      ]}
                      className="gx-mb-3"
                    >
                      <TextArea
                        rows={4}
                        placeholder={intl.formatMessage({
                          id: 'article.status.reventReasonsPlaceholder',
                        })}
                        showCount
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Form.Item>
          ))}
          <Form.Item className="gx-mb-1">
            <Form.Item
              name={'othercheckbox'}
              valuePropName="checked"
              className="gx-mb-2"
            >
              <Checkbox value="other">
                <FormattedMessage id="article.detail.articleTypeOther" />
              </Checkbox>
            </Form.Item>
            <Form.Item dependencies={['othercheckbox']}>
              {({ getFieldValue }) =>
                getFieldValue('othercheckbox') && (
                  <Form.Item
                    name={'other'}
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="article.status.reventReasonsErrorMessage" />,
                      },
                      {
                        min: 100,
                        message: <FormattedMessage id="article.reasonLengthMessage" />,
                      },
                    ]}
                    className="gx-mb-3"
                  >
                    <TextArea
                      rows={4}
                      placeholder={intl.formatMessage({
                        id: 'article.status.DecisionReasonPlaceHolder',
                      })}
                      showCount
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Form.Item>
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default Refund;
