import { useState } from 'react';

import { Button, Select, Modal } from 'antd';
import { Form, Input } from 'components/Form';
import { NOTE_TYPES, openNotification } from 'utils';
import { sendArticleOrderMeet } from 'fetcher';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import { fetchError } from 'slices/commonSlice';

export const ArticleInterviewNote = ({ offer, open, close }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { data: offerVersions } = useSWR(`/article_offers/${offer?.id}/versions?step=3`);
  const last = offerVersions?.[0]?.['@id'];

  const [type, setType] = useState();

  const handleSelectType = (value) => {
    form.setFieldsValue({ interviewNote: value });
    const noteType = form.getFieldValue(`interviewNote`);
    setType(noteType);
  };

  const onFinish = async (values) => {
    const note = {
      articleOfferVersion: last,
      type: parseInt(values.noteType),
      subject: type === '2' ? values.emailTitle : null,
      content: values.noteArea,
    };

    try {
      await sendArticleOrderMeet(note);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }
  };

  return (
    <Modal
      visible={open}
      onOk={close}
      onCancel={close}
      width={900}
      footer={null}
    >
      <div className="interviewNote">
        <h1 className="h1">GÖRÜŞME NOTU</h1>

        <Form
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="gx-mb-4"
            name="noteType"
          >
            <Select
              placeholder="İletişim Türü Seçiniz"
              showSearch
              dropdownMatchSelectWidth={false}
              onChange={handleSelectType}
            >
              {NOTE_TYPES.map(([value, text]) => (
                <Select.Option key={value}>{text}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          {type === '2' && (
            <Form.Item
              className="gx-mb-4"
              name="emailTitle"
            >
              <Input placeholder="Lütfen mail başlığı giriniz." />
            </Form.Item>
          )}
          <Form.Item
            className="gx-mb-4"
            name="noteArea"
          >
            <TextArea
              className="noteArea"
              placeholder="Lütfen yazar ile yapılan görüşmede aldığınız notları giriniz."
            />
          </Form.Item>

          <Button
            className="buttonStyle"
            type="primary"
            htmlType="submit"
          >
            BİLDİRİM OLUŞTUR
          </Button>
          <Button
            onClick={close}
            className="buttonStyle"
          >
            VAZGEÇ
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
