import useSWR from 'swr';

const useInterviewNotes = (id) => {
  const { data, error } = useSWR(`/order_meets/offer/${id}`);

  return {
    notes: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useInterviewNotes;
