import { useState, useMemo } from 'react';

import { Button, Card, Checkbox, Descriptions, Radio, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { openNotification, formType, heyetKarariMetin } from 'utils';
import { sendCommitteeDecision, FETCH_RESULTS } from 'fetcher';
import { Form, Input } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { fetchError } from 'slices/commonSlice';
import useSWR from 'swr';

import { PullbackStatus } from '../offer/ArticlePullback';

const { TextArea } = Input;
const { Option } = Select;

export default ({ offer, status, goBack, hasActivePullback }) => {
  const {
    id,
    dahStatus,
    dahDescription,
    committeeStatus,
    statusDescription,
    ownerCommittee,
    aykStatus,
    aykDescription,
    otherCommittees,
    dahStatusIsConditional,
    dahRevertReasonAnswers,
    aykRevertReasonAnswers,
    isDraft,
    isView,
    step,
  } = offer;

  const commiteeId = ownerCommittee !== null ? ownerCommittee.id : otherCommittees.length !== 0 ? otherCommittees[0].id : { id: -100 };

  const formData = {
    ayk: {
      id: 19,
      status: aykStatus,
      description: aykDescription,
      revertAnswers: aykRevertReasonAnswers,
    },
    dah: {
      id: 18,
      status: dahStatus,
      description: dahDescription,
      revertAnswers: dahRevertReasonAnswers,
    },
    committee: {
      id: commiteeId,
      status: committeeStatus,
      description: statusDescription,
      revertAnswers: [],
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { memberships, isAdmin } = useUserInfo();
  const { data: reventReasons, error: reventReasonsError } = useSWR('/revert_reasons?step=2&status=3&committee=' + formData[status].id, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: dahAccept, error: dahAcceptError } = useSWR('/revert_reasons?step=2&status=2&committee=' + formData[status].id, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [editDecision, setEditDecision] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const isMember = memberships.some((item) => item.committee.id === formData[status].id && [0, 1].includes(item.type));

  const canPullback = useMemo(
    () => {
      return (isAdmin || isMember) && ((status === 'committee' && formData.dah.status === 0) || (status === 'dah' && formData.ayk.status === 0));
    }, // eslint-disable-next-line
    [isAdmin, isMember, status]
  );

  const editable = useMemo(
    () => {
      if (isAdmin) return true;

      const editableStatuses = [0, 1, 7, status === 'committee' ? 4 : -1];
      const isMember = memberships.some((item) => item.committee.id === formData[status].id && [0, 1].includes(item.type));

      return isMember && editableStatuses.includes(formData[status].status);
    }, //eslint-disable-next-line
    [isAdmin, memberships, status]
  );

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const answers = Object.entries(values)
        .filter(([key]) => !['refundReasons', 'accept', 'other', 'description', 'status', 'to'].includes(key))
        .map(([key, value]) => {
          if (key.includes('checkbox')) {
            return [key.replace('checkbox', ''), !!value];
          }
          return [key, value];
        });

      if (status === 'dah' && values.status === 2 && answers.some(([, value]) => !value)) {
        setAlert(true);
        setLoading(false);
        return;
      }

      const decision = {
        status: values.status,
        description: (values.other ?? values.description) || undefined,
        ...(status === 'ayk' && { to: values.to }),
      };

      if ((status === 'ayk' && values.status === 3) || (status === 'dah' && [2, 3].includes(values.status))) {
        decision.answers = Object.fromEntries(answers);
      }

      const result = await sendCommitteeDecision(offer.id, status, decision);

      // check if result code is 200
      if (result.status !== FETCH_RESULTS.OK) {
        throw result.status;
      }

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/madde-teklifleri/tum-maddeler');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` })));
    }
    setLoading(false);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Card
          title={
            <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
              <h2 className="gx-m-0">{formType[status]}</h2>

              {step === 2 && (
                <div className="gx-d-flex gx-justify-content-end gx-align-items-center">
                  {canPullback && (
                    <PullbackStatus
                      id={id}
                      committee={status}
                    />
                  )}

                  {editable && (
                    <Tooltip
                      placement="left"
                      title={hasActivePullback && <FormattedMessage id="article.status.pullback.decideTooltip" />}
                    >
                      <span>
                        <Button
                          type="primary"
                          className="gx-mb-0"
                          disabled={hasActivePullback || !isView}
                          onClick={() => {
                            setEditDecision(!editDecision);
                          }}
                        >
                          <FormattedMessage id="article.status.giveDecision" />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          }
          className="gx-mb-3"
        >
          {(isAdmin || editable) && editDecision ? (
            <>
              <Form.Item shouldUpdate>
                {({ setFieldsValue }) => (
                  <Form.Item
                    name="status"
                    labelfor={'status'}
                    label={<FormattedMessage id="article.status.committeeDecision" />}
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="appModule.requiredMessage" />,
                      },
                    ]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={() => setFieldsValue({ offerReasonSwitch: false })}
                      disabled={status !== 'committee' && (dahAcceptError || reventReasonsError)}
                    >
                      <Radio.Button
                        value={2}
                        data-cy="accept"
                      >
                        <FormattedMessage id="article.committeeDecisionAccept" />
                      </Radio.Button>
                      {status === 'dah' && (
                        <Radio.Button
                          value={6}
                          data-cy="revent"
                        >
                          <FormattedMessage id="article.committeeDecisionAcceptAnnotated" />
                        </Radio.Button>
                      )}
                      {status !== 'committee' && (
                        <Radio.Button
                          value={3}
                          data-cy="revent"
                        >
                          <FormattedMessage id="article.committeeDecisionRevent" />
                        </Radio.Button>
                      )}
                      <Tooltip title={isDraft && 'Taslak formlar için "Beklet" kararı verilememektedir.'}>
                        <Radio.Button
                          value={1}
                          data-cy="wait"
                          disabled={isDraft}
                        >
                          <FormattedMessage id="article.committeeDecisionWait" />
                        </Radio.Button>
                      </Tooltip>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item dependencies={['status']}>
                {({ getFieldValue }) =>
                  getFieldValue('status') === 2 && formData[status].id === 18 ? (
                    <Form.Item
                      label={<FormattedMessage id="article.status.mifEvaluationList" />}
                      required
                    >
                      <Checkbox.Group>
                        {dahAccept?.map((item) => (
                          <Form.Item
                            name={item.input + 'checkbox'}
                            key={item.id}
                            valuePropName="checked"
                            className="gx-mb-3"
                          >
                            <Checkbox value={item.input}>
                              {item.title}{' '}
                              {item?.info?.length && (
                                <Tooltip title={item?.info}>
                                  <QuestionCircleOutlined />{' '}
                                </Tooltip>
                              )}
                            </Checkbox>
                          </Form.Item>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  ) : (
                    getFieldValue('status') === 3 &&
                    ['dah', 'ayk'].includes(status) && (
                      <>
                        {formData[status].id === 19 && (
                          <Form.Item
                            label={<FormattedMessage id="article.status.reventCommittee" />}
                            labelfor={'to'}
                            name={'to'}
                            rules={[
                              {
                                required: true,
                                message: <FormattedMessage id="appModule.requiredMessage" />,
                              },
                            ]}
                            className="gx-mb-3"
                          >
                            <Select
                              getPopupContainer={(triggerNode) => triggerNode.parentElement}
                              placeholder="Heyetler"
                              style={{ width: '100%' }}
                            >
                              <Option
                                key={'dah'}
                                value={'dah'}
                              >
                                <FormattedMessage id="dah" />
                              </Option>
                              <Option
                                key={'committee'}
                                value={'committee'}
                              >
                                <FormattedMessage id="article.detail.ownerCommittee" /> {ownerCommittee ? `(${ownerCommittee.name})` : ''}
                              </Option>
                            </Select>
                          </Form.Item>
                        )}

                        {[18, 19].includes(formData[status].id) && (
                          <Form.Item
                            label={<FormattedMessage id="article.status.mifEvaluationList" />}
                            required
                          >
                            <Checkbox.Group>
                              {reventReasons?.map((item) => (
                                <Form.Item
                                  name={item.input + 'checkbox'}
                                  key={item.id}
                                  valuePropName="checked"
                                  className="gx-mb-3"
                                >
                                  <Checkbox value={item.input}>
                                    {item.title}{' '}
                                    {item?.info?.length && (
                                      <Tooltip title={item?.info}>
                                        <QuestionCircleOutlined />{' '}
                                      </Tooltip>
                                    )}
                                  </Checkbox>
                                </Form.Item>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        )}

                        <Form.Item
                          label={<FormattedMessage id="article.status.reventReason" />}
                          name={'other'}
                          rules={[
                            { required: true },
                            {
                              min: 100,
                              message: formatMessage({
                                id: 'article.reasonLengthMessage',
                              }),
                            },
                          ]}
                          className="gx-mb-3"
                        >
                          <TextArea
                            rows={4}
                            placeholder={formatMessage({
                              id: 'article.status.reventReasonsPlaceholder',
                            })}
                            showCount
                          />
                        </Form.Item>
                      </>
                    )
                  )
                }
              </Form.Item>

              {status === 'dah' && (
                <SweetAlert
                  warning
                  closeAnim
                  confirmBtnText={<FormattedMessage id="appModule.okey" />}
                  title={<FormattedMessage id="appModule.danger" />}
                  show={alert}
                  onConfirm={() => setAlert(false)}
                >
                  <div className="gx-mt-2">
                    <FormattedHTMLMessage id="article.status.mifEvaluationListErrorMessage" />
                  </div>{' '}
                </SweetAlert>
              )}

              <Form.Item dependencies={['status']}>
                {({ getFieldValue }) =>
                  [1, 2, 6].includes(getFieldValue('status')) && (
                    <Form.Item
                      name="description"
                      className="gx-mb-3"
                      label={<FormattedMessage id="article.status.committeReason" />}
                      rules={[
                        {
                          required: offer?.hasDecided,
                          message: formatMessage(
                            { id: 'appModule.requiredFieldMessage' },
                            {
                              field: formatMessage({
                                id: 'article.status.committeReason',
                              }),
                            }
                          ),
                        },
                        {
                          min: 100,
                          message: formatMessage({
                            id: 'article.reasonLengthMessage',
                          }),
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        showCount
                        placeholder={formatMessage({
                          id: 'article.status.DecisionReasonPlaceHolder',
                        })}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>

              {committeeStatus !== 7 && (
                <>
                  <Form.Item
                    name="accept"
                    valuePropName="checked"
                  >
                    <div className="gx-text-right gx-mt-3">
                      <Checkbox data-cy="decision-okey">
                        <FormattedMessage id="article.status.approveDecision" />
                      </Checkbox>
                      <h6 style={{ color: 'red' }}>
                        <FormattedMessage id="article.status.approveDecisionCantChange" />
                      </h6>
                    </div>
                  </Form.Item>

                  <Form.Item dependencies={['accept']}>
                    {({ getFieldValue }) => (
                      <Form.Item>
                        <Button
                          style={{ width: 100, float: 'right' }}
                          type="primary"
                          disabled={!getFieldValue('accept')}
                          htmlType="submit"
                          loading={loading}
                          data-cy="send"
                        >
                          <FormattedMessage id="appModule.send" />
                        </Button>
                      </Form.Item>
                    )}
                  </Form.Item>
                </>
              )}
            </>
          ) : (
            <>
              <Descriptions
                layout="horizontal"
                bordered
                column={24}
                className="decision"
              >
                <Descriptions.Item
                  label={<FormattedMessage id="article.status.committeeDecision" />}
                  span={24}
                  contentStyle={{ textTransform: 'uppercase' }}
                >
                  {status === 'dah' && dahStatusIsConditional ? (
                    <FormattedMessage id="article.committeeDecisionAcceptAnnotated" />
                  ) : (
                    heyetKarariMetin[formData[status]?.status]
                  )}
                </Descriptions.Item>

                {isView && step === 2 && formData[status].revertAnswers && formData[status].status === 3 && (
                  <Descriptions.Item
                    label={<FormattedMessage id="article.status.mifEvaluationList" />}
                    span={24}
                  >
                    <ul className="gx-m-0 gx-px-3 gx-py-0">
                      {formData[status]?.revertAnswers
                        ?.filter((item) => item.answer === '1')
                        .map((item) => (
                          <li
                            key={item.id}
                            className="gx-mb-1"
                          >
                            <div className="gx-font-weight-semi-bold gx-mb-1">{item.revertReason.title}</div>
                          </li>
                        ))}
                    </ul>
                  </Descriptions.Item>
                )}

                {formData[status].status && formData[status]?.description && (
                  <Descriptions.Item
                    label={<FormattedMessage id="article.status.committeReason" />}
                    span={24}
                  >
                    {formData[status]?.description}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </>
          )}
        </Card>

        {status === 'committee' && ![0, 2, 8, 9, 19].includes(offer.committeeStatus) && (
          <div className="gx-d-flex gx-justify-content-end gx-mt-4">
            <Button onClick={goBack}>
              <FormattedMessage id="goBack" />
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              disabled={!editDecision}
            >
              <FormattedMessage id="article.process.approval.title" />
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};
