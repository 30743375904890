import { DownloadOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getFileExtensionIcon } from 'utils';

export const AdditionalFiles = ({ offer }) => {
  if (offer?.contentMedia?.length === 0) return null;

  return (
    <div>
      <h2 className="gx-mb-4">
        <FormattedMessage id="article.detail.media" />
        <span className="gx-fs-md"> ({offer?.contentMedia?.length}) </span>
      </h2>

      <Row>
        {offer?.contentMedia?.map(({ media }, i) => (
          <Col
            key={i}
            span={12}
          >
            <Card className="gx-py-1 gx-mb-3">
              <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="gx-d-flex gx-align-items-center">
                  <span className=" gx-fs-xxl">{getFileExtensionIcon(media)}</span>
                  <span className="gx-ml-3">{media.originalFilename}</span>
                </div>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_BASE_URL}${media.relativePath}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="gx-ml-2"
                >
                  <DownloadOutlined className="gx-fs-xl" />
                </a>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
