import { useState } from 'react';

import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useCommittees } from 'useSWR';
import { exportTable } from 'utils';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useWindowSize } from 'hooks';
import { CircularProgress } from 'components';
import { Title } from 'components';

const columns = [
  {
    title: <FormattedMessage id="committees.committeeId" />,
    dataIndex: 'id',
    width: 150,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: <FormattedMessage id="committees.committeeName" />,
    dataIndex: 'name',
    width: 300,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: <FormattedMessage id="committees.committeeShortName" />,
    dataIndex: 'shortName',
    width: 120,
    sorter: (a, b) => a.shortName.localeCompare(b.shortName),
  },
  {
    title: <FormattedMessage id="committees.committeeHead" />,
    dataIndex: 'head',
    width: 300,
    sorter: (a, b) => a?.head?.fullName.localeCompare(b?.head.fullName),
    render: (head) => <span>{head?.fullName} </span>,
  },
  {
    title: <FormattedMessage id="committees.committeeSecretary" />,
    dataIndex: 'secretary',
    width: 300,
    sorter: (a, b) =>
      a?.secretary?.fullName.localeCompare(b?.secretary.fullName),
    render: (secretary) => <span>{secretary?.fullName} </span>,
  },
  {
    title: <FormattedMessage id="appModule.email" />,
    dataIndex: 'email',
    width: 300,
    sorter: (a, b) => a?.email?.localeCompare(b?.email),
  },
];

const Committees = () => {
  const { formatMessage } = useIntl();
  const {
    committees,
    isLoading,
    url: downloadURL,
  } = useCommittees('categoryOne');
  let navigate = useNavigate();
  const { height } = useWindowSize();

  const pagination = {
    hideOnSinglePage: true,
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total) =>
      `${formatMessage({ id: 'table.totalItem' }, { type: 'Heyet' })} ${total}`,
    showSizeChanger: false,
  };

  const [wait, setWait] = useState(false);

  const handleExportTable = async () => {
    setWait(true);
    let url = downloadURL;
    url = url.replaceAll('/committees', '/committees/export?');
    await exportTable(url, 'İlim Heyetleri');
    setWait(false);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div className="gx-mb-2">
      <Title>{formatMessage({ id: 'committees.Title' })}</Title>
      <div className="offers-table-columns gx-d-flex gx-justify-content-end">
        <Button
          loading={wait}
          disabled={!(committees?.length > 0)}
          onClick={handleExportTable}
          icon={<CloudDownloadOutlined />}
        >
          <FormattedMessage id="app.export" />
        </Button>
      </div>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="committees.Title" />
      </h1>
      <Table
        bordered
        columns={columns}
        dataSource={committees}
        pagination={pagination}
        rowKey={(record) => record.id}
        onRow={(record) => {
          return {
            onClick: () => navigate(`/heyetler/heyet-detay/${record.id}`), // click row
          };
        }}
        rowClassName={() => 'clickable'}
        scroll={{ x: 990, y: height - 300 }}
      />
    </div>
  );
};

export default Committees;
