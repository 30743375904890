import React from 'react';

import { FieldTimeOutlined, FileWordOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import useSWR from 'swr';
import { useInterviewNotes } from 'useSWR';
import dayjs from 'dayjs';
import { CircularProgress } from 'components/CircularProgress';

function OrderInterviewHistory({ offer }) {
  const { Panel } = Collapse;

  const { data: offerVersions } = useSWR(`/article_offers/${offer?.id}/versions?step=3`);
  const last = offerVersions?.[0]?.['@id'].substr(28, 4);
  const { notes, isLoading } = useInterviewNotes(last);
  const phoneHistory = notes?.filter((note) => note.type === 1);
  const mailHistory = notes?.filter((note) => note.type === 2);

  if (isLoading) {
    return <CircularProgress />;
  }
  if (phoneHistory.length || mailHistory.length !== 0) {
    return (
      <>
        <div className="headerDivWrapper">
          <span>
            <FieldTimeOutlined className="gx-p-1" />
            Görüşme Geçmişi
          </span>
          <div>
            <label className="gx-p-2">
              <FileWordOutlined className="gx-p-1" /> 19 Gündür Gönderilmeyi Bekliyor
            </label>
            <label className="gx-p-2">
              <MailOutlined className="gx-p-1" />
              {mailHistory?.length}
            </label>

            <label className="gx-p-2">
              <PhoneOutlined className="gx-p-1" />
              {phoneHistory?.length}
            </label>
          </div>
        </div>
        <Collapse
          expandIconPosition="right"
          className="gx-mt-4"
        >
          {notes?.map((note) => (
            <>
              <Panel
                icon={false}
                header={
                  <div className="panelHeaderWrapper">
                    <div>
                      <label>{dayjs(note.updatedAt).format('DD/MM/YYYY')}</label>
                      <label>{note.updatedAt.substr(11, 5)}</label>
                    </div>
                    <label className="gx-mt-1">
                      {note.type === 1 ? <PhoneOutlined className="gx-p-1" /> : <MailOutlined className="gx-p-1" />}
                      {note.type === 1 ? 'Telefon Görüşmesi Yapıldı' : 'Mail Atıldı'}
                    </label>
                  </div>
                }
              >
                <div className="gx-p-3">
                  <span className="interviewDetail">{note.type === 1 ? 'Görüşme Detayı' : 'Mail Detayı'}</span>
                  <p className="gx-mt-1">{note.content}</p>
                </div>
              </Panel>
            </>
          ))}
        </Collapse>
      </>
    );
  }
  if (!isLoading) {
    return (
      <>
        <h2>Herhangi Bir Görüşme Yapılmamış</h2>
      </>
    );
  }
}
export default OrderInterviewHistory;
