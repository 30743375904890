import React from 'react';

import { Timeline } from 'antd';
import dayjs from 'dayjs';
import { ENTITY } from 'utils';

import TimePoint from './TimePoint';
import EntityAdd from './EntityAdd';
import TimeCard from './TimeCard';

const soloList = ['contentFormFilled', 'lastOpinionDate'];
const pullbackList = ['requester', 'requested', 'description', 'status'];
const noEditList = [
  'opinionDate',
  'decisionDescription',
  'status',
  'requesterStatus',
];

const TimeLineComponent = ({ timeScale }) => {
  return (
    <Timeline mode="alternate" className="gx-px-5">
      {timeScale?.map((time, idx) => {
        const metaKeys = Object.keys(time?.metadata);

        if (metaKeys.includes('formNumber')) return null;

        return (
          <Timeline.Item
            key={time.id}
            color={time.type === 'entity_add' ? 'green' : 'blue'}
            dot={<TimePoint count={timeScale.length - idx} />}
            className="gx-mt-3"
          >
            <h3 className="gx-font-weight-semi-bold gx-mx-1">
              {dayjs(time.createdAt).format('DD/MM/YYYY - HH:mm')}
            </h3>

            {!metaKeys.some(
              (key) => soloList.includes(key) || noEditList.includes(key)
            ) && (
              <h6 style={{ marginTop: '-6px' }} className="gx-mb-2">
                {ENTITY[time.type]}{' '}
              </h6>
            )}

            {time?.type === 'entity_add' &&
              (pullbackList.every((key) => metaKeys.includes(key)) ? (
                <TimeCard
                  metadata={time.metadata}
                  type={time.type}
                  idx={idx}
                  user={time.user}
                />
              ) : (
                <EntityAdd metadata={time?.metadata} user={time.user} />
              ))}

            {time?.type !== 'entity_add' && (
              <>
                {metaKeys.some((key) => soloList.includes(key)) ? (
                  <EntityAdd metadata={time?.metadata} user={time.user} />
                ) : (
                  <TimeCard
                    metadata={time.metadata}
                    type={time.type}
                    idx={idx}
                    user={time.user}
                  />
                )}
              </>
            )}
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default TimeLineComponent;
