import { useState } from 'react';

import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useOfferVersions } from 'useSWR';

import { OrderMailModal } from '../tableActions/MailModal';

export const ArticleOrderExpired = ({ offer }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { versions, isLoading } = useOfferVersions({ pagination: false, id: offer.id, step: 3 });
  const [lastVersion] = versions || [offer];

  return (
    <>
      <Button
        block
        type="primary"
        loading={isLoading}
        className="gx-mt-3 gx-mb-0 gx-text-uppercase"
        onClick={() => setModalVisible(true)}
      >
        <FormattedMessage id="appModule.sendMail" />
      </Button>

      {!isLoading && (
        <OrderMailModal
          offer={lastVersion}
          open={modalVisible}
          close={() => setModalVisible(false)}
        />
      )}
    </>
  );
};
